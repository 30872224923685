import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ avatarId, gradients }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`g_paint0_${avatarId}`}
                x1="229.05"
                y1="100.885"
                x2="229.05"
                y2="157.085"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface ThumbsUpProps {
    color?: string;
    className?: string;
    avatarId?: string;
}

const ThumbsUp = (props: ThumbsUpProps) => {
    const { color = '', avatarId = '', className } = props;
    const gradients = getAvatarColors({ color, type: 'gesture' });

    return (
        <g id={`gesture-2_${avatarId}`} className={className}>
            <path
                d="M146.05 110.735C145.917 110.801 145.783 110.885 145.65 110.985C144.483 111.751 143.7 112.801 143.3 114.135C142.7 116.235 141.783 118.218 140.55 120.085C139.75 121.351 138.8 122.551 137.7 123.685C137.567 123.818 137.433 123.968 137.3 124.135C134.167 126.901 132.35 129.201 131.85 131.035C131.283 132.435 131 133.935 131 135.535C131 138.968 132.267 141.901 134.8 144.335C137.333 146.768 140.383 147.985 143.95 147.985C145.983 147.985 147.433 147.985 148.3 147.985C150.267 147.985 152 147.985 153.5 147.985C155.433 147.985 157.083 147.335 158.45 146.035C159.45 145.068 159.817 143.551 159.55 141.485C161.45 141.351 162.6 140.135 163 137.835C163 137.701 163 137.568 163 137.435C163 135.968 162.567 134.668 161.7 133.535C162.5 132.401 162.9 131.118 162.9 129.685C162.9 127.818 162.217 126.235 160.85 124.935C159.483 123.601 157.833 122.935 155.9 122.935C154.267 122.935 153.1 123.035 152.4 123.235C153.167 121.701 153.9 119.668 154.6 117.135C155.033 115.668 154.867 114.268 154.1 112.935C153.6 112.101 153 111.451 152.3 110.985C151.767 110.651 151.183 110.401 150.55 110.235C148.983 109.801 147.483 109.968 146.05 110.735Z"
                fill={`url(#g_paint0_${avatarId})`}
            />
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default ThumbsUp;
