import React from 'react';
import Peace from './gestures/Peace.js';
import Wave from './gestures/Wave.js';
import ThumbsUp from './gestures/ThumbsUp.js';
import Rocker from './gestures/Rocker.js';
import Pointer from './gestures/Pointer.js';
import Fist from './gestures/Fist.js';

interface GestureProps {
    gesture: string;
    color?: string;
    className?: string;
    avatarId?: string;
}

export const Gesture = (props: GestureProps) => {
    const { gesture, color = '', avatarId, className } = props;

    switch (gesture) {
        case 'gesture-0':
            return (
                // peace
                <Peace className={className} avatarId={avatarId} color={color} />
            );
        case 'gesture-1':
            return (
                // wave
                <Wave className={className} avatarId={avatarId} color={color} />
            );
        case 'gesture-2':
            return (
                // thumbs up
                <ThumbsUp className={className} avatarId={avatarId} color={color} />
            );
        case 'gesture-3':
            return (
                // rocker
                <Rocker className={className} avatarId={avatarId} color={color} />
            );

        case 'gesture-4':
            return (
                // pointer
                <Pointer className={className} avatarId={avatarId} color={color} />
            );
        case 'gesture-5':
            return (
                // fist
                <Fist className={className} avatarId={avatarId} color={color} />
            );
        default:
            return null;
    }
};
