import React from 'react';

export const allFaces = {
    'face-0': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.0912 19.6258C19.9777 22.7393 18.4209 26.4597 18.4209 30.7869C18.4209 35.1669 19.9777 38.8873 23.0912 41.948C26.1519 45.0615 29.8723 46.6183 34.2523 46.6183C38.6323 46.6183 42.3526 45.0615 45.4134 41.948C48.5269 38.8873 50.0836 35.1669 50.0836 30.7869C50.0836 26.4597 48.5269 22.7393 45.4134 19.6258C42.3526 16.5123 38.6323 14.9556 34.2523 14.9556C29.8723 14.9556 26.1519 16.5123 23.0912 19.6258Z"
                fill="white"
            />
            <path
                d="M27.6036 24.1377C25.7038 25.9847 24.7539 28.2011 24.7539 30.7869C24.7539 33.4255 25.7038 35.6682 27.6036 37.5152C29.4505 39.3622 31.6669 40.2857 34.2527 40.2857C36.8913 40.2857 39.1341 39.3622 40.9811 37.5152C42.828 35.6682 43.7515 33.4255 43.7515 30.7869C43.7515 28.2011 42.828 25.9847 40.9811 24.1377C39.1341 22.238 36.8913 21.2881 34.2527 21.2881C31.6669 21.2881 29.4505 22.238 27.6036 24.1377Z"
                fill="#3D465D"
            />
            <path
                d="M70.5853 19.6258C67.4718 22.7393 65.915 26.4597 65.915 30.7869C65.915 35.1669 67.4718 38.8873 70.5853 41.948C73.646 45.0615 77.3664 46.6183 81.7464 46.6183C86.1264 46.6183 89.8468 45.0615 92.9075 41.948C96.021 38.8873 97.5778 35.1669 97.5778 30.7869C97.5778 26.4597 96.021 22.7393 92.9075 19.6258C89.8468 16.5123 86.1264 14.9556 81.7464 14.9556C77.3664 14.9556 73.646 16.5123 70.5853 19.6258Z"
                fill="white"
            />
            <path
                d="M75.0977 24.1382C73.1979 25.9852 72.248 28.2016 72.248 30.7874C72.248 33.426 73.1979 35.6687 75.0977 37.5157C76.9447 39.3627 79.1611 40.2862 81.7469 40.2862C84.3854 40.2862 86.6282 39.3627 88.4752 37.5157C90.3222 35.6687 91.2457 33.426 91.2457 30.7874C91.2457 28.2016 90.3222 25.9852 88.4752 24.1382C86.6282 22.2385 84.3854 21.2886 81.7469 21.2886C79.1611 21.2886 76.9447 22.2385 75.0977 24.1382Z"
                fill="#3D465D"
            />
            <path
                d="M15.4932 19.9419C15.8626 20.6807 16.4959 21.1556 17.393 21.3667L47.9475 29.599C48.7918 29.8101 49.6098 29.7046 50.4014 29.2824C51.1402 28.8602 51.6151 28.2006 51.8262 27.3035L53.4885 21.2084C53.6996 20.3113 53.594 19.5197 53.1719 18.8337C52.6969 18.0421 52.0373 17.5408 51.1929 17.3297L20.6384 9.09742C19.7413 8.88634 18.9497 9.01827 18.2637 9.49321C17.4721 9.86261 16.9708 10.4959 16.7597 11.393L15.0974 17.488C14.8863 18.3852 15.0183 19.2031 15.4932 19.9419Z"
                fill="#3D465D"
            />
            <path
                d="M65.5989 29.2824C66.3905 29.7046 67.2084 29.8101 68.0528 29.599L98.6073 21.3667C99.5044 21.1556 100.138 20.6807 100.507 19.9419C100.982 19.2031 101.114 18.3852 100.903 17.488L99.2406 11.393C99.0295 10.4959 98.5281 9.86261 97.7366 9.49321C97.0506 9.01827 96.259 8.88634 95.3619 9.09742L64.8073 17.3297C63.963 17.5408 63.3034 18.0421 62.8284 18.8337C62.4063 19.5197 62.3007 20.3113 62.5118 21.2084L64.1741 27.3035C64.3852 28.2006 64.8601 28.8602 65.5989 29.2824Z"
                fill="#3D465D"
            />
            <path
                d="M48.2461 56.4941H68.8269"
                stroke="#3D465D"
                strokeWidth="5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-1': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.0921 18.7626C18.9786 21.6232 17.4219 25.0414 17.4219 29.0171C17.4219 33.0414 18.9786 36.4595 22.0921 39.2717C25.1528 42.1323 28.8731 43.5626 33.2531 43.5626C37.6331 43.5626 41.3534 42.1323 44.4141 39.2717C47.5276 36.4595 49.0843 33.0414 49.0843 29.0171C49.0843 25.0414 47.5276 21.6232 44.4141 18.7626C41.3534 15.902 37.6331 14.4717 33.2531 14.4717C28.8731 14.4717 25.1528 15.902 22.0921 18.7626Z"
                fill="white"
            />
            <path
                d="M26.6035 22.9082C24.7038 24.6052 23.7539 26.6415 23.7539 29.0173C23.7539 31.4415 24.7038 33.5021 26.6035 35.1991C28.4505 36.8961 30.6669 37.7446 33.2526 37.7446C35.8912 37.7446 38.1339 36.8961 39.9809 35.1991C41.8279 33.5021 42.7514 31.4415 42.7514 29.0173C42.7514 26.6415 41.8279 24.6052 39.9809 22.9082C38.1339 21.1628 35.8912 20.29 33.2526 20.29C30.6669 20.29 28.4505 21.1628 26.6035 22.9082Z"
                fill="#3D465D"
            />
            <path
                d="M69.5852 18.7626C66.4718 21.6232 64.915 25.0414 64.915 29.0171C64.915 33.0414 66.4718 36.4595 69.5852 39.2717C72.6459 42.1323 76.3663 43.5626 80.7462 43.5626C85.1262 43.5626 88.8465 42.1323 91.9072 39.2717C95.0207 36.4595 96.5775 33.0414 96.5775 29.0171C96.5775 25.0414 95.0207 21.6232 91.9072 18.7626C88.8465 15.902 85.1262 14.4717 80.7462 14.4717C76.3663 14.4717 72.6459 15.902 69.5852 18.7626Z"
                fill="white"
            />
            <path
                d="M74.0977 22.9082C72.1979 24.6052 71.248 26.6415 71.248 29.0173C71.248 31.4415 72.1979 33.5021 74.0977 35.1991C75.9446 36.8961 78.161 37.7446 80.7468 37.7446C83.3853 37.7446 85.6281 36.8961 87.475 35.1991C89.322 33.5021 90.2455 31.4415 90.2455 29.0173C90.2455 26.6415 89.322 24.6052 87.475 22.9082C85.6281 21.1628 83.3853 20.29 80.7468 20.29C78.161 20.29 75.9446 21.1628 74.0977 22.9082Z"
                fill="#3D465D"
            />
            <path
                d="M14.4932 19.0531C14.8626 19.7319 15.4958 20.1683 16.3929 20.3622L46.9472 27.9259C47.7915 28.1198 48.6095 28.0228 49.401 27.635C50.1398 27.2471 50.6147 26.641 50.8258 25.8168L52.4881 20.2168C52.6992 19.3925 52.5936 18.6653 52.1715 18.035C51.6965 17.3077 51.0369 16.8471 50.1926 16.6531L19.6383 9.08951C18.7412 8.89557 17.9497 9.01678 17.2637 9.45315C16.4721 9.79254 15.9708 10.3744 15.7597 11.1986L14.0974 16.7986C13.8863 17.6228 14.0183 18.3744 14.4932 19.0531Z"
                fill="#3D465D"
            />
            <path
                d="M64.5999 27.635C65.3914 28.0228 66.2094 28.1198 67.0537 27.9259L97.6079 20.3622C98.505 20.1683 99.1383 19.7319 99.5077 19.0531C99.9826 18.3744 100.115 17.6228 99.9035 16.7986L98.2412 11.1986C98.0301 10.3744 97.5288 9.79254 96.7372 9.45315C96.0512 9.01678 95.2596 8.89557 94.3625 9.08951L63.8083 16.6531C62.964 16.8471 62.3043 17.3077 61.8294 18.035C61.4072 18.6653 61.3017 19.3925 61.5128 20.2168L63.175 25.8168C63.3861 26.641 63.8611 27.2471 64.5999 27.635Z"
                fill="#3D465D"
            />
            <path
                d="M67.4306 51.0366C67.8528 50.3578 67.9583 49.6548 67.7472 48.9275C67.4834 48.1517 66.9557 47.5942 66.1641 47.2548C65.4253 46.8184 64.6074 46.7214 63.7103 46.9639C62.8659 47.1578 62.2063 47.5699 61.7314 48.2002C61.3092 48.7336 60.7023 49.2426 59.9108 49.7275C57.9582 50.8426 55.6627 51.4002 53.0242 51.4002C51.8105 51.4002 50.6759 51.3032 49.6205 51.1093C48.7234 50.9639 47.9054 51.1093 47.1666 51.5457C46.4278 51.8851 45.9529 52.4669 45.7418 53.2911C45.5307 54.0184 45.6891 54.7214 46.2168 55.4002C46.6389 55.982 47.325 56.3699 48.2748 56.5638C49.6996 56.8548 51.2828 57.0002 53.0242 57.0002C57.0875 57.0002 60.5968 56.1275 63.5519 54.382L63.4728 54.4548C65.267 53.4366 66.5863 52.2972 67.4306 51.0366Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-2': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.0921 18.7611C18.9786 21.6213 17.4219 25.0391 17.4219 29.0143C17.4219 33.0381 18.9786 36.4558 22.0921 39.2676C25.1528 42.1278 28.8731 43.5579 33.2531 43.5579C37.6331 43.5579 41.3534 42.1278 44.4141 39.2676C47.5276 36.4558 49.0843 33.0381 49.0843 29.0143C49.0843 25.0391 47.5276 21.6213 44.4141 18.7611C41.3534 15.9008 37.6331 14.4707 33.2531 14.4707C28.8731 14.4707 25.1528 15.9008 22.0921 18.7611Z"
                fill="white"
            />
            <path
                d="M26.6035 22.9064C24.7038 24.6032 23.7539 26.6393 23.7539 29.0147C23.7539 31.4387 24.7038 33.499 26.6035 35.1958C28.4505 36.8925 30.6669 37.7409 33.2526 37.7409C35.8912 37.7409 38.1339 36.8925 39.9809 35.1958C41.8279 33.499 42.7514 31.4387 42.7514 29.0147C42.7514 26.6393 41.8279 24.6032 39.9809 22.9064C38.1339 21.1612 35.8912 20.2886 33.2526 20.2886C30.6669 20.2886 28.4505 21.1612 26.6035 22.9064Z"
                fill="#3D465D"
            />
            <path
                d="M69.5852 18.7611C66.4718 21.6213 64.915 25.0391 64.915 29.0143C64.915 33.0381 66.4718 36.4558 69.5852 39.2676C72.6459 42.1278 76.3663 43.5579 80.7462 43.5579C85.1262 43.5579 88.8465 42.1278 91.9072 39.2676C95.0207 36.4558 96.5775 33.0381 96.5775 29.0143C96.5775 25.0391 95.0207 21.6213 91.9072 18.7611C88.8465 15.9008 85.1262 14.4707 80.7462 14.4707C76.3663 14.4707 72.6459 15.9008 69.5852 18.7611Z"
                fill="white"
            />
            <path
                d="M74.0977 22.9064C72.1979 24.6032 71.248 26.6393 71.248 29.0147C71.248 31.4387 72.1979 33.499 74.0977 35.1958C75.9446 36.8925 78.161 37.7409 80.7468 37.7409C83.3853 37.7409 85.6281 36.8925 87.475 35.1958C89.322 33.499 90.2455 31.4387 90.2455 29.0147C90.2455 26.6393 89.322 24.6032 87.475 22.9064C85.6281 21.1612 83.3853 20.2886 80.7468 20.2886C78.161 20.2886 75.9446 21.1612 74.0977 22.9064Z"
                fill="#3D465D"
            />
            <path
                d="M14.4932 19.0519C14.8626 19.7306 15.4958 20.1669 16.3929 20.3608L46.9472 27.9235C47.7915 28.1174 48.6095 28.0204 49.401 27.6326C50.1398 27.2448 50.6147 26.6388 50.8258 25.8147L52.4881 20.2154C52.6992 19.3912 52.5936 18.6641 52.1715 18.0338C51.6965 17.3066 51.0369 16.8461 50.1926 16.6522L19.6383 9.0895C18.7412 8.89558 17.9497 9.01678 17.2637 9.45309C16.4721 9.79244 15.9708 10.3742 15.7597 11.1983L14.0974 16.7976C13.8863 17.6218 14.0183 18.3732 14.4932 19.0519Z"
                fill="#3D465D"
            />
            <path
                d="M64.5999 27.6326C65.3914 28.0204 66.2094 28.1174 67.0537 27.9235L97.6079 20.3608C98.505 20.1669 99.1383 19.7306 99.5077 19.0519C99.9826 18.3732 100.115 17.6218 99.9035 16.7976L98.2412 11.1983C98.0301 10.3742 97.5288 9.79244 96.7372 9.45309C96.0512 9.01678 95.2596 8.89558 94.3625 9.0895L63.8083 16.6522C62.964 16.8461 62.3043 17.3066 61.8294 18.0338C61.4072 18.6641 61.3017 19.3912 61.5128 20.2154L63.175 25.8147C63.3861 26.6388 63.8611 27.2448 64.5999 27.6326Z"
                fill="#3D465D"
            />
            <path
                d="M69.7256 50.5221C66.1372 48.0497 61.81 46.8135 56.7441 46.8135C51.5725 46.8135 47.1662 48.0739 43.525 50.5948C43.525 50.6433 43.4986 50.6675 43.4458 50.6675C42.7598 51.1523 42.1793 51.6371 41.7044 52.1219C41.1239 52.7521 40.8601 53.4551 40.9128 54.2307C40.9656 55.0549 41.335 55.7336 42.021 56.2668C42.6543 56.8001 43.4195 57.0425 44.3166 56.994C45.1609 56.9455 45.8733 56.6062 46.4538 55.976C46.7176 55.7336 47.0079 55.4912 47.3245 55.2488C49.9102 53.5035 53.0501 52.6309 56.7441 52.6309C60.3325 52.6309 63.3932 53.5035 65.9262 55.2488H66.0053C66.4802 55.5881 66.9288 55.9275 67.351 56.2668C67.9842 56.8001 68.7494 57.0425 69.6465 56.994C70.4908 56.9455 71.2032 56.6062 71.7837 55.976C72.3642 55.3942 72.628 54.6913 72.5753 53.8671C72.5225 53.0915 72.1795 52.437 71.5462 51.9037C70.9658 51.4674 70.3589 51.0311 69.7256 50.5948V50.5221Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-3': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.0921 18.8334C18.9786 21.7148 17.4219 25.1579 17.4219 29.1626C17.4219 33.2161 18.9786 36.6592 22.0921 39.4918C25.1528 42.3732 28.8731 43.8139 33.2531 43.8139C37.6331 43.8139 41.3534 42.3732 44.4141 39.4918C47.5276 36.6592 49.0843 33.2161 49.0843 29.1626C49.0843 25.1579 47.5276 21.7148 44.4141 18.8334C41.3534 15.9519 37.6331 14.5112 33.2531 14.5112C28.8731 14.5112 25.1528 15.9519 22.0921 18.8334Z"
                fill="white"
            />
            <path
                d="M26.6035 23.0093C24.7038 24.7186 23.7539 26.7698 23.7539 29.1629C23.7539 31.6048 24.7038 33.6804 26.6035 35.3897C28.4505 37.099 30.6669 37.9537 33.2526 37.9537C35.8912 37.9537 38.1339 37.099 39.9809 35.3897C41.8279 33.6804 42.7514 31.6048 42.7514 29.1629C42.7514 26.7698 41.8279 24.7186 39.9809 23.0093C38.1339 21.2512 35.8912 20.3721 33.2526 20.3721C30.6669 20.3721 28.4505 21.2512 26.6035 23.0093Z"
                fill="#3D465D"
            />
            <path
                d="M69.5852 18.8334C66.4718 21.7148 64.915 25.1579 64.915 29.1626C64.915 33.2161 66.4718 36.6592 69.5852 39.4918C72.6459 42.3732 76.3663 43.8139 80.7462 43.8139C85.1262 43.8139 88.8465 42.3732 91.9072 39.4918C95.0207 36.6592 96.5775 33.2161 96.5775 29.1626C96.5775 25.1579 95.0207 21.7148 91.9072 18.8334C88.8465 15.9519 85.1262 14.5112 80.7462 14.5112C76.3663 14.5112 72.6459 15.9519 69.5852 18.8334Z"
                fill="white"
            />
            <path
                d="M74.0977 23.0093C72.1979 24.7186 71.248 26.7698 71.248 29.1629C71.248 31.6048 72.1979 33.6804 74.0977 35.3897C75.9446 37.099 78.161 37.9537 80.7468 37.9537C83.3853 37.9537 85.6281 37.099 87.475 35.3897C89.322 33.6804 90.2455 31.6048 90.2455 29.1629C90.2455 26.7698 89.322 24.7186 87.475 23.0093C85.6281 21.2512 83.3853 20.3721 80.7468 20.3721C78.161 20.3721 75.9446 21.2512 74.0977 23.0093Z"
                fill="#3D465D"
            />
            <path
                d="M14.4932 19.1263C14.8626 19.8101 15.4958 20.2496 16.3929 20.445L46.9472 28.0637C47.7915 28.259 48.6095 28.1613 49.401 27.7706C50.1398 27.3799 50.6147 26.7695 50.8258 25.9392L52.4881 20.2984C52.6992 19.4682 52.5936 18.7356 52.1715 18.1007C51.6965 17.3682 51.0369 16.9042 50.1926 16.7089L19.6383 9.09016C18.7412 8.89481 17.9497 9.01691 17.2637 9.45645C16.4721 9.79831 15.9708 10.3844 15.7597 11.2146L14.0974 16.8554C13.8863 17.6856 14.0183 18.4426 14.4932 19.1263Z"
                fill="#3D465D"
            />
            <path
                d="M64.5999 27.7706C65.3914 28.1613 66.2094 28.259 67.0537 28.0637L97.6079 20.445C98.505 20.2496 99.1383 19.8101 99.5077 19.1263C99.9826 18.4426 100.115 17.6856 99.9035 16.8554L98.2412 11.2146C98.0301 10.3844 97.5288 9.79831 96.7372 9.45645C96.0512 9.01691 95.2596 8.89481 94.3625 9.09016L63.8083 16.7089C62.964 16.9042 62.3043 17.3682 61.8294 18.1007C61.4072 18.7356 61.3017 19.4682 61.5128 20.2984L63.175 25.9392C63.3861 26.7695 63.8611 27.3799 64.5999 27.7706Z"
                fill="#3D465D"
            />
            <path
                d="M71.8021 51.8719C72.4353 51.3347 72.7784 50.6753 72.8311 49.8939C72.8839 49.0637 72.62 48.3555 72.0396 47.7695C71.4591 47.1346 70.7467 46.7927 69.9023 46.7439C69.0052 46.6951 68.2401 46.9392 67.6068 47.4765C67.1847 47.8183 66.7361 48.1602 66.2612 48.5021H66.182C63.649 50.2602 60.5883 51.1393 56.9999 51.1393C53.306 51.1393 50.1661 50.2602 47.5803 48.5021C47.2637 48.2579 46.9735 48.0137 46.7096 47.7695C46.1292 47.1346 45.4167 46.7927 44.5724 46.7439C43.6753 46.6951 42.9101 46.9392 42.2769 47.4765C41.5909 48.0137 41.2215 48.6974 41.1687 49.5277C41.1159 50.3091 41.3798 51.0172 41.9603 51.6521C42.4352 52.1405 43.0157 52.6289 43.7017 53.1172C43.7545 53.1172 43.7809 53.1417 43.7809 53.1905C47.422 55.7301 51.8284 56.9998 56.9999 56.9998C62.0659 56.9998 66.3931 55.7545 69.9815 53.2637V53.1905C70.6148 52.7509 71.2216 52.3114 71.8021 51.8719Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-4': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.0592 24.8212C42.0341 21.7405 38.357 20.2002 34.028 20.2002C29.6989 20.2002 26.0219 21.7405 22.9968 24.8212C19.9195 27.8497 18.3809 31.5308 18.3809 35.8647H49.6751C49.6751 31.5308 48.1365 27.8497 45.0592 24.8212Z"
                fill="white"
            />
            <path
                d="M40.6789 29.2076C38.8534 27.3801 36.6368 26.4663 34.0289 26.4663C31.4732 26.4663 29.2826 27.3801 27.4571 29.2076C25.5795 31.0351 24.6406 33.2543 24.6406 35.865H43.4172C43.4172 33.2543 42.5044 31.0351 40.6789 29.2076Z"
                fill="#3D465D"
            />
            <path
                d="M92.0006 24.8212C88.9755 21.7405 85.2984 20.2002 80.9694 20.2002C76.6404 20.2002 72.9633 21.7405 69.9382 24.8212C66.8609 27.8497 65.3223 31.5308 65.3223 35.8647H96.6165C96.6165 31.5308 95.0779 27.8497 92.0006 24.8212Z"
                fill="white"
            />
            <path
                d="M87.6194 29.2076C85.7939 27.3801 83.5772 26.4663 80.9693 26.4663C78.4136 26.4663 76.223 27.3801 74.3975 29.2076C72.5199 31.0351 71.5811 33.2543 71.5811 35.865H90.3576C90.3576 33.2543 89.4449 31.0351 87.6194 29.2076Z"
                fill="#3D465D"
            />
            <path
                d="M15.4875 20.8266C15.8526 21.5576 16.4785 22.0275 17.3651 22.2364L47.5641 30.3819C48.3986 30.5908 49.207 30.4863 49.9894 30.0686C50.7196 29.6509 51.189 28.9982 51.3976 28.1106L53.0406 22.0797C53.2492 21.1921 53.1449 20.4089 52.7276 19.7301C52.2582 18.9468 51.6062 18.4508 50.7717 18.2419L20.5728 10.0964C19.6861 9.88754 18.9038 10.0181 18.2257 10.488C17.4434 10.8535 16.9479 11.4801 16.7392 12.3677L15.0963 18.3986C14.8877 19.2862 15.0181 20.0956 15.4875 20.8266Z"
                fill="#3D465D"
            />
            <path
                d="M65.0119 30.0686C65.7943 30.4863 66.6027 30.5908 67.4373 30.3819L97.6362 22.2364C98.5229 22.0275 99.1488 21.5576 99.5139 20.8266C99.9833 20.0956 100.114 19.2862 99.905 18.3986L98.2621 12.3677C98.0535 11.4801 97.558 10.8535 96.7756 10.488C96.0976 10.0181 95.3152 9.88754 94.4285 10.0964L64.2296 18.2419C63.3951 18.4508 62.7431 18.9468 62.2737 19.7301C61.8564 20.4089 61.7521 21.1921 61.9608 22.0797L63.6037 28.1106C63.8123 28.9982 64.2817 29.6509 65.0119 30.0686Z"
                fill="#3D465D"
            />
            <path
                d="M70.079 50.0228C66.5323 47.3598 62.2555 46.0283 57.2484 46.0283C52.137 46.0283 47.7819 47.3859 44.183 50.1011C44.183 50.1533 44.1569 50.1794 44.1048 50.1794C43.4267 50.7016 42.853 51.2237 42.3836 51.7459C41.8099 52.4247 41.5491 53.1818 41.6012 54.0172C41.6534 54.9049 42.0185 55.6359 42.6965 56.2102C43.3224 56.7846 44.0787 57.0457 44.9654 56.9935C45.7999 56.9412 46.504 56.5757 47.0777 55.8969C47.3385 55.6359 47.6254 55.3748 47.9383 55.1137C50.494 53.234 53.5974 52.2941 57.2484 52.2941C60.7951 52.2941 63.8202 53.234 66.3237 55.1137H66.4019C66.8714 55.4792 67.3147 55.8447 67.7319 56.2102C68.3578 56.7846 69.1141 57.0457 70.0008 56.9935C70.8353 56.9412 71.5394 56.5757 72.1131 55.8969C72.6869 55.2704 72.9477 54.5133 72.8955 53.6256C72.8433 52.7902 72.5043 52.0853 71.8784 51.5109C71.3047 51.041 70.7049 50.571 70.079 50.1011V50.0228Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-5': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.6529 24.1124C42.7693 21.1791 39.2643 19.7124 35.1377 19.7124C31.0112 19.7124 27.5062 21.1791 24.6226 24.1124C21.6893 26.9959 20.2227 30.501 20.2227 34.6275H50.0528C50.0528 30.501 48.5862 26.9959 45.6529 24.1124Z"
                fill="white"
            />
            <path
                d="M41.4774 28.2884C39.7373 26.5483 37.6244 25.6782 35.1385 25.6782C32.7024 25.6782 30.6143 26.5483 28.8742 28.2884C27.0844 30.0285 26.1895 32.1414 26.1895 34.6273H44.0876C44.0876 32.1414 43.2175 30.0285 41.4774 28.2884Z"
                fill="#3D465D"
            />
            <path
                d="M90.398 24.1124C87.5144 21.1791 84.0094 19.7124 79.8829 19.7124C75.7564 19.7124 72.2513 21.1791 69.3677 24.1124C66.4344 26.9959 64.9678 30.501 64.9678 34.6275H94.7979C94.7979 30.501 93.3313 26.9959 90.398 24.1124Z"
                fill="white"
            />
            <path
                d="M86.2225 28.2884C84.4824 26.5483 82.3695 25.6782 79.8836 25.6782C77.4475 25.6782 75.3594 26.5483 73.6193 28.2884C71.8295 30.0285 70.9346 32.1414 70.9346 34.6273H88.8327C88.8327 32.1414 87.9626 30.0285 86.2225 28.2884Z"
                fill="#3D465D"
            />
            <path
                d="M17.4647 20.3086C17.8127 21.0047 18.4093 21.4521 19.2545 21.651L48.0406 29.4068C48.8361 29.6057 49.6067 29.5063 50.3524 29.1085C51.0485 28.7108 51.4959 28.0893 51.6948 27.2441L53.2609 21.5018C53.4597 20.6566 53.3603 19.9109 52.9626 19.2646C52.5151 18.5188 51.8937 18.0465 51.0982 17.8476L22.3121 10.0918C21.4669 9.89292 20.7211 10.0172 20.0748 10.4647C19.329 10.8127 18.8567 11.4093 18.6579 12.2545L17.0918 17.9968C16.8929 18.842 17.0172 19.6126 17.4647 20.3086Z"
                fill="#3D465D"
            />
            <path
                d="M64.6719 29.1085C65.4177 29.5063 66.1883 29.6057 66.9838 29.4068L95.7699 21.651C96.6151 21.4521 97.2117 21.0047 97.5597 20.3086C98.0072 19.6126 98.1315 18.842 97.9326 17.9968L96.3665 12.2545C96.1676 11.4093 95.6953 10.8127 94.9496 10.4647C94.3032 10.0172 93.5575 9.89292 92.7123 10.0918L63.9262 17.8476C63.1307 18.0465 62.5093 18.5188 62.0618 19.2646C61.6641 19.9109 61.5646 20.6566 61.7635 21.5018L63.3296 27.2441C63.5284 28.0893 63.9759 28.7108 64.6719 29.1085Z"
                fill="#3D465D"
            />
            <path
                d="M85.5512 44.2476C84.4077 42.8059 83.0654 42.085 81.5241 42.085C65.1175 46.2115 49.059 46.2115 33.3484 42.085C31.8072 42.085 30.4648 42.8059 29.3213 44.2476C28.2276 45.6894 27.6807 47.4047 27.6807 49.3934V49.7662C27.6807 51.7052 28.2276 53.4204 29.3213 54.9119C30.4648 56.304 31.8072 57 33.3484 57C49.059 52.9233 65.1175 52.9233 81.5241 57C83.0654 57 84.4077 56.304 85.5512 54.9119C86.7444 53.4204 87.341 51.7052 87.341 49.7662V49.3934C87.341 47.4047 86.7444 45.6894 85.5512 44.2476Z"
                fill="white"
            />
        </svg>
    ),
    'face-6': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.7061 27.3673C42.7166 24.3809 39.0828 22.8877 34.8047 22.8877C30.5266 22.8877 26.8929 24.3809 23.9034 27.3673C20.8623 30.3032 19.3418 33.8717 19.3418 38.073H50.2676C50.2676 33.8717 48.7471 30.3032 45.7061 27.3673Z"
                fill="white"
            />
            <path
                d="M41.3768 31.6198C39.5728 29.8482 37.3822 28.9624 34.8051 28.9624C32.2795 28.9624 30.1147 29.8482 28.3107 31.6198C26.4551 33.3914 25.5273 35.5427 25.5273 38.0736H44.0828C44.0828 35.5427 43.1808 33.3914 41.3768 31.6198Z"
                fill="#3D465D"
            />
            <path
                d="M92.0947 27.3678C89.1052 24.3814 85.4715 22.8882 81.1934 22.8882C76.9153 22.8882 73.2815 24.3814 70.292 27.3678C67.251 30.3037 65.7305 33.8722 65.7305 38.0734H96.6563C96.6563 33.8722 95.1358 30.3037 92.0947 27.3678Z"
                fill="white"
            />
            <path
                d="M87.7645 31.6198C85.9605 29.8482 83.7699 28.9624 81.1928 28.9624C78.6672 28.9624 76.5024 29.8482 74.6984 31.6198C72.8428 33.3914 71.915 35.5427 71.915 38.0736H90.4705C90.4705 35.5427 89.5685 33.3914 87.7645 31.6198Z"
                fill="#3D465D"
            />
            <path
                d="M16.4817 23.4954C16.8425 24.204 17.461 24.6596 18.3373 24.862L48.1807 32.7584C49.0054 32.9608 49.8043 32.8596 50.5775 32.4547C51.2991 32.0497 51.763 31.417 51.9691 30.5565L53.5927 24.7102C53.7989 23.8497 53.6958 23.0904 53.2835 22.4324C52.8196 21.6731 52.1753 21.1923 51.3506 20.9898L21.5072 13.0934C20.6309 12.891 19.8578 13.0175 19.1877 13.4731C18.4146 13.8274 17.9249 14.4348 17.7188 15.2953L16.0952 21.1416C15.889 22.0021 16.0178 22.7867 16.4817 23.4954Z"
                fill="#3D465D"
            />
            <path
                d="M65.4221 32.4547C66.1952 32.8596 66.9941 32.9608 67.8188 32.7584L97.6622 24.862C98.5385 24.6596 99.157 24.204 99.5178 23.4954C99.9817 22.7867 100.111 22.0021 99.9044 21.1416L98.2808 15.2953C98.0746 14.4348 97.5849 13.8274 96.8118 13.4731C96.1417 13.0175 95.3686 12.891 94.4923 13.0934L64.6489 20.9898C63.8242 21.1923 63.1799 21.6731 62.7161 22.4324C62.3037 23.0904 62.2006 23.8497 62.4068 24.7102L64.0304 30.5565C64.2366 31.417 64.7005 32.0497 65.4221 32.4547Z"
                fill="#3D465D"
            />
            <path
                d="M48.4727 54H68.5744"
                stroke="#3D465D"
                strokeWidth="3.5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-7': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.0592 24.8233C42.0341 21.7422 38.357 20.2017 34.028 20.2017C29.6989 20.2017 26.0219 21.7422 22.9968 24.8233C19.9195 27.8521 18.3809 31.5338 18.3809 35.8682H49.6751C49.6751 31.5338 48.1365 27.8521 45.0592 24.8233Z"
                fill="white"
            />
            <path
                d="M40.6789 29.2099C38.8534 27.3821 36.6368 26.4683 34.0289 26.4683C31.4732 26.4683 29.2826 27.3821 27.4571 29.2099C25.5795 31.0377 24.6406 33.2571 24.6406 35.8682H43.4172C43.4172 33.2571 42.5044 31.0377 40.6789 29.2099Z"
                fill="#3D465D"
            />
            <path
                d="M92.0006 24.8233C88.9755 21.7422 85.2984 20.2017 80.9694 20.2017C76.6404 20.2017 72.9633 21.7422 69.9382 24.8233C66.8609 27.8521 65.3223 31.5338 65.3223 35.8682H96.6165C96.6165 31.5338 95.0779 27.8521 92.0006 24.8233Z"
                fill="white"
            />
            <path
                d="M87.6194 29.2099C85.7939 27.3821 83.5772 26.4683 80.9693 26.4683C78.4136 26.4683 76.223 27.3821 74.3975 29.2099C72.5199 31.0377 71.5811 33.2571 71.5811 35.8682H90.3576C90.3576 33.2571 89.4449 31.0377 87.6194 29.2099Z"
                fill="#3D465D"
            />
            <path
                d="M15.4875 20.828C15.8526 21.5591 16.4785 22.0291 17.3651 22.238L47.5641 30.3846C48.3986 30.5935 49.207 30.489 49.9894 30.0712C50.7196 29.6535 51.189 29.0007 51.3976 28.1129L53.0406 22.0813C53.2492 21.1935 53.1449 20.4102 52.7276 19.7313C52.2582 18.948 51.6062 18.4519 50.7717 18.243L20.5728 10.0964C19.6861 9.88752 18.9038 10.0181 18.2257 10.4881C17.4434 10.8536 16.9479 11.4803 16.7392 12.3681L15.0963 18.3997C14.8877 19.2874 15.0181 20.0969 15.4875 20.828Z"
                fill="#3D465D"
            />
            <path
                d="M65.0119 30.0712C65.7943 30.489 66.6027 30.5935 67.4373 30.3846L97.6362 22.238C98.5229 22.0291 99.1488 21.5591 99.5139 20.828C99.9833 20.0969 100.114 19.2874 99.905 18.3997L98.2621 12.3681C98.0535 11.4803 97.558 10.8536 96.7756 10.4881C96.0976 10.0181 95.3152 9.88752 94.4285 10.0964L64.2296 18.243C63.3951 18.4519 62.7431 18.948 62.2737 19.7313C61.8564 20.4102 61.7521 21.1935 61.9608 22.0813L63.6037 28.1129C63.8123 29.0007 64.2817 29.6535 65.0119 30.0712Z"
                fill="#3D465D"
            />
            <path
                d="M72.8955 49.4017C72.9477 48.5139 72.6869 47.7567 72.1131 47.13C71.5394 46.4512 70.8353 46.0856 70.0008 46.0334C69.1141 45.9812 68.3578 46.2423 67.7319 46.8167C67.3147 47.1823 66.8714 47.5478 66.4019 47.9134H66.3237C63.8202 49.7934 60.7951 50.7333 57.2484 50.7333C53.5974 50.7333 50.494 49.7934 47.9383 47.9134C47.6254 47.6523 47.3385 47.3911 47.0777 47.13C46.504 46.4512 45.7999 46.0856 44.9654 46.0334C44.0787 45.9812 43.3224 46.2423 42.6965 46.8167C42.0185 47.3911 41.6534 48.1223 41.6012 49.01C41.5491 49.8456 41.8099 50.6028 42.3836 51.2817C42.853 51.8039 43.4267 52.3261 44.1048 52.8483C44.1569 52.8483 44.183 52.8744 44.183 52.9267C47.7819 55.6422 52.137 57 57.2484 57C62.2555 57 66.5323 55.6683 70.079 53.005V52.9267C70.7049 52.4567 71.3047 51.9867 71.8784 51.5167C72.5043 50.9422 72.8433 50.2372 72.8955 49.4017Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-8': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.0976 24.8238C42.0687 21.7427 38.387 20.2021 34.0525 20.2021C29.718 20.2021 26.0363 21.7427 23.0074 24.8238C19.9263 27.8527 18.3857 31.5344 18.3857 35.8689H49.7193C49.7193 31.5344 48.1787 27.8527 45.0976 24.8238Z"
                fill="white"
            />
            <path
                d="M40.7108 29.2099C38.883 27.3822 36.6635 26.4683 34.0524 26.4683C31.4935 26.4683 29.3001 27.3822 27.4724 29.2099C25.5923 31.0377 24.6523 33.2572 24.6523 35.8683H43.4525C43.4525 33.2572 42.5386 31.0377 40.7108 29.2099Z"
                fill="#3D465D"
            />
            <path
                d="M92.0985 24.8234C89.0696 21.7422 85.3879 20.2017 81.0535 20.2017C76.719 20.2017 73.0373 21.7422 70.0084 24.8234C66.9273 27.8523 65.3867 31.5339 65.3867 35.8684H96.7202C96.7202 31.5339 95.1797 27.8523 92.0985 24.8234Z"
                fill="white"
            />
            <path
                d="M87.7118 29.2095C85.884 27.3817 83.6645 26.4678 81.0534 26.4678C78.4945 26.4678 76.3011 27.3817 74.4733 29.2095C72.5933 31.0372 71.6533 33.2567 71.6533 35.8678H90.4534C90.4534 33.2567 89.5395 31.0372 87.7118 29.2095Z"
                fill="#3D465D"
            />
            <path
                d="M15.4881 20.8281C15.8536 21.5593 16.4803 22.0293 17.3681 22.2381L47.6049 30.3849C48.4405 30.5938 49.2499 30.4893 50.0333 30.0715C50.7644 29.6537 51.2344 29.001 51.4433 28.1132L53.0883 22.0815C53.2972 21.1937 53.1927 20.4104 52.775 19.7315C52.305 18.9481 51.6522 18.452 50.8166 18.2431L20.5798 10.0964C19.692 9.88752 18.9087 10.0181 18.2298 10.4881C17.4464 10.8536 16.9503 11.4803 16.7414 12.3681L15.0964 18.3998C14.8875 19.2876 15.0181 20.097 15.4881 20.8281Z"
                fill="#3D465D"
            />
            <path
                d="M65.0725 30.0715C65.8559 30.4893 66.6653 30.5938 67.5009 30.3849L97.7377 22.2381C98.6255 22.0293 99.2522 21.5593 99.6178 20.8281C100.088 20.097 100.218 19.2876 100.009 18.3998L98.3644 12.3681C98.1555 11.4803 97.6594 10.8536 96.8761 10.4881C96.1972 10.0181 95.4138 9.88752 94.5261 10.0964L64.2892 18.2431C63.4537 18.452 62.8009 18.9481 62.3309 19.7315C61.9131 20.4104 61.8086 21.1937 62.0175 22.0815L63.6625 28.1132C63.8714 29.001 64.3414 29.6537 65.0725 30.0715Z"
                fill="#3D465D"
            />
            <path
                d="M67.8762 50.5764C68.294 49.8453 68.3985 49.088 68.1896 48.3047C67.9284 47.4691 67.4062 46.8686 66.6229 46.503C65.8918 46.033 65.0823 45.9286 64.1945 46.1897C63.359 46.3986 62.7062 46.8425 62.2362 47.5214C61.8184 48.0958 61.2179 48.6441 60.4345 49.1664C58.5023 50.3675 56.2306 50.968 53.6195 50.968C52.4184 50.968 51.2956 50.8636 50.2511 50.6547C49.3633 50.498 48.5539 50.6547 47.8228 51.1247C47.0917 51.4903 46.6217 52.1169 46.4128 53.0047C46.2039 53.7881 46.3605 54.5453 46.8828 55.2764C47.3005 55.9031 47.9794 56.3209 48.9194 56.5297C50.3295 56.8431 51.8961 56.9998 53.6195 56.9998C57.6406 56.9998 61.1134 56.0597 64.0379 54.1797L63.9595 54.2581C65.7351 53.1614 67.0407 51.9342 67.8762 50.5764Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-9': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.0903 32.3276H20.3018C20.3018 36.1215 21.7664 39.3439 24.6956 41.9951C27.5751 44.6919 31.0753 46.0403 35.196 46.0403C39.3168 46.0403 42.8169 44.6919 45.6965 41.9951C48.6257 39.3439 50.0903 36.1215 50.0903 32.3276Z"
                fill="white"
            />
            <path
                d="M44.1319 32.3276H26.2588C26.2588 34.6131 27.1524 36.5557 28.9398 38.1555C30.6774 39.7553 32.7626 40.5552 35.1954 40.5552C37.6777 40.5552 39.7878 39.7553 41.5254 38.1555C43.2631 36.5557 44.1319 34.6131 44.1319 32.3276Z"
                fill="#3D465D"
            />
            <path
                d="M94.7729 32.3276H64.9844C64.9844 36.1215 66.449 39.3439 69.3782 41.9951C72.2577 44.6919 75.7579 46.0403 79.8786 46.0403C83.9994 46.0403 87.4995 44.6919 90.3791 41.9951C93.3083 39.3439 94.7729 36.1215 94.7729 32.3276Z"
                fill="white"
            />
            <path
                d="M88.8155 32.3276H70.9424C70.9424 34.6131 71.836 36.5557 73.6234 38.1555C75.361 39.7553 77.4462 40.5552 79.8789 40.5552C82.3613 40.5552 84.4713 39.7553 86.209 38.1555C87.9467 36.5557 88.8155 34.6131 88.8155 32.3276Z"
                fill="#3D465D"
            />
            <path
                d="M20.0037 26.568C20.7484 26.9337 21.5179 27.0251 22.3123 26.8423L51.0582 19.7117C51.9022 19.5289 52.498 19.1175 52.8455 18.4776C53.2924 17.8376 53.4165 17.1291 53.2179 16.3521L51.654 11.0727C51.4554 10.2957 50.9838 9.74716 50.239 9.4272C49.5936 9.01582 48.8489 8.90155 48.0049 9.08439L19.259 16.215C18.4646 16.3978 17.844 16.832 17.3972 17.5177C17 18.1119 16.9007 18.7975 17.0993 19.5746L18.6632 24.8539C18.8618 25.631 19.3086 26.2023 20.0037 26.568Z"
                fill="#3D465D"
            />
            <path
                d="M62.1535 23.8942C62.5506 24.5799 63.1464 25.037 63.9408 25.2655L92.6867 32.3275C93.5307 32.5104 94.3003 32.4189 94.9953 32.0533C95.6904 31.6876 96.1372 31.1162 96.3358 30.3392L97.8997 25.0598C98.0983 24.2828 97.999 23.5971 97.6018 23.0029C97.2047 22.3173 96.5841 21.8831 95.74 21.7002L66.9941 14.6382C66.1997 14.4097 65.455 14.4782 64.76 14.8439C64.0153 15.2096 63.5436 15.7809 63.345 16.558L61.7811 21.8373C61.5825 22.6144 61.7066 23.3 62.1535 23.8942Z"
                fill="#3D465D"
            />
            <path
                d="M75.3853 50.8922C72.0092 48.5611 67.9381 47.3955 63.172 47.3955C58.3065 47.3955 54.1609 48.5839 50.7352 50.9608C50.7352 51.0065 50.7104 51.0294 50.6608 51.0294C50.0154 51.4864 49.4692 51.9435 49.0224 52.4006C48.4763 52.9948 48.228 53.6576 48.2777 54.389C48.3273 55.166 48.6749 55.8059 49.3203 56.3087C49.9161 56.8115 50.6359 57.0401 51.48 56.9944C52.2743 56.9487 52.9446 56.6287 53.4907 56.0345C53.7389 55.8059 54.012 55.5774 54.3099 55.3488C56.7426 53.7033 59.6966 52.8806 63.172 52.8806C66.548 52.8806 69.4276 53.7033 71.8106 55.3488H71.8851C72.3319 55.6688 72.7539 55.9888 73.1511 56.3087C73.7469 56.8115 74.4668 57.0401 75.3108 56.9944C76.1052 56.9487 76.7754 56.6287 77.3215 56.0345C77.8676 55.486 78.1159 54.8232 78.0662 54.0461C78.0166 53.3148 77.6939 52.6977 77.0981 52.1949C76.552 51.7836 75.981 51.3722 75.3853 50.9608V50.8922Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-10': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.0904 34.5176H20.3018C20.3018 38.6676 21.7664 42.1926 24.6956 45.0926C27.5752 48.0426 31.0753 49.5176 35.1961 49.5176C39.3169 49.5176 42.817 48.0426 45.6966 45.0926C48.6258 42.1926 50.0904 38.6676 50.0904 34.5176Z"
                fill="white"
            />
            <path
                d="M44.132 34.5176H26.2588C26.2588 37.0176 27.1525 39.1426 28.9398 40.8926C30.6774 42.6426 32.7627 43.5176 35.1954 43.5176C37.6778 43.5176 39.7878 42.6426 41.5255 40.8926C43.2632 39.1426 44.132 37.0176 44.132 34.5176Z"
                fill="#3D465D"
            />
            <path
                d="M94.774 34.5171H64.9854C64.9854 38.6671 66.45 42.1921 69.3792 45.0921C72.2588 48.0421 75.7589 49.5171 79.8797 49.5171C84.0005 49.5171 87.5006 48.0421 90.3802 45.0921C93.3094 42.1921 94.774 38.6671 94.774 34.5171Z"
                fill="white"
            />
            <path
                d="M88.8156 34.5171H70.9424C70.9424 37.0171 71.836 39.1421 73.6234 40.8921C75.361 42.6421 77.4462 43.5171 79.879 43.5171C82.3614 43.5171 84.4714 42.6421 86.2091 40.8921C87.9468 39.1421 88.8156 37.0171 88.8156 34.5171Z"
                fill="#3D465D"
            />
            <path
                d="M20.0037 28.2173C20.7484 28.6173 21.5179 28.7173 22.3123 28.5173L51.0584 20.7173C51.9024 20.5173 52.4982 20.0673 52.8457 19.3673C53.2925 18.6673 53.4167 17.8923 53.2181 17.0423L51.6542 11.2673C51.4556 10.4173 50.9839 9.81731 50.2392 9.46731C49.5938 9.01731 48.8491 8.89231 48.005 9.09231L19.259 16.8923C18.4646 17.0923 17.844 17.5673 17.3972 18.3173C17 18.9673 16.9007 19.7173 17.0993 20.5673L18.6632 26.3423C18.8618 27.1923 19.3086 27.8173 20.0037 28.2173Z"
                fill="#3D465D"
            />
            <path
                d="M62.1544 25.2927C62.5516 26.0427 63.1474 26.5427 63.9418 26.7927L92.6878 34.5177C93.5318 34.7177 94.3014 34.6177 94.9965 34.2177C95.6915 33.8177 96.1384 33.1927 96.3369 32.3427L97.9009 26.5677C98.0994 25.7177 98.0002 24.9677 97.603 24.3177C97.2058 23.5677 96.5852 23.0927 95.7412 22.8927L66.9951 15.1677C66.2007 14.9177 65.456 14.9927 64.761 15.3927C64.0162 15.7927 63.5446 16.4177 63.346 17.2677L61.7821 23.0427C61.5835 23.8927 61.7076 24.6427 62.1544 25.2927Z"
                fill="#3D465D"
            />
            <path
                d="M54.2354 57H73.598"
                stroke="#3D465D"
                strokeWidth="3.5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-11': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.0903 32.3301H20.3018C20.3018 36.1243 21.7664 39.3472 24.6956 41.9986C27.5751 44.6957 31.0753 46.0443 35.196 46.0443C39.3168 46.0443 42.8169 44.6957 45.6965 41.9986C48.6257 39.3472 50.0903 36.1243 50.0903 32.3301Z"
                fill="white"
            />
            <path
                d="M44.1319 32.3301H26.2588C26.2588 34.6158 27.1524 36.5586 28.9398 38.1586C30.6774 39.7586 32.7626 40.5586 35.1954 40.5586C37.6777 40.5586 39.7878 39.7586 41.5254 38.1586C43.2631 36.5586 44.1319 34.6158 44.1319 32.3301Z"
                fill="#3D465D"
            />
            <path
                d="M94.7729 32.3301H64.9844C64.9844 36.1243 66.449 39.3472 69.3782 41.9986C72.2577 44.6957 75.7579 46.0443 79.8786 46.0443C83.9994 46.0443 87.4995 44.6957 90.3791 41.9986C93.3083 39.3472 94.7729 36.1243 94.7729 32.3301Z"
                fill="white"
            />
            <path
                d="M88.8155 32.3301H70.9424C70.9424 34.6158 71.836 36.5586 73.6234 38.1586C75.361 39.7586 77.4462 40.5586 79.8789 40.5586C82.3613 40.5586 84.4713 39.7586 86.209 38.1586C87.9467 36.5586 88.8155 34.6158 88.8155 32.3301Z"
                fill="#3D465D"
            />
            <path
                d="M20.0037 26.57C20.7484 26.9357 21.5179 27.0271 22.3123 26.8443L51.0582 19.7129C51.9022 19.53 52.498 19.1186 52.8455 18.4786C53.2924 17.8386 53.4165 17.13 53.2179 16.3529L51.654 11.073C51.4554 10.2958 50.9838 9.74725 50.239 9.42725C49.5936 9.01582 48.8489 8.90154 48.0049 9.08439L19.259 16.2158C18.4646 16.3986 17.844 16.8329 17.3972 17.5186C17 18.1129 16.9007 18.7986 17.0993 19.5757L18.6632 24.8557C18.8618 25.6328 19.3086 26.2043 20.0037 26.57Z"
                fill="#3D465D"
            />
            <path
                d="M62.1535 23.8963C62.5506 24.582 63.1464 25.0391 63.9408 25.2677L92.6867 32.3305C93.5307 32.5133 94.3003 32.4219 94.9953 32.0562C95.6904 31.6905 96.1372 31.1191 96.3358 30.3419L97.8997 25.062C98.0983 24.2848 97.999 23.5991 97.6018 23.0048C97.2047 22.3191 96.5841 21.8849 95.74 21.702L66.9941 14.6392C66.1997 14.4106 65.455 14.4792 64.76 14.8449C64.0153 15.2106 63.5436 15.782 63.345 16.5592L61.7811 21.8391C61.5825 22.6163 61.7066 23.302 62.1535 23.8963Z"
                fill="#3D465D"
            />
            <path
                d="M78.0662 50.3488C78.1159 49.5717 77.8676 48.9088 77.3215 48.3602C76.7754 47.766 76.1052 47.446 75.3108 47.4002C74.4668 47.3545 73.7469 47.5831 73.1511 48.086C72.7539 48.406 72.3319 48.726 71.8851 49.0459H71.8106C69.4276 50.6917 66.548 51.5145 63.172 51.5145C59.6966 51.5145 56.7426 50.6917 54.3099 49.0459C54.012 48.8174 53.7389 48.5888 53.4907 48.3602C52.9446 47.766 52.2743 47.446 51.48 47.4002C50.6359 47.3545 49.9161 47.5831 49.3203 48.086C48.6749 48.5888 48.3273 49.2288 48.2777 50.0059C48.228 50.7374 48.4763 51.4002 49.0224 51.9945C49.4692 52.4516 50.0154 52.9088 50.6608 53.3659C50.7104 53.3659 50.7352 53.3888 50.7352 53.4345C54.1609 55.8116 58.3065 57.0002 63.172 57.0002C67.9381 57.0002 72.0092 55.8345 75.3853 53.5031V53.4345C75.981 53.0231 76.552 52.6116 77.0981 52.2002C77.6939 51.6974 78.0166 51.0802 78.0662 50.3488Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-12': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.0903 32.2563H20.3018C20.3018 36.0386 21.7664 39.2512 24.6956 41.8943C27.5751 44.5828 31.0753 45.9271 35.196 45.9271C39.3168 45.9271 42.8169 44.5828 45.6965 41.8943C48.6257 39.2512 50.0903 36.0386 50.0903 32.2563Z"
                fill="white"
            />
            <path
                d="M44.1319 32.2563H26.2588C26.2588 34.5348 27.1524 36.4715 28.9398 38.0664C30.6774 39.6614 32.7626 40.4588 35.1954 40.4588C37.6777 40.4588 39.7878 39.6614 41.5254 38.0664C43.2631 36.4715 44.1319 34.5348 44.1319 32.2563Z"
                fill="#3D465D"
            />
            <path
                d="M94.7729 32.2563H64.9844C64.9844 36.0386 66.449 39.2512 69.3782 41.8943C72.2577 44.5828 75.7579 45.9271 79.8786 45.9271C83.9994 45.9271 87.4995 44.5828 90.3791 41.8943C93.3083 39.2512 94.7729 36.0386 94.7729 32.2563Z"
                fill="white"
            />
            <path
                d="M88.8155 32.2563H70.9424C70.9424 34.5348 71.836 36.4715 73.6234 38.0664C75.361 39.6614 77.4462 40.4588 79.8789 40.4588C82.3613 40.4588 84.4713 39.6614 86.209 38.0664C87.9467 36.4715 88.8155 34.5348 88.8155 32.2563Z"
                fill="#3D465D"
            />
            <path
                d="M20.0037 26.5144C20.7484 26.8789 21.5179 26.9701 22.3123 26.7878L51.0582 19.679C51.9022 19.4967 52.498 19.0866 52.8455 18.4486C53.2924 17.8106 53.4165 17.1043 53.2179 16.3296L51.654 11.0664C51.4554 10.2917 50.9838 9.74488 50.239 9.4259C49.5936 9.01577 48.8489 8.90185 48.0049 9.08413L19.259 16.1929C18.4646 16.3752 17.844 16.8081 17.3972 17.4917C17 18.0841 16.9007 18.7676 17.0993 19.5423L18.6632 24.8055C18.8618 25.5802 19.3086 26.1498 20.0037 26.5144Z"
                fill="#3D465D"
            />
            <path
                d="M62.1535 23.849C62.5506 24.5325 63.1464 24.9882 63.9408 25.2161L92.6867 32.2565C93.5307 32.4388 94.3003 32.3477 94.9953 31.9831C95.6904 31.6185 96.1372 31.0489 96.3358 30.2743L97.8997 25.011C98.0983 24.2363 97.999 23.5528 97.6018 22.9604C97.2047 22.2768 96.5841 21.8439 95.74 21.6617L66.9941 14.6212C66.1997 14.3933 65.455 14.4617 64.76 14.8263C64.0153 15.1908 63.5436 15.7604 63.345 16.5351L61.7811 21.7984C61.5825 22.573 61.7066 23.2566 62.1535 23.849Z"
                fill="#3D465D"
            />
            <path
                d="M72.2821 47.8408C71.5871 47.4307 70.8175 47.3395 69.9735 47.5674C69.1791 47.7497 68.5585 48.137 68.1117 48.7294C67.7145 49.2307 67.1436 49.7091 66.3989 50.1648C64.5619 51.2129 62.4023 51.737 59.9199 51.737C58.778 51.737 57.7106 51.6458 56.7176 51.4635C55.8736 51.3268 55.1041 51.4635 54.409 51.8737C53.7139 52.1927 53.2671 52.7395 53.0685 53.5142C52.8699 54.1977 53.0189 54.8585 53.5153 55.4964C53.9125 56.0433 54.5579 56.4078 55.4516 56.5901C56.7921 56.8635 58.2815 57.0002 59.9199 57.0002C63.7427 57.0002 67.0443 56.18 69.8246 54.5395L69.7501 54.6078C71.4381 53.6509 72.6793 52.58 73.4737 51.3952C73.8708 50.7572 73.9701 50.0965 73.7715 49.4129C73.5233 48.6838 73.0268 48.1598 72.2821 47.8408Z"
                fill="#37393F"
            />
        </svg>
    ),
    'face-13': (
        <svg
            width="115"
            height="68"
            viewBox="0 0 115 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9133 16.0316C17.6699 16.4973 18.543 16.6137 19.5326 16.3809L46.5113 9.13414C47.4426 8.90132 48.1702 8.37746 48.6941 7.56256C49.1015 6.74767 49.1597 5.84547 48.8687 4.85595L48.4322 3.19707C48.1993 2.20755 47.7046 1.50907 46.9479 1.10163C46.0748 0.577766 45.1726 0.432249 44.2413 0.665076L17.2625 7.9118C16.273 8.14463 15.5745 8.69759 15.1671 9.57069C14.6432 10.3274 14.4977 11.2005 14.7305 12.19L15.1671 13.8489C15.4581 14.8384 16.0402 15.566 16.9133 16.0316Z"
                fill="#3D465D"
            />
            <path
                d="M67.2912 7.64948C67.7568 8.46437 68.4553 8.98823 69.3866 9.22106L96.3654 16.4678C97.3549 16.7006 98.2571 16.6133 99.072 16.2059C99.8869 15.682 100.44 14.9253 100.731 13.9358L101.167 12.2769C101.4 11.2874 101.284 10.4434 100.818 9.74492C100.353 8.81361 99.625 8.23154 98.6354 7.99872L71.6567 0.75199C70.7254 0.519163 69.8523 0.66468 69.0374 1.18854C68.2225 1.59599 67.6986 2.29447 67.4658 3.28398L67.0292 4.94287C66.7382 5.93238 66.8255 6.83459 67.2912 7.64948Z"
                fill="#3D465D"
            />
            <path
                d="M17.7861 23.4528C17.7861 24.4424 18.1354 25.2864 18.8339 25.9848L26.7791 33.93L18.8339 41.9626C18.1354 42.6028 17.7861 43.4177 17.7861 44.4072C17.7861 45.3968 18.1354 46.2407 18.8339 46.9392C19.4741 47.5795 20.289 47.8996 21.2785 47.8996C22.268 47.8996 23.112 47.5795 23.8105 46.9392L31.7557 38.9067L39.7882 46.9392C40.4285 47.5795 41.2434 47.8996 42.2329 47.8996C43.2224 47.8996 44.0664 47.5795 44.7649 46.9392C45.4052 46.2407 45.7253 45.3968 45.7253 44.4072C45.7253 43.4177 45.4052 42.6028 44.7649 41.9626L36.7324 33.93L44.7649 25.9848C45.4052 25.2864 45.7253 24.4424 45.7253 23.4528C45.7253 22.4633 45.4052 21.6484 44.7649 21.0082C44.0664 20.3097 43.2224 19.9604 42.2329 19.9604C41.2434 19.9604 40.4285 20.3097 39.7882 21.0082L31.7557 28.9534L23.8105 21.0082C23.112 20.3097 22.268 19.9604 21.2785 19.9604C20.289 19.9604 19.4741 20.3097 18.8339 21.0082C18.1354 21.6484 17.7861 22.4633 17.7861 23.4528ZM70.1721 23.4528C70.1721 24.4424 70.5214 25.2864 71.2198 25.9848L79.165 33.93L71.2198 41.9626C70.5214 42.6028 70.1721 43.4177 70.1721 44.4072C70.1721 45.3968 70.5214 46.2407 71.2198 46.9392C71.8601 47.5795 72.675 47.8996 73.6645 47.8996C74.654 47.8996 75.498 47.5795 76.1965 46.9392L84.1417 38.9067L92.1742 46.9392C92.8145 47.5795 93.6294 47.8996 94.6189 47.8996C95.6084 47.8996 96.4524 47.5795 97.1509 46.9392C97.7912 46.2407 98.1113 45.3968 98.1113 44.4072C98.1113 43.4177 97.7912 42.6028 97.1509 41.9626L89.1184 33.93L97.1509 25.9848C97.7912 25.2864 98.1113 24.4424 98.1113 23.4528C98.1113 22.4633 97.7912 21.6484 97.1509 21.0082C96.4524 20.3097 95.6084 19.9604 94.6189 19.9604C93.6294 19.9604 92.8145 20.3097 92.1742 21.0082L84.1417 28.9534L76.1965 21.0082C75.498 20.3097 74.654 19.9604 73.6645 19.9604C72.675 19.9604 71.8601 20.3097 71.2198 21.0082C70.5214 21.6484 70.1721 22.4633 70.1721 23.4528Z"
                fill="#3D465D"
            />
            <path
                d="M68.164 65.0995C68.6879 65.4488 69.1826 65.8271 69.6483 66.2346C70.3468 66.8748 71.1908 67.1659 72.1803 67.1077C73.1116 67.0495 73.8974 66.6711 74.5377 65.9726C75.1779 65.216 75.469 64.372 75.4108 63.4407C75.3525 62.4511 74.9742 61.6362 74.2757 60.996C73.6355 60.4721 72.9661 59.9483 72.2676 59.4244C68.3095 56.3976 63.5366 54.8843 57.9488 54.8843C52.2445 54.8843 47.3843 56.3976 43.368 59.4244C43.368 59.4826 43.3389 59.5117 43.2807 59.5117C42.524 60.0938 41.8837 60.7049 41.3599 61.3452C40.7196 62.0437 40.4286 62.8877 40.4868 63.8772C40.545 64.8085 40.9524 65.5943 41.7091 66.2346C42.4076 66.8748 43.2516 67.1659 44.2411 67.1077C45.1724 67.0495 45.9582 66.6711 46.5985 65.9726C46.8895 65.6234 47.2096 65.3033 47.5589 65.0122C50.411 62.9168 53.8743 61.8691 57.9488 61.8691C61.9068 61.8691 65.2828 62.9168 68.0767 65.0122L68.164 65.0995Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-14': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M73.9748 53.624C73.3521 52.8881 72.588 52.4919 71.6823 52.4353C70.7201 52.3787 69.8993 52.6617 69.2201 53.2844C68.7672 53.6806 68.2861 54.0768 67.7767 54.473H67.6918C64.9748 56.5108 61.6918 57.5296 57.8427 57.5296C53.8804 57.5296 50.5125 56.5108 47.7389 54.473C47.3993 54.19 47.088 53.907 46.805 53.624C46.1823 52.8881 45.4182 52.4919 44.5125 52.4353C43.5502 52.3787 42.7295 52.6617 42.0502 53.2844C41.3144 53.907 40.9182 54.6994 40.8616 55.6617C40.805 56.5674 41.088 57.3881 41.7106 58.124C42.2201 58.69 42.8427 59.2561 43.5785 59.8221C43.6352 59.8221 43.6635 59.8504 43.6635 59.907C47.5691 62.8504 52.2955 64.3221 57.8427 64.3221C63.2767 64.3221 67.9182 62.8787 71.7672 59.9919V59.907C72.4465 59.3976 73.0974 58.8881 73.7201 58.3787C74.3993 57.7561 74.7672 56.9919 74.8238 56.0862C74.8804 55.124 74.5974 54.3032 73.9748 53.624Z"
                fill="#3D465D"
            />
            <path
                d="M17.9361 18.0479C18.672 18.5007 19.521 18.6139 20.4833 18.3875L46.7191 11.3403C47.6248 11.1139 48.3324 10.6045 48.8418 9.81205C49.238 9.01959 49.2946 8.14223 49.0116 7.17997L48.5871 5.56676C48.3607 4.6045 47.8795 3.92525 47.1437 3.52903C46.2946 3.01959 45.4173 2.87808 44.5116 3.1045L18.2757 10.1517C17.3135 10.3781 16.6342 10.9158 16.238 11.7649C15.7286 12.5007 15.5871 13.3498 15.8135 14.312L16.238 15.9253C16.521 16.8875 17.0871 17.5951 17.9361 18.0479Z"
                fill="#3D465D"
            />
            <path
                d="M66.9268 9.89652C67.3796 10.689 68.0589 11.1984 68.9645 11.4248L95.2004 18.472C96.1626 18.6984 97.04 18.6135 97.8324 18.2173C98.6249 17.7078 99.1626 16.972 99.4457 16.0097L99.8702 14.3965C100.097 13.4343 99.9834 12.6135 99.5306 11.9343C99.0777 11.0286 98.3702 10.4626 97.4079 10.2361L71.1721 3.18897C70.2664 2.96256 69.4174 3.10407 68.6249 3.6135C67.8324 4.00973 67.323 4.68897 67.0966 5.65124L66.6721 7.26444C66.3891 8.22671 66.474 9.10407 66.9268 9.89652Z"
                fill="#3D465D"
            />
            <path
                d="M31.5218 25.2642C25.918 25.2642 21.135 27.2453 17.1727 31.2076C15.8708 32.5094 14.7954 33.8962 13.9463 35.3679V35.4528C13.1538 36.7547 12.5595 38.1415 12.1633 39.6132C11.8803 40.5189 11.9652 41.3679 12.418 42.1604C12.8708 43.0094 13.5501 43.5472 14.4557 43.7736C15.3614 44.0566 16.2388 43.9717 17.0878 43.5189C17.8803 43.066 18.418 42.3868 18.701 41.4811C18.9274 40.5755 19.2954 39.6981 19.8048 38.8491C20.3708 37.8868 21.1067 36.9528 22.0123 36.0472C24.6161 33.3868 27.7859 32.0566 31.5218 32.0566C35.201 32.0566 38.3708 33.3868 41.0312 36.0472C41.8803 36.9528 42.6444 37.8868 43.3237 38.8491C43.7765 39.6981 44.1727 40.6038 44.5123 41.566C44.7954 42.4717 45.3614 43.1227 46.2104 43.5189C47.0595 43.9717 47.9369 44.0566 48.8425 43.7736C49.635 43.4906 50.2576 42.9245 50.7104 42.0755C51.1067 41.2264 51.1633 40.3491 50.8803 39.4434C50.4274 38.0849 49.8614 36.7547 49.1821 35.4528C49.1255 35.3962 49.0972 35.3396 49.0972 35.283C48.135 33.8679 47.0595 32.5094 45.8708 31.2076V31.2925C41.9086 27.2736 37.1255 25.2642 31.5218 25.2642Z"
                fill="#3D465D"
            />
            <path
                d="M82.4651 25.2642C76.8614 25.2642 72.0783 27.2453 68.1161 31.2076C66.8142 32.5094 65.7387 33.8962 64.8897 35.3679V35.4528C64.0972 36.7547 63.5029 38.1415 63.1066 39.6132C62.8236 40.5189 62.9085 41.3679 63.3614 42.1604C63.8142 43.0094 64.4934 43.5472 65.3991 43.7736C66.3048 44.0566 67.1821 43.9717 68.0312 43.5189C68.8236 43.066 69.3614 42.3868 69.6444 41.4811C69.8708 40.5755 70.2387 39.6981 70.7481 38.8491C71.3142 37.8868 72.05 36.9528 72.9557 36.0472C75.5595 33.3868 78.7293 32.0566 82.4651 32.0566C86.1444 32.0566 89.3142 33.3868 91.9746 36.0472C92.8236 36.9528 93.5878 37.8868 94.267 38.8491C94.7199 39.6981 95.1161 40.6038 95.4557 41.566C95.7387 42.4717 96.3048 43.1227 97.1538 43.5189C98.0029 43.9717 98.8802 44.0566 99.7859 43.7736C100.578 43.4906 101.201 42.9245 101.654 42.0755C102.05 41.2264 102.107 40.3491 101.824 39.4434C101.371 38.0849 100.805 36.7547 100.126 35.4528C100.069 35.3962 100.041 35.3396 100.041 35.283C99.0783 33.8679 98.0029 32.5094 96.8142 31.2076V31.2925C92.8519 27.2736 88.0689 25.2642 82.4651 25.2642Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-15': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M72.3379 63.4366C72.2862 62.4771 71.9502 61.687 71.3299 61.0662C70.7613 60.5582 70.1668 60.0503 69.5465 59.5423C66.0313 56.6075 61.7925 55.1401 56.8299 55.1401C51.7639 55.1401 47.4476 56.6075 43.8807 59.5423C43.8807 59.5988 43.8549 59.627 43.8032 59.627C43.1312 60.1914 42.5625 60.784 42.0973 61.4048C41.5287 62.082 41.2702 62.9004 41.3219 63.8599C41.3736 64.7629 41.7354 65.5248 42.4075 66.1456C43.0278 66.7664 43.7773 67.0486 44.6561 66.9922C45.4832 66.9357 46.1811 66.5689 46.7497 65.8916C47.0082 65.553 47.2925 65.2426 47.6026 64.9604C50.1356 62.9286 53.2114 61.9127 56.8299 61.9127C60.345 61.9127 63.3433 62.9286 65.8245 64.9604L65.9021 65.0451C66.3673 65.3837 66.8067 65.7505 67.2203 66.1456C67.8406 66.7664 68.5901 67.0486 69.4689 66.9922C70.296 66.9357 70.9939 66.5689 71.5625 65.8916C72.1311 65.1579 72.3896 64.3396 72.3379 63.4366Z"
                fill="#3D465D"
            />
            <path
                d="M17.0531 8.91725C16.1743 9.143 15.554 9.65095 15.1921 10.4411C14.7269 11.2312 14.5976 12.106 14.8044 13.0655L16.4328 19.5841C16.6395 20.5435 17.1306 21.2208 17.906 21.6159C18.578 22.1238 19.3534 22.2649 20.2322 22.0392L50.1627 13.2348C50.9898 13.009 51.6359 12.4729 52.1012 11.6263C52.5147 10.8926 52.6181 10.046 52.4113 9.08657L50.783 2.56794C50.5762 1.60849 50.111 0.903014 49.3873 0.451507C48.6119 9.58735e-07 47.8106 -0.112877 46.9835 0.112876L17.0531 8.91725Z"
                fill="#3D465D"
            />
            <path
                d="M66.6796 0.112876C65.8525 -0.112877 65.0513 9.58735e-07 64.2759 0.451507C63.5522 0.903014 63.0869 1.60849 62.8802 2.56794L61.2518 9.08657C61.0451 10.046 61.1484 10.8926 61.562 11.6263C62.0272 12.4729 62.6734 13.009 63.5005 13.2348L93.4309 22.0392C94.3097 22.2649 95.0851 22.1238 95.7571 21.6159C96.5325 21.2208 97.0236 20.5435 97.2304 19.5841L98.8587 13.0655C99.0655 12.106 98.9363 11.2312 98.471 10.4411C98.1092 9.65095 97.4889 9.143 96.6101 8.91725L66.6796 0.112876Z"
                fill="#3D465D"
            />
            <path
                d="M50.3171 33.2978C49.9036 32.4513 49.335 31.8869 48.6113 31.6047C47.7842 31.3225 46.9829 31.4072 46.2075 31.8587C45.4321 32.2537 44.9152 32.9028 44.6567 33.8058C44.3465 34.7652 43.9847 35.6683 43.5711 36.5148C42.9508 37.4743 42.253 38.4055 41.4776 39.3085C39.048 41.9611 36.1532 43.2874 32.7931 43.2874C29.3813 43.2874 26.4865 41.9611 24.1086 39.3085C23.2815 38.4055 22.6095 37.4743 22.0926 36.5148C21.6273 35.6683 21.2913 34.7935 21.0845 33.8904C20.8261 32.9874 20.335 32.3102 19.6113 31.8587C18.8359 31.4072 18.0346 31.3225 17.2075 31.6047C16.3804 31.8305 15.7601 32.3666 15.3466 33.2132C14.933 34.0033 14.8555 34.8499 15.114 35.7529C15.4758 37.2203 16.0186 38.603 16.7423 39.9011V39.9858C17.5177 41.4532 18.4999 42.8359 19.6888 44.134C23.3074 48.0847 27.6754 50.06 32.7931 50.06C37.9107 50.06 42.2788 48.0565 45.8974 44.0493V44.134C46.9829 42.8359 47.9651 41.4814 48.8439 40.0704C48.8439 40.014 48.8697 39.9576 48.9214 39.9011C49.5417 38.603 50.0587 37.2767 50.4722 35.9222C50.7307 35.0192 50.679 34.1444 50.3171 33.2978Z"
                fill="#3D465D"
            />
            <path
                d="M96.8425 33.2978C96.429 32.4513 95.8604 31.8869 95.1366 31.6047C94.3096 31.3225 93.5083 31.4072 92.7329 31.8587C91.9575 32.2537 91.4406 32.9028 91.1821 33.8058C90.8719 34.7652 90.5101 35.6683 90.0965 36.5148C89.4762 37.4743 88.7784 38.4055 88.003 39.3085C85.5734 41.9611 82.6785 43.2874 79.3185 43.2874C75.9067 43.2874 73.0119 41.9611 70.634 39.3085C69.8069 38.4055 69.1349 37.4743 68.618 36.5148C68.1527 35.6683 67.8167 34.7935 67.6099 33.8904C67.3515 32.9874 66.8604 32.3102 66.1367 31.8587C65.3613 31.4072 64.56 31.3225 63.7329 31.6047C62.9058 31.8305 62.2855 32.3666 61.872 33.2132C61.4584 34.0033 61.3809 34.8499 61.6393 35.7529C62.0012 37.2203 62.544 38.603 63.2677 39.9011V39.9858C64.0431 41.4532 65.0253 42.8359 66.2142 44.134C69.8327 48.0847 74.2008 50.06 79.3185 50.06C84.4361 50.06 88.8042 48.0565 92.4227 44.0493V44.134C93.5083 42.8359 94.4905 41.4814 95.3693 40.0704C95.3693 40.014 95.3951 39.9576 95.4468 39.9011C96.0671 38.603 96.5841 37.2767 96.9976 35.9222C97.2561 35.0192 97.2044 34.1444 96.8425 33.2978Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-16': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.7749 24.8333C39.074 21.0792 34.5755 19.2021 29.2794 19.2021C23.9832 19.2021 19.4847 21.0792 15.7838 24.8333C12.0191 28.5237 10.1367 33.0095 10.1367 38.2906H48.422C48.422 33.0095 46.5396 28.5237 42.7749 24.8333Z"
                fill="white"
            />
            <path
                d="M37.4152 30.1779C35.1818 27.9509 32.47 26.8374 29.2795 26.8374C26.1529 26.8374 23.4729 27.9509 21.2396 30.1779C18.9425 32.4049 17.7939 35.1091 17.7939 38.2905H40.7651C40.7651 35.1091 39.6485 32.4049 37.4152 30.1779Z"
                fill="#3D465D"
            />
            <path
                d="M100.203 24.8333C96.5017 21.0792 92.0032 19.2021 86.7071 19.2021C81.411 19.2021 76.9124 21.0792 73.2115 24.8333C69.4468 28.5237 67.5645 33.0095 67.5645 38.2906H105.85C105.85 33.0095 103.967 28.5237 100.203 24.8333Z"
                fill="white"
            />
            <path
                d="M94.8429 30.1779C92.6096 27.9509 89.8977 26.8374 86.7073 26.8374C83.5806 26.8374 80.9007 27.9509 78.6674 30.1779C76.3702 32.4049 75.2217 35.1091 75.2217 38.2905H98.1929C98.1929 35.1091 97.0762 32.4049 94.8429 30.1779Z"
                fill="#3D465D"
            />
            <path
                d="M8.12762 24.1647L9.65903 29.7004C9.91426 30.7821 10.4885 31.5774 11.3819 32.0865C12.2752 32.5955 13.2323 32.7228 14.2533 32.4682L43.8286 24.5465C44.9134 24.292 45.711 23.6875 46.2215 22.7331C46.7319 21.9059 46.8596 20.9515 46.6043 19.8698L45.0729 14.3342C44.8177 13.3161 44.2434 12.5208 43.3501 11.9481C42.4568 11.5027 41.4677 11.4073 40.383 11.6618L10.8076 19.5835C9.78665 19.838 9.02094 20.4107 8.51047 21.3015C8 22.1923 7.87238 23.1467 8.12762 24.1647Z"
                fill="#3D465D"
            />
            <path
                d="M69.862 13.3797C70.3087 14.2069 71.0744 14.7477 72.1591 15.0022L101.734 22.9239C102.755 23.2421 103.744 23.1784 104.702 22.733C105.531 22.1604 106.073 21.365 106.329 20.347L107.86 14.8113C108.115 13.7296 108.02 12.7752 107.573 11.948C106.999 10.9936 106.201 10.3573 105.18 10.0392L75.6048 2.11747C74.52 1.86295 73.5629 2.02203 72.7334 2.59468C71.7763 3.04008 71.1701 3.80362 70.9148 4.8853L69.3834 10.421C69.1282 11.439 69.2877 12.4253 69.862 13.3797Z"
                fill="#3D465D"
            />
            <path
                d="M81.1269 56.4974C76.7879 53.2524 71.5556 51.6299 65.4299 51.6299C59.1767 51.6299 53.8486 53.2842 49.4458 56.5929C49.4458 56.6565 49.4139 56.6883 49.3501 56.6883C48.5206 57.3246 47.8187 57.9609 47.2444 58.5972C46.5425 59.4244 46.2235 60.347 46.2873 61.365C46.3511 62.4467 46.7977 63.3375 47.6273 64.0374C48.393 64.7373 49.3182 65.0555 50.4029 64.9918C51.4239 64.9282 52.2853 64.4828 52.9872 63.6556C53.3062 63.3375 53.6572 63.0194 54.04 62.7012C57.1667 60.4106 60.9633 59.2653 65.4299 59.2653C69.7689 59.2653 73.4698 60.4106 76.5326 62.7012H76.6284C77.2026 63.1466 77.745 63.592 78.2555 64.0374C79.0212 64.7373 79.9464 65.0555 81.0312 64.9918C82.0521 64.9282 82.9135 64.4828 83.6154 63.6556C84.3173 62.8921 84.6364 61.9695 84.5726 60.8878C84.5088 59.8698 84.094 59.0108 83.3283 58.3109C82.6264 57.7382 81.8926 57.1655 81.1269 56.5929V56.4974Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-17': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.7749 24.8359C39.074 21.0814 34.5755 19.2041 29.2794 19.2041C23.9832 19.2041 19.4847 21.0814 15.7838 24.8359C12.0191 28.5268 10.1367 33.0131 10.1367 38.2949H48.422C48.422 33.0131 46.5396 28.5268 42.7749 24.8359Z"
                fill="white"
            />
            <path
                d="M37.4152 30.1812C35.1818 27.954 32.47 26.8403 29.2795 26.8403C26.1529 26.8403 23.4729 27.954 21.2396 30.1812C18.9425 32.4085 17.7939 35.113 17.7939 38.2948H40.7651C40.7651 35.113 39.6485 32.4085 37.4152 30.1812Z"
                fill="#3D465D"
            />
            <path
                d="M100.203 24.8359C96.5017 21.0814 92.0032 19.2041 86.7071 19.2041C81.411 19.2041 76.9124 21.0814 73.2115 24.8359C69.4468 28.5268 67.5645 33.0131 67.5645 38.2949H105.85C105.85 33.0131 103.967 28.5268 100.203 24.8359Z"
                fill="white"
            />
            <path
                d="M94.8429 30.1812C92.6096 27.954 89.8977 26.8403 86.7073 26.8403C83.5806 26.8403 80.9007 27.954 78.6674 30.1812C76.3702 32.4085 75.2217 35.113 75.2217 38.2948H98.1929C98.1929 35.113 97.0762 32.4085 94.8429 30.1812Z"
                fill="#3D465D"
            />
            <path
                d="M8.12762 24.1672L9.65903 29.7035C9.91426 30.7853 10.4885 31.5808 11.3819 32.0899C12.2752 32.599 13.2323 32.7262 14.2533 32.4717L43.8286 24.549C44.9134 24.2945 45.711 23.6899 46.2215 22.7354C46.7319 21.9081 46.8596 20.9536 46.6043 19.8718L45.0729 14.3355C44.8177 13.3173 44.2434 12.5219 43.3501 11.9491C42.4568 11.5037 41.4677 11.4082 40.383 11.6628L10.8076 19.5854C9.78665 19.84 9.02094 20.4127 8.51047 21.3036C8 22.1945 7.87238 23.149 8.12762 24.1672Z"
                fill="#3D465D"
            />
            <path
                d="M69.862 13.381C70.3087 14.2083 71.0744 14.7492 72.1591 15.0037L101.734 22.9264C102.755 23.2446 103.744 23.181 104.702 22.7355C105.531 22.1628 106.073 21.3673 106.329 20.3492L107.86 14.8128C108.115 13.731 108.02 12.7765 107.573 11.9492C106.999 10.9947 106.201 10.3583 105.18 10.0401L75.6048 2.11748C74.52 1.86294 73.5629 2.02203 72.7334 2.59475C71.7763 3.0402 71.1701 3.80383 70.9148 4.88564L69.3834 10.422C69.1282 11.4401 69.2877 12.4265 69.862 13.381Z"
                fill="#3D465D"
            />
            <path
                d="M84.5726 55.7409C84.6364 54.6591 84.3173 53.7364 83.6154 52.9727C82.9135 52.1455 82.0521 51.7 81.0312 51.6364C79.9464 51.5727 79.0212 51.8909 78.2555 52.5909C77.745 53.0364 77.2026 53.4818 76.6284 53.9273H76.5326C73.4698 56.2182 69.7689 57.3636 65.4299 57.3636C60.9633 57.3636 57.1667 56.2182 54.04 53.9273C53.6572 53.6091 53.3062 53.2909 52.9872 52.9727C52.2853 52.1455 51.4239 51.7 50.4029 51.6364C49.3182 51.5727 48.393 51.8909 47.6273 52.5909C46.7977 53.2909 46.3511 54.1818 46.2873 55.2636C46.2235 56.2818 46.5425 57.2045 47.2444 58.0318C47.8187 58.6681 48.5206 59.3045 49.3501 59.9409C49.4139 59.9409 49.4458 59.9727 49.4458 60.0363C53.8486 63.3454 59.1767 64.9999 65.4299 64.9999C71.5556 64.9999 76.7879 63.3772 81.1269 60.1318V60.0363C81.8926 59.4636 82.6264 58.8909 83.3283 58.3181C84.094 57.6181 84.5088 56.7591 84.5726 55.7409Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-18': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M41.776 25.1076C38.0751 21.3083 33.5765 19.4087 28.2804 19.4087C22.9843 19.4087 18.4857 21.3083 14.7848 25.1076C11.0201 28.8425 9.1377 33.3823 9.1377 38.7271H47.4231C47.4231 33.3823 45.5407 28.8425 41.776 25.1076Z"
                fill="white"
            />
            <path
                d="M36.4152 30.5165C34.1819 28.2626 31.47 27.1357 28.2796 27.1357C25.1529 27.1357 22.4729 28.2626 20.2396 30.5165C17.9425 32.7703 16.7939 35.507 16.7939 38.7268H39.7652C39.7652 35.507 38.6485 32.7703 36.4152 30.5165Z"
                fill="#3D465D"
            />
            <path
                d="M99.2037 25.1076C95.5028 21.3083 91.0043 19.4087 85.7081 19.4087C80.412 19.4087 75.9134 21.3083 72.2125 25.1076C68.4478 28.8425 66.5654 33.3823 66.5654 38.7271H104.851C104.851 33.3823 102.968 28.8425 99.2037 25.1076Z"
                fill="white"
            />
            <path
                d="M93.8449 30.5169C91.6116 28.2631 88.8997 27.1362 85.7093 27.1362C82.5826 27.1362 79.9026 28.2631 77.6693 30.5169C75.3722 32.7708 74.2236 35.5075 74.2236 38.7273H97.1949C97.1949 35.5075 96.0782 32.7708 93.8449 30.5169Z"
                fill="#3D465D"
            />
            <path
                d="M7.12762 24.4317L8.65903 30.0341C8.91427 31.1288 9.48855 31.9337 10.3819 32.4489C11.2752 32.964 12.2323 33.0928 13.2533 32.8352L42.8288 24.8181C43.9135 24.5605 44.7111 23.9488 45.2216 22.9829C45.7321 22.1457 45.8597 21.1798 45.6044 20.0851L44.073 14.4828C43.8178 13.4525 43.2435 12.6475 42.3502 12.068C41.4569 11.6172 40.4678 11.5206 39.3831 11.7782L9.8076 19.7953C8.78665 20.0529 8.02094 20.6325 7.51047 21.534C7 22.4355 6.87238 23.4014 7.12762 24.4317Z"
                fill="#3D465D"
            />
            <path
                d="M68.863 13.5167C69.3096 14.3539 70.0753 14.9012 71.1601 15.1588L100.736 23.1759C101.757 23.4979 102.746 23.4335 103.703 22.9827C104.532 22.4032 105.075 21.5982 105.33 20.5679L106.861 14.9656C107.116 13.8709 107.021 12.905 106.574 12.0678C106 11.1019 105.202 10.458 104.181 10.136L74.6058 2.11888C73.521 1.8613 72.5639 2.02229 71.7344 2.60184C70.7772 3.0526 70.1711 3.82534 69.9158 4.92005L68.3844 10.5224C68.1292 11.5527 68.2887 12.5508 68.863 13.5167Z"
                fill="#3D465D"
            />
            <path
                d="M76.0408 52.0564C75.1475 51.4769 74.1584 51.3481 73.0737 51.67C72.0527 51.9276 71.2551 52.475 70.6808 53.3121C70.1703 54.0204 69.4365 54.6966 68.4794 55.3405C66.1185 56.8216 63.3428 57.5621 60.1523 57.5621C58.6847 57.5621 57.3128 57.4334 56.0367 57.1758C54.9519 56.9826 53.9629 57.1758 53.0695 57.7553C52.1762 58.2061 51.6019 58.9788 51.3467 60.0735C51.0915 61.0395 51.2829 61.9732 51.921 62.8747C52.4314 63.6474 53.261 64.1626 54.4095 64.4202C56.1324 64.8065 58.0466 64.9997 60.1523 64.9997C65.0656 64.9997 69.3089 63.8406 72.8822 61.5224L72.7865 61.619C74.956 60.2667 76.5513 58.7534 77.5722 57.0792C78.0827 56.1777 78.2103 55.2439 77.955 54.278C77.636 53.2477 76.9979 52.5072 76.0408 52.0564Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-19': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1239 20.3472C30.9969 20.3472 27.4915 21.861 24.6075 24.8888C21.6739 27.9165 20.207 31.5344 20.207 35.7425C20.207 40.0019 21.6739 43.6198 24.6075 46.5962C27.4915 49.6239 30.9969 51.1378 35.1239 51.1378C39.251 51.1378 42.7564 49.6239 45.6404 46.5962C48.574 43.6198 50.0408 40.0019 50.0408 35.7425C50.0408 31.5344 48.574 27.9165 45.6404 24.8888C42.7564 21.861 39.251 20.3472 35.1239 20.3472Z"
                fill="white"
            />
            <path
                d="M35.124 26.5054C32.6875 26.5054 30.5992 27.4291 28.8589 29.2765C27.0688 31.0727 26.1738 33.228 26.1738 35.7426C26.1738 38.3085 27.0688 40.4895 28.8589 42.2856C30.5992 44.0817 32.6875 44.9798 35.124 44.9798C37.6101 44.9798 39.7234 44.0817 41.4637 42.2856C43.204 40.4895 44.0741 38.3085 44.0741 35.7426C44.0741 33.228 43.204 31.0727 41.4637 29.2765C39.7234 27.4291 37.6101 26.5054 35.124 26.5054Z"
                fill="#3D465D"
            />
            <path
                d="M79.8749 20.3472C75.7479 20.3472 72.2424 21.861 69.3585 24.8888C66.4248 27.9165 64.958 31.5344 64.958 35.7425C64.958 40.0019 66.4248 43.6198 69.3585 46.5962C72.2424 49.6239 75.7479 51.1378 79.8749 51.1378C84.0019 51.1378 87.5074 49.6239 90.3913 46.5962C93.325 43.6198 94.7918 40.0019 94.7918 35.7425C94.7918 31.5344 93.325 27.9165 90.3913 24.8888C87.5074 21.861 84.0019 20.3472 79.8749 20.3472Z"
                fill="white"
            />
            <path
                d="M79.8749 26.5054C77.4385 26.5054 75.3502 27.4291 73.6098 29.2765C71.8198 31.0727 70.9248 33.228 70.9248 35.7426C70.9248 38.3085 71.8198 40.4895 73.6098 42.2856C75.3502 44.0817 77.4385 44.9798 79.8749 44.9798C82.3611 44.9798 84.4743 44.0817 86.2146 42.2856C87.9549 40.4895 88.8251 38.3085 88.8251 35.7426C88.8251 33.228 87.9549 31.0727 86.2146 29.2765C84.4743 27.4291 82.3611 26.5054 79.8749 26.5054Z"
                fill="#3D465D"
            />
            <path
                d="M16.2547 9.10821C15.4095 9.31348 14.8128 9.77534 14.4647 10.4938C14.0172 11.2122 13.8929 12.0077 14.0918 12.8801L15.6581 18.8073C15.857 19.6797 16.3293 20.2955 17.0752 20.6547C17.7216 21.1166 18.4674 21.2449 19.3127 21.0396L48.1023 13.034C48.8979 12.8287 49.5195 12.3412 49.967 11.5715C50.3647 10.9043 50.4642 10.1346 50.2653 9.26216L48.699 3.33496C48.5001 2.46256 48.0526 1.82108 47.3565 1.41054C46.6107 1 45.8399 0.897364 45.0444 1.10264L16.2547 9.10821Z"
                fill="#3D465D"
            />
            <path
                d="M69.9561 1.10264C69.1605 0.897364 68.3898 1 67.644 1.41054C66.9479 1.82108 66.5004 2.46256 66.3015 3.33496L64.7352 9.26216C64.5363 10.1346 64.6357 10.9043 65.0335 11.5715C65.481 12.3412 66.1026 12.8287 66.8981 13.034L95.6878 21.0396C96.5331 21.2449 97.2789 21.1166 97.9253 20.6547C98.6712 20.2955 99.1435 19.6797 99.3424 18.8073L100.909 12.8801C101.108 12.0077 100.983 11.2122 100.536 10.4938C100.188 9.77534 99.591 9.31348 98.7457 9.10821L69.9561 1.10264Z"
                fill="#3D465D"
            />
            <path
                d="M71.4476 59.6052C70.9006 59.1433 70.3288 58.6814 69.7321 58.2196C66.351 55.5511 62.2737 54.2168 57.5003 54.2168C52.6274 54.2168 48.4755 55.5511 45.0447 58.2196C45.0447 58.2709 45.0198 58.2966 44.9701 58.2966C44.3237 58.8097 43.7767 59.3486 43.3292 59.9131C42.7823 60.5289 42.5336 61.273 42.5834 62.1454C42.6331 62.9665 42.9811 63.6593 43.6275 64.2238C44.2242 64.7883 44.9452 65.0448 45.7905 64.9935C46.5861 64.9422 47.2573 64.6086 47.8043 63.9928C48.0529 63.6849 48.3264 63.4027 48.6247 63.1461C51.0611 61.2986 54.0197 60.3749 57.5003 60.3749C60.8814 60.3749 63.7654 61.2986 66.1521 63.1461L66.2267 63.2231C66.6742 63.531 67.0968 63.8645 67.4946 64.2238C68.0913 64.7883 68.8123 65.0448 69.6576 64.9935C70.4531 64.9422 71.1244 64.6086 71.6713 63.9928C72.2183 63.3257 72.4669 62.5816 72.4172 61.7605C72.3675 60.8881 72.0443 60.1697 71.4476 59.6052Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-20': (
        <svg
            width="115"
            height="68"
            viewBox="0 0 115 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1248 19.7041C30.9978 19.7041 27.4924 21.1677 24.6085 24.0948C21.6748 27.0219 20.208 30.5196 20.208 34.5878C20.208 38.7057 21.6748 42.2033 24.6085 45.0808C27.4924 48.008 30.9978 49.4715 35.1248 49.4715C39.2518 49.4715 42.7572 48.008 45.6411 45.0808C48.5748 42.2033 50.0416 38.7057 50.0416 34.5878C50.0416 30.5196 48.5748 27.0219 45.6411 24.0948C42.7572 21.1677 39.2518 19.7041 35.1248 19.7041Z"
                fill="white"
            />
            <path
                d="M35.1219 25.6577C32.6855 25.6577 30.5972 26.5507 28.8569 28.3368C27.0669 30.0732 26.1719 32.1569 26.1719 34.5879C26.1719 37.0686 27.0669 39.1771 28.8569 40.9135C30.5972 42.65 32.6855 43.5182 35.1219 43.5182C37.6081 43.5182 39.7213 42.65 41.4616 40.9135C43.2019 39.1771 44.072 37.0686 44.072 34.5879C44.072 32.1569 43.2019 30.0732 41.4616 28.3368C39.7213 26.5507 37.6081 25.6577 35.1219 25.6577Z"
                fill="#3D465D"
            />
            <path
                d="M79.8738 19.7041C75.7468 19.7041 72.2414 21.1677 69.3575 24.0948C66.4238 27.0219 64.957 30.5196 64.957 34.5878C64.957 38.7057 66.4238 42.2033 69.3575 45.0808C72.2414 48.008 75.7468 49.4715 79.8738 49.4715C84.0008 49.4715 87.5062 48.008 90.3902 45.0808C93.3238 42.2033 94.7906 38.7057 94.7906 34.5878C94.7906 30.5196 93.3238 27.0219 90.3902 24.0948C87.5062 21.1677 84.0008 19.7041 79.8738 19.7041Z"
                fill="white"
            />
            <path
                d="M79.8749 25.6572C77.4385 25.6572 75.3501 26.5502 73.6098 28.3363C71.8198 30.0727 70.9248 32.1565 70.9248 34.5875C70.9248 37.0681 71.8198 39.1766 73.6098 40.913C75.3501 42.6495 77.4385 43.5177 79.8749 43.5177C82.361 43.5177 84.4742 42.6495 86.2145 40.913C87.9548 39.1766 88.8249 37.0681 88.8249 34.5875C88.8249 32.1565 87.9548 30.0727 86.2145 28.3363C84.4742 26.5502 82.361 25.6572 79.8749 25.6572Z"
                fill="#3D465D"
            />
            <path
                d="M16.2547 8.83876C15.4094 9.03721 14.8128 9.48372 14.4647 10.1783C14.0172 10.8729 13.8929 11.6419 14.0918 12.4853L15.6581 18.2155C15.857 19.0589 16.3293 19.6543 17.0752 20.0016C17.7215 20.4481 18.4674 20.5721 19.3127 20.3736L48.1021 12.6341C48.8976 12.4357 49.5192 11.9643 49.9667 11.2202C50.3644 10.5752 50.4639 9.83101 50.265 8.9876L48.6987 3.25737C48.4999 2.41395 48.0523 1.7938 47.3562 1.3969C46.6104 1 45.8397 0.900775 45.0441 1.09922L16.2547 8.83876Z"
                fill="#3D465D"
            />
            <path
                d="M69.9561 1.09922C69.1605 0.900775 68.3898 1 67.644 1.3969C66.9478 1.7938 66.5003 2.41395 66.3015 3.25737L64.7352 8.9876C64.5363 9.83101 64.6357 10.5752 65.0335 11.2202C65.481 11.9643 66.1026 12.4357 66.8981 12.6341L95.6875 20.3736C96.5328 20.5721 97.2786 20.4481 97.925 20.0016C98.6709 19.6543 99.1432 19.0589 99.3421 18.2155L100.908 12.4853C101.107 11.6419 100.983 10.8729 100.535 10.1783C100.187 9.48372 99.5907 9.03721 98.7455 8.83876L69.9561 1.09922Z"
                fill="#3D465D"
            />
            <path
                d="M46.8174 65H66.2092"
                stroke="#3D465D"
                strokeWidth="5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-21': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1239 20.1494C30.9969 20.1494 27.4915 21.6478 24.6075 24.6446C21.6739 27.6415 20.207 31.2224 20.207 35.3875C20.207 39.6034 21.6739 43.1843 24.6075 46.1304C27.4915 49.1272 30.9969 50.6256 35.1239 50.6256C39.2509 50.6256 42.7564 49.1272 45.6404 46.1304C48.574 43.1843 50.0408 39.6034 50.0408 35.3875C50.0408 31.2224 48.574 27.6415 45.6404 24.6446C42.7564 21.6478 39.2509 20.1494 35.1239 20.1494Z"
                fill="white"
            />
            <path
                d="M35.124 26.2446C32.6875 26.2446 30.5992 27.1589 28.8589 28.9875C27.0688 30.7653 26.1738 32.8986 26.1738 35.3875C26.1738 37.9272 27.0688 40.0859 28.8589 41.8637C30.5992 43.6414 32.6875 44.5303 35.124 44.5303C37.6101 44.5303 39.7234 43.6414 41.4637 41.8637C43.204 40.0859 44.0741 37.9272 44.0741 35.3875C44.0741 32.8986 43.204 30.7653 41.4637 28.9875C39.7234 27.1589 37.6101 26.2446 35.124 26.2446Z"
                fill="#3D465D"
            />
            <path
                d="M79.8749 20.1494C75.7479 20.1494 72.2424 21.6478 69.3585 24.6446C66.4248 27.6415 64.958 31.2224 64.958 35.3875C64.958 39.6034 66.4248 43.1843 69.3585 46.1304C72.2424 49.1272 75.7479 50.6256 79.8749 50.6256C84.0019 50.6256 87.5074 49.1272 90.3913 46.1304C93.325 43.1843 94.7918 39.6034 94.7918 35.3875C94.7918 31.2224 93.325 27.6415 90.3913 24.6446C87.5074 21.6478 84.0019 20.1494 79.8749 20.1494Z"
                fill="white"
            />
            <path
                d="M79.8749 26.2446C77.4385 26.2446 75.3502 27.1589 73.6098 28.9875C71.8198 30.7653 70.9248 32.8986 70.9248 35.3875C70.9248 37.9272 71.8198 40.0859 73.6098 41.8637C75.3502 43.6414 77.4385 44.5303 79.8749 44.5303C82.3611 44.5303 84.4743 43.6414 86.2146 41.8637C87.9549 40.0859 88.8251 37.9272 88.8251 35.3875C88.8251 32.8986 87.9549 30.7653 86.2146 28.9875C84.4743 27.1589 82.3611 26.2446 79.8749 26.2446Z"
                fill="#3D465D"
            />
            <path
                d="M16.2547 9.02539C15.4095 9.22857 14.8128 9.68571 14.4647 10.3968C14.0172 11.1079 13.8929 11.8952 14.0918 12.7587L15.6581 18.6254C15.857 19.4889 16.3293 20.0984 17.0752 20.454C17.7216 20.9111 18.4674 21.0381 19.3127 20.8349L48.1023 12.9111C48.8979 12.7079 49.5194 12.2254 49.967 11.4635C50.3647 10.8032 50.4642 10.0413 50.2653 9.17777L48.699 3.31111C48.5001 2.44762 48.0526 1.8127 47.3565 1.40635C46.6107 1 45.8399 0.898412 45.0444 1.10159L16.2547 9.02539Z"
                fill="#3D465D"
            />
            <path
                d="M69.9561 1.10159C69.1605 0.898412 68.3898 1 67.644 1.40635C66.9479 1.8127 66.5004 2.44762 66.3015 3.31111L64.7352 9.17777C64.5363 10.0413 64.6357 10.8032 65.0335 11.4635C65.481 12.2254 66.1026 12.7079 66.8981 12.9111L95.6878 20.8349C96.5331 21.0381 97.2789 20.9111 97.9253 20.454C98.6712 20.0984 99.1435 19.4889 99.3424 18.6254L100.909 12.7587C101.108 11.8952 100.983 11.1079 100.536 10.3968C100.188 9.68571 99.591 9.22857 98.7457 9.02539L69.9561 1.10159Z"
                fill="#3D465D"
            />
            <path
                d="M67.329 58.7523C67.7268 58.0412 67.8262 57.3047 67.6274 56.5428C67.3787 55.7301 66.8815 55.146 66.1357 54.7904C65.4395 54.3333 64.6688 54.2317 63.8235 54.4857C63.028 54.6888 62.4064 55.1206 61.9589 55.7809C61.5611 56.3396 60.9893 56.873 60.2435 57.3809C58.4037 58.5492 56.2408 59.1333 53.7546 59.1333C52.611 59.1333 51.542 59.0317 50.5475 58.8285C49.7022 58.6761 48.9315 58.8285 48.2354 59.2857C47.5393 59.6412 47.0917 60.2507 46.8929 61.1142C46.694 61.8761 46.8431 62.6126 47.3404 63.3238C47.7381 63.9333 48.3845 64.3396 49.2796 64.5428C50.6221 64.8476 52.1138 64.9999 53.7546 64.9999C57.5833 64.9999 60.8899 64.0857 63.6744 62.2571L63.5998 62.3333C65.2904 61.2666 66.5335 60.073 67.329 58.7523Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-22': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1239 17.7559C30.9969 17.7559 27.4915 19.067 24.6075 21.6892C21.6739 24.3114 20.207 27.4447 20.207 31.0892C20.207 34.7781 21.6739 37.9114 24.6075 40.4892C27.4915 43.1114 30.9969 44.4225 35.1239 44.4225C39.2509 44.4225 42.7564 43.1114 45.6404 40.4892C48.574 37.9114 50.0408 34.7781 50.0408 31.0892C50.0408 27.4447 48.574 24.3114 45.6404 21.6892C42.7564 19.067 39.2509 17.7559 35.1239 17.7559Z"
                fill="white"
            />
            <path
                d="M35.124 23.0894C32.6875 23.0894 30.5992 23.8894 28.8589 25.4894C27.0688 27.0449 26.1738 28.9116 26.1738 31.0894C26.1738 33.3116 27.0688 35.2005 28.8589 36.756C30.5992 38.3116 32.6875 39.0894 35.124 39.0894C37.6101 39.0894 39.7234 38.3116 41.4637 36.756C43.204 35.2005 44.0741 33.3116 44.0741 31.0894C44.0741 28.9116 43.204 27.0449 41.4637 25.4894C39.7234 23.8894 37.6101 23.0894 35.124 23.0894Z"
                fill="#3D465D"
            />
            <path
                d="M79.8749 17.7559C75.7479 17.7559 72.2424 19.067 69.3585 21.6892C66.4248 24.3114 64.958 27.4447 64.958 31.0892C64.958 34.7781 66.4248 37.9114 69.3585 40.4892C72.2424 43.1114 75.7479 44.4225 79.8749 44.4225C84.0019 44.4225 87.5074 43.1114 90.3913 40.4892C93.325 37.9114 94.7918 34.7781 94.7918 31.0892C94.7918 27.4447 93.325 24.3114 90.3913 21.6892C87.5074 19.067 84.0019 17.7559 79.8749 17.7559Z"
                fill="white"
            />
            <path
                d="M79.8749 23.0894C77.4385 23.0894 75.3502 23.8894 73.6098 25.4894C71.8198 27.0449 70.9248 28.9116 70.9248 31.0894C70.9248 33.3116 71.8198 35.2005 73.6098 36.756C75.3502 38.3116 77.4385 39.0894 79.8749 39.0894C82.3611 39.0894 84.4743 38.3116 86.2146 36.756C87.9549 35.2005 88.8251 33.3116 88.8251 31.0894C88.8251 28.9116 87.9549 27.0449 86.2146 25.4894C84.4743 23.8894 82.3611 23.0894 79.8749 23.0894Z"
                fill="#3D465D"
            />
            <path
                d="M16.2547 8.02222C15.4095 8.2 14.8128 8.6 14.4647 9.22222C14.0172 9.84445 13.8929 10.5333 14.0918 11.2889L15.6581 16.4222C15.857 17.1778 16.3293 17.7111 17.0752 18.0222C17.7216 18.4222 18.4674 18.5333 19.3127 18.3556L48.1023 11.4222C48.8979 11.2444 49.5194 10.8222 49.967 10.1556C50.3647 9.57778 50.4642 8.91111 50.2653 8.15556L48.699 3.02222C48.5001 2.26667 48.0526 1.71111 47.3565 1.35556C46.6107 1 45.8399 0.911111 45.0444 1.08889L16.2547 8.02222Z"
                fill="#3D465D"
            />
            <path
                d="M69.9561 1.08889C69.1605 0.911111 68.3898 1 67.644 1.35556C66.9479 1.71111 66.5004 2.26667 66.3015 3.02222L64.7352 8.15556C64.5363 8.91111 64.6357 9.57778 65.0335 10.1556C65.481 10.8222 66.1026 11.2444 66.8981 11.4222L95.6878 18.3556C96.5331 18.5333 97.2789 18.4222 97.9253 18.0222C98.6712 17.7111 99.1435 17.1778 99.3424 16.4222L100.909 11.2889C101.108 10.5333 100.983 9.84445 100.536 9.22222C100.188 8.6 99.591 8.2 98.7457 8.02222L69.9561 1.08889Z"
                fill="#3D465D"
            />
            <path
                d="M85.3044 53.5998C84.1607 52.3109 82.8182 51.6665 81.2768 51.6665C64.8682 55.3554 48.8077 55.3554 33.0952 51.6665C31.5538 51.6665 30.2112 52.3109 29.0676 53.5998C27.9737 54.8887 27.4268 56.4221 27.4268 58.1998V58.5332C27.4268 60.2665 27.9737 61.7998 29.0676 63.1332C30.2112 64.3776 31.5538 64.9998 33.0952 64.9998C48.8077 61.3554 64.8682 61.3554 81.2768 64.9998C82.8182 64.9998 84.1607 64.3776 85.3044 63.1332C86.4977 61.7998 87.0944 60.2665 87.0944 58.5332V58.1998C87.0944 56.4221 86.4977 54.8887 85.3044 53.5998Z"
                fill="white"
            />
        </svg>
    ),
    'face-23': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.1239 20.3472C30.9969 20.3472 27.4915 21.8611 24.6075 24.8888C21.6739 27.9166 20.207 31.5345 20.207 35.7426C20.207 40.002 21.6739 43.6199 24.6075 46.5964C27.4915 49.6241 30.9969 51.138 35.1239 51.138C39.251 51.138 42.7564 49.6241 45.6404 46.5964C48.574 43.6199 50.0408 40.002 50.0408 35.7426C50.0408 31.5345 48.574 27.9166 45.6404 24.8888C42.7564 21.8611 39.251 20.3472 35.1239 20.3472Z"
                fill="white"
            />
            <path
                d="M35.124 26.5054C32.6875 26.5054 30.5992 27.4291 28.8589 29.2765C27.0688 31.0727 26.1738 33.228 26.1738 35.7426C26.1738 38.3085 27.0688 40.4895 28.8589 42.2857C30.5992 44.0818 32.6875 44.9799 35.124 44.9799C37.6101 44.9799 39.7234 44.0818 41.4637 42.2857C43.204 40.4895 44.0741 38.3085 44.0741 35.7426C44.0741 33.228 43.204 31.0727 41.4637 29.2765C39.7234 27.4291 37.6101 26.5054 35.124 26.5054Z"
                fill="#3D465D"
            />
            <path
                d="M79.8749 20.3472C75.7479 20.3472 72.2424 21.8611 69.3585 24.8888C66.4248 27.9166 64.958 31.5345 64.958 35.7426C64.958 40.002 66.4248 43.6199 69.3585 46.5964C72.2424 49.6241 75.7479 51.138 79.8749 51.138C84.0019 51.138 87.5074 49.6241 90.3913 46.5964C93.325 43.6199 94.7918 40.002 94.7918 35.7426C94.7918 31.5345 93.325 27.9166 90.3913 24.8888C87.5074 21.8611 84.0019 20.3472 79.8749 20.3472Z"
                fill="white"
            />
            <path
                d="M79.8749 26.5054C77.4385 26.5054 75.3502 27.4291 73.6098 29.2765C71.8198 31.0727 70.9248 33.228 70.9248 35.7426C70.9248 38.3085 71.8198 40.4895 73.6098 42.2857C75.3502 44.0818 77.4385 44.9799 79.8749 44.9799C82.3611 44.9799 84.4743 44.0818 86.2146 42.2857C87.9549 40.4895 88.8251 38.3085 88.8251 35.7426C88.8251 33.228 87.9549 31.0727 86.2146 29.2765C84.4743 27.4291 82.3611 26.5054 79.8749 26.5054Z"
                fill="#3D465D"
            />
            <path
                d="M16.2547 9.10826C15.4095 9.31353 14.8128 9.77539 14.4647 10.4938C14.0172 11.2123 13.8929 12.0077 14.0918 12.8801L15.6581 18.8074C15.857 19.6798 16.3293 20.2956 17.0752 20.6548C17.7216 21.1167 18.4674 21.245 19.3127 21.0397L48.1023 13.0341C48.8979 12.8288 49.5195 12.3413 49.967 11.5715C50.3647 10.9044 50.4642 10.1346 50.2653 9.26221L48.699 3.33497C48.5001 2.46257 48.0526 1.82109 47.3565 1.41055C46.6107 1 45.8399 0.897364 45.0444 1.10264L16.2547 9.10826Z"
                fill="#3D465D"
            />
            <path
                d="M69.9561 1.10264C69.1605 0.897364 68.3898 1 67.644 1.41055C66.9479 1.82109 66.5004 2.46257 66.3015 3.33497L64.7352 9.26221C64.5363 10.1346 64.6357 10.9044 65.0335 11.5715C65.481 12.3413 66.1026 12.8288 66.8981 13.0341L95.6878 21.0397C96.5331 21.245 97.2789 21.1167 97.9253 20.6548C98.6712 20.2956 99.1435 19.6798 99.3424 18.8074L100.909 12.8801C101.108 12.0077 100.983 11.2123 100.536 10.4938C100.188 9.77539 99.591 9.31353 98.7457 9.10826L69.9561 1.10264Z"
                fill="#3D465D"
            />
            <path
                d="M43.5534 59.6116C44.1003 60.0735 44.6722 60.5353 45.2688 60.9972C48.65 63.6657 52.7273 65 57.5007 65C62.3736 65 66.5254 63.6657 69.9563 60.9972C69.9563 60.9459 69.9812 60.9202 70.0309 60.9202C70.6773 60.407 71.2243 59.8682 71.6718 59.3037C72.2187 58.6879 72.4673 57.9438 72.4176 57.0714C72.3679 56.2503 72.0198 55.5575 71.3734 54.993C70.7768 54.4285 70.0558 54.1719 69.2105 54.2232C68.4149 54.2745 67.7436 54.6081 67.1967 55.2239C66.9481 55.5318 66.6746 55.8141 66.3763 56.0707C63.9398 57.9181 60.9813 58.8418 57.5007 58.8418C54.1195 58.8418 51.2356 57.9181 48.8489 56.0707L48.7743 55.9937C48.3268 55.6858 47.9042 55.3522 47.5064 54.993C46.9097 54.4285 46.1887 54.1719 45.3434 54.2232C44.5479 54.2745 43.8766 54.6081 43.3296 55.2239C42.7827 55.891 42.5341 56.6352 42.5838 57.4562C42.6335 58.3286 42.9567 59.0471 43.5534 59.6116Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-24': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9053 27.5232C20.8133 26.6958 21.7781 25.9976 22.7996 25.4287C17.9189 28.0145 13.8328 29.2299 10.5412 29.0747C7.87384 29.0747 7.30632 29.8505 8.83863 31.402L15.4786 37.3753C15.4786 33.4965 16.9542 30.2125 19.9053 27.5232ZM93.2004 25.4287C94.2219 25.9976 95.1867 26.6958 96.0947 27.5232C99.0459 30.2125 100.521 33.4965 100.521 37.3753L107.161 31.402C108.694 29.8505 108.126 29.0747 105.459 29.0747C102.167 29.2299 98.0811 28.0145 93.2004 25.4287Z"
                fill="#3D465D"
            />
            <path
                d="M44.5071 26.4373C41.2155 23.386 37.2145 21.8604 32.5041 21.8604C27.7937 21.8604 23.7927 23.386 20.5011 26.4373C17.1527 29.4368 15.4785 33.0829 15.4785 37.3754H49.5297C49.5297 33.0829 47.8555 29.4368 44.5071 26.4373Z"
                fill="white"
            />
            <path
                d="M39.7403 30.7815C37.754 28.9714 35.342 28.0664 32.5044 28.0664C29.7236 28.0664 27.34 28.9714 25.3537 30.7815C23.3106 32.5916 22.2891 34.7896 22.2891 37.3754H42.7198C42.7198 34.7896 41.7266 32.5916 39.7403 30.7815Z"
                fill="#3D465D"
            />
            <path
                d="M95.5833 26.4373C92.2917 23.386 88.2907 21.8604 83.5803 21.8604C78.8699 21.8604 74.8688 23.386 71.5772 26.4373C68.2289 29.4368 66.5547 33.0829 66.5547 37.3754H100.606C100.606 33.0829 98.9317 29.4368 95.5833 26.4373Z"
                fill="white"
            />
            <path
                d="M90.8174 30.7815C88.8311 28.9714 86.4192 28.0664 83.5816 28.0664C80.8007 28.0664 78.4171 28.9714 76.4308 30.7815C74.3877 32.5916 73.3662 34.7896 73.3662 37.3754H93.7969C93.7969 34.7896 92.8038 32.5916 90.8174 30.7815Z"
                fill="#3D465D"
            />
            <path
                d="M17.1807 17.206C17.1807 17.6197 17.3509 17.9818 17.6914 18.2921C18.0319 18.6024 18.4292 18.7575 18.8832 18.7575H41.0165C41.4705 18.7575 41.8678 18.6024 42.2083 18.2921C42.5488 17.9818 42.719 17.6197 42.719 17.206V12.5515C42.719 12.1378 42.5488 11.7758 42.2083 11.4655C41.8678 11.1552 41.4705 11 41.0165 11H34.4616C34.2914 11 34.1495 11.0259 34.036 11.0776L18.4576 14.646C18.0603 14.6977 17.7482 14.8788 17.5212 15.1891C17.2942 15.4476 17.1807 15.7579 17.1807 16.12V17.206Z"
                fill="#3D465D"
            />
            <path
                d="M73.877 18.2921C74.2175 18.6024 74.6148 18.7575 75.0688 18.7575H97.202C97.656 18.7575 98.0533 18.6024 98.3938 18.2921C98.7343 17.9818 98.9046 17.6197 98.9046 17.206V16.12C98.9046 15.7579 98.7911 15.4476 98.5641 15.1891C98.3371 14.8788 98.0249 14.6977 97.6277 14.646L82.0493 11.0776C81.9358 11.0259 81.7939 11 81.6236 11H75.0688C74.6148 11 74.2175 11.1552 73.877 11.4655C73.5365 11.7758 73.3662 12.1378 73.3662 12.5515V17.206C73.3662 17.6197 73.5365 17.9818 73.877 18.2921Z"
                fill="#3D465D"
            />
            <path
                d="M71.3355 49.0891C67.4763 46.4516 62.8227 45.1328 57.3745 45.1328C51.8128 45.1328 47.074 46.4774 43.1581 49.1667C43.1581 49.2184 43.1297 49.2443 43.073 49.2443C42.3352 49.7615 41.7109 50.2786 41.2002 50.7958C40.5759 51.4681 40.2921 52.218 40.3489 53.0455C40.4056 53.9247 40.8029 54.6487 41.5407 55.2176C42.2217 55.7865 43.0446 56.045 44.0094 55.9933C44.9174 55.9416 45.6836 55.5796 46.3078 54.9073C46.5916 54.6487 46.9037 54.3901 47.2443 54.1315C50.0251 52.2697 53.4018 51.3388 57.3745 51.3388C61.2336 51.3388 64.5252 52.2697 67.2493 54.1315H67.3344C67.8452 54.4935 68.3276 54.8556 68.7816 55.2176C69.4626 55.7865 70.2855 56.045 71.2503 55.9933C72.1584 55.9416 72.9245 55.5796 73.5488 54.9073C74.1731 54.2867 74.4568 53.5368 74.4001 52.6576C74.3433 51.8301 73.9744 51.132 73.2934 50.5631C72.6691 50.0976 72.0165 49.6322 71.3355 49.1667V49.0891Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-25': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9053 29.4327C20.8133 28.5097 21.7781 27.7308 22.7996 27.0962C17.9189 29.9808 13.8328 31.3366 10.5412 31.1635C7.87384 31.1635 7.30632 32.0289 8.83863 33.7597L15.4786 40.4231C15.4786 36.0962 16.9542 32.4327 19.9053 29.4327ZM93.2004 27.0962C94.2219 27.7308 95.1867 28.5097 96.0947 29.4327C99.0459 32.4327 100.521 36.0962 100.521 40.4231L107.161 33.7597C108.694 32.0289 108.126 31.1635 105.459 31.1635C102.167 31.3366 98.0811 29.9808 93.2004 27.0962Z"
                fill="#3D465D"
            />
            <path
                d="M44.5071 28.221C41.2155 24.8172 37.2145 23.1152 32.5041 23.1152C27.7937 23.1152 23.7927 24.8172 20.5011 28.221C17.1527 31.5672 15.4785 35.6345 15.4785 40.4229H49.5297C49.5297 35.6345 47.8555 31.5672 44.5071 28.221Z"
                fill="white"
            />
            <path
                d="M39.7393 33.0674C37.753 31.0482 35.341 30.0386 32.5034 30.0386C29.7226 30.0386 27.339 31.0482 25.3527 33.0674C23.3096 35.0867 22.2881 37.5386 22.2881 40.4232H42.7188C42.7188 37.5386 41.7256 35.0867 39.7393 33.0674Z"
                fill="#3D465D"
            />
            <path
                d="M95.5833 28.221C92.2917 24.8172 88.2907 23.1152 83.5803 23.1152C78.8699 23.1152 74.8688 24.8172 71.5772 28.221C68.2289 31.5672 66.5547 35.6345 66.5547 40.4229H100.606C100.606 35.6345 98.9317 31.5672 95.5833 28.221Z"
                fill="white"
            />
            <path
                d="M90.8174 33.0674C88.8311 31.0482 86.4192 30.0386 83.5816 30.0386C80.8007 30.0386 78.4171 31.0482 76.4308 33.0674C74.3877 35.0867 73.3662 37.5386 73.3662 40.4232H93.7969C93.7969 37.5386 92.8038 35.0867 90.8174 33.0674Z"
                fill="#3D465D"
            />
            <path
                d="M17.1807 17.9231C17.1807 18.3846 17.3509 18.7885 17.6914 19.1346C18.0319 19.4808 18.4292 19.6538 18.8832 19.6538H41.0165C41.4705 19.6538 41.8678 19.4808 42.2083 19.1346C42.5488 18.7885 42.719 18.3846 42.719 17.9231V12.7308C42.719 12.2692 42.5488 11.8654 42.2083 11.5192C41.8678 11.1731 41.4705 11 41.0165 11H34.4616C34.2914 11 34.1495 11.0288 34.036 11.0865L18.4576 15.0673C18.0603 15.125 17.7482 15.3269 17.5212 15.6731C17.2942 15.9615 17.1807 16.3077 17.1807 16.7115V17.9231Z"
                fill="#3D465D"
            />
            <path
                d="M73.877 19.1346C74.2175 19.4808 74.6148 19.6538 75.0688 19.6538H97.202C97.656 19.6538 98.0533 19.4808 98.3938 19.1346C98.7343 18.7885 98.9046 18.3846 98.9046 17.9231V16.7115C98.9046 16.3077 98.7911 15.9615 98.5641 15.6731C98.3371 15.3269 98.0249 15.125 97.6277 15.0673L82.0493 11.0865C81.9358 11.0288 81.7939 11 81.6236 11H75.0688C74.6148 11 74.2175 11.1731 73.877 11.5192C73.5365 11.8654 73.3662 12.2692 73.3662 12.7308V17.9231C73.3662 18.3846 73.5365 18.7885 73.877 19.1346Z"
                fill="#3D465D"
            />
            <path
                d="M47.1582 56H69.2915"
                stroke="#3D465D"
                strokeWidth="5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-26': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9053 27.5255C20.8133 26.6979 21.7781 25.9996 22.7996 25.4307C17.9189 28.0169 13.8328 29.2324 10.5412 29.0772C7.87384 29.0772 7.30632 29.8531 8.83863 31.4048L15.4786 37.3789C15.4786 33.4996 16.9542 30.2151 19.9053 27.5255ZM93.2004 25.4307C94.2219 25.9996 95.1867 26.6979 96.0947 27.5255C99.0459 30.2151 100.521 33.4996 100.521 37.3789L107.161 31.4048C108.694 29.8531 108.126 29.0772 105.459 29.0772C102.167 29.2324 98.0811 28.0169 93.2004 25.4307Z"
                fill="#3D465D"
            />
            <path
                d="M44.5071 26.4399C41.2155 23.3882 37.2145 21.8623 32.5041 21.8623C27.7937 21.8623 23.7927 23.3882 20.5011 26.4399C17.1527 29.4399 15.4785 33.0864 15.4785 37.3795H49.5297C49.5297 33.0864 47.8555 29.4399 44.5071 26.4399Z"
                fill="white"
            />
            <path
                d="M39.7403 30.7844C37.754 28.974 35.342 28.0688 32.5044 28.0688C29.7236 28.0688 27.34 28.974 25.3537 30.7844C23.3106 32.5947 22.2891 34.793 22.2891 37.3792H42.7198C42.7198 34.793 41.7266 32.5947 39.7403 30.7844Z"
                fill="#3D465D"
            />
            <path
                d="M95.5833 26.4399C92.2917 23.3882 88.2907 21.8623 83.5803 21.8623C78.8699 21.8623 74.8688 23.3882 71.5772 26.4399C68.2289 29.4399 66.5547 33.0864 66.5547 37.3795H100.606C100.606 33.0864 98.9317 29.4399 95.5833 26.4399Z"
                fill="white"
            />
            <path
                d="M90.8174 30.7844C88.8311 28.974 86.4192 28.0688 83.5816 28.0688C80.8007 28.0688 78.4171 28.974 76.4308 30.7844C74.3877 32.5947 73.3662 34.793 73.3662 37.3792H93.7969C93.7969 34.793 92.8038 32.5947 90.8174 30.7844Z"
                fill="#3D465D"
            />
            <path
                d="M17.1807 17.2069C17.1807 17.6207 17.3509 17.9828 17.6914 18.2931C18.0319 18.6034 18.4292 18.7586 18.8832 18.7586H41.0165C41.4705 18.7586 41.8678 18.6034 42.2083 18.2931C42.5488 17.9828 42.719 17.6207 42.719 17.2069V12.5517C42.719 12.1379 42.5488 11.7759 42.2083 11.4655C41.8678 11.1552 41.4705 11 41.0165 11H34.4616C34.2914 11 34.1495 11.0259 34.036 11.0776L18.4576 14.6466C18.0603 14.6983 17.7482 14.8793 17.5212 15.1897C17.2942 15.4483 17.1807 15.7586 17.1807 16.1207V17.2069Z"
                fill="#3D465D"
            />
            <path
                d="M73.877 18.2931C74.2175 18.6034 74.6148 18.7586 75.0688 18.7586H97.202C97.656 18.7586 98.0533 18.6034 98.3938 18.2931C98.7343 17.9828 98.9046 17.6207 98.9046 17.2069V16.1207C98.9046 15.7586 98.7911 15.4483 98.5641 15.1897C98.3371 14.8793 98.0249 14.6983 97.6277 14.6466L82.0493 11.0776C81.9358 11.0259 81.7939 11 81.6236 11H75.0688C74.6148 11 74.2175 11.1552 73.877 11.4655C73.5365 11.7759 73.3662 12.1379 73.3662 12.5517V17.2069C73.3662 17.6207 73.5365 17.9828 73.877 18.2931Z"
                fill="#3D465D"
            />
            <path
                d="M89.3825 48.0395C88.0772 47.1395 86.5449 46.6895 84.7856 46.6895C66.0575 49.2653 47.7266 49.2653 29.793 46.6895C28.0337 46.6895 26.5014 47.1395 25.1961 48.0395C23.9475 48.9395 23.3232 50.0101 23.3232 51.2515V51.4843C23.3232 52.6946 23.9475 53.7653 25.1961 54.6964C26.5014 55.5653 28.0337 55.9998 29.793 55.9998C47.7266 53.455 66.0575 53.455 84.7856 55.9998C86.5449 55.9998 88.0772 55.5653 89.3825 54.6964C90.7446 53.7653 91.4256 52.6946 91.4256 51.4843V51.2515C91.4256 50.0101 90.7446 48.9395 89.3825 48.0395Z"
                fill="white"
            />
        </svg>
    ),
    'face-27': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9053 27.2454C20.8133 26.4318 21.7781 25.7454 22.7996 25.186C17.9189 27.7284 13.8328 28.9233 10.5412 28.7708C7.87384 28.7708 7.30632 29.5335 8.83863 31.0589L15.4786 36.9318C15.4786 33.1182 16.9542 29.8894 19.9053 27.2454ZM93.2004 25.186C94.2219 25.7454 95.1867 26.4318 96.0947 27.2454C99.0459 29.8894 100.521 33.1182 100.521 36.9318L107.161 31.0589C108.694 29.5335 108.126 28.7708 105.459 28.7708C102.167 28.9233 98.0811 27.7284 93.2004 25.186Z"
                fill="#3D465D"
            />
            <path
                d="M44.5071 26.1777C41.2155 23.1777 37.2145 21.6777 32.5041 21.6777C27.7937 21.6777 23.7927 23.1777 20.5011 26.1777C17.1527 29.1269 15.4785 32.7116 15.4785 36.932H49.5297C49.5297 32.7116 47.8555 29.1269 44.5071 26.1777Z"
                fill="white"
            />
            <path
                d="M39.7403 30.4493C37.754 28.6696 35.342 27.7798 32.5044 27.7798C29.7236 27.7798 27.34 28.6696 25.3537 30.4493C23.3106 32.2289 22.2891 34.39 22.2891 36.9323H42.7198C42.7198 34.39 41.7266 32.2289 39.7403 30.4493Z"
                fill="#3D465D"
            />
            <path
                d="M95.5833 26.1777C92.2917 23.1777 88.2907 21.6777 83.5803 21.6777C78.8699 21.6777 74.8688 23.1777 71.5772 26.1777C68.2289 29.1269 66.5547 32.7116 66.5547 36.932H100.606C100.606 32.7116 98.9317 29.1269 95.5833 26.1777Z"
                fill="white"
            />
            <path
                d="M90.8174 30.4493C88.8311 28.6696 86.4192 27.7798 83.5816 27.7798C80.8007 27.7798 78.4171 28.6696 76.4308 30.4493C74.3877 32.2289 73.3662 34.39 73.3662 36.9323H93.7969C93.7969 34.39 92.8038 32.2289 90.8174 30.4493Z"
                fill="#3D465D"
            />
            <path
                d="M17.1807 17.1017C17.1807 17.5085 17.3509 17.8644 17.6914 18.1695C18.0319 18.4746 18.4292 18.6271 18.8832 18.6271H41.0165C41.4705 18.6271 41.8678 18.4746 42.2083 18.1695C42.5488 17.8644 42.719 17.5085 42.719 17.1017V12.5254C42.719 12.1186 42.5488 11.7627 42.2083 11.4576C41.8678 11.1525 41.4705 11 41.0165 11H34.4616C34.2914 11 34.1495 11.0254 34.036 11.0763L18.4576 14.5847C18.0603 14.6356 17.7482 14.8136 17.5212 15.1186C17.2942 15.3729 17.1807 15.678 17.1807 16.0339V17.1017Z"
                fill="#3D465D"
            />
            <path
                d="M73.877 18.1695C74.2175 18.4746 74.6148 18.6271 75.0688 18.6271H97.202C97.656 18.6271 98.0533 18.4746 98.3938 18.1695C98.7343 17.8644 98.9046 17.5085 98.9046 17.1017V16.0339C98.9046 15.678 98.7911 15.3729 98.5641 15.1186C98.3371 14.8136 98.0249 14.6356 97.6277 14.5847L82.0493 11.0763C81.9358 11.0254 81.7939 11 81.6236 11H75.0688C74.6148 11 74.2175 11.1525 73.877 11.4576C73.5365 11.7627 73.3662 12.1186 73.3662 12.5254V17.1017C73.3662 17.5085 73.5365 17.8644 73.877 18.1695Z"
                fill="#3D465D"
            />
            <path
                d="M71.9194 52.1099V52.0336C72.6005 51.576 73.2531 51.1184 73.8774 50.6608C74.5584 50.1014 74.9273 49.415 74.984 48.6014C75.0408 47.737 74.757 46.9998 74.1328 46.3896C73.5085 45.7286 72.7423 45.3726 71.8343 45.3218C70.8695 45.2709 70.0466 45.5252 69.3656 46.0845C68.9116 46.4404 68.4292 46.7964 67.9184 47.1523H67.8333C65.1092 48.9828 61.8176 49.8981 57.9585 49.8981C53.9858 49.8981 50.6091 48.9828 47.8282 47.1523C47.4877 46.8981 47.1756 46.6438 46.8918 46.3896C46.2676 45.7286 45.5014 45.3726 44.5934 45.3218C43.6286 45.2709 42.8057 45.5252 42.1247 46.0845C41.3869 46.6438 40.9896 47.3557 40.9329 48.2201C40.8761 49.0336 41.1599 49.7709 41.7842 50.432C42.2949 50.9404 42.9192 51.4489 43.657 51.9574C43.7137 51.9574 43.7421 51.9828 43.7421 52.0336C47.658 54.6777 52.3968 55.9998 57.9585 55.9998C63.4066 55.9998 68.0603 54.7031 71.9194 52.1099Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-28': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9053 27.5255C20.8133 26.6979 21.7781 25.9996 22.7996 25.4307C17.9189 28.0169 13.8328 29.2324 10.5412 29.0772C7.87384 29.0772 7.30632 29.8531 8.83863 31.4048L15.4786 37.3789C15.4786 33.4996 16.9542 30.2151 19.9053 27.5255ZM93.2004 25.4307C94.2219 25.9996 95.1867 26.6979 96.0947 27.5255C99.0459 30.2151 100.521 33.4996 100.521 37.3789L107.161 31.4048C108.694 29.8531 108.126 29.0772 105.459 29.0772C102.167 29.2324 98.0811 28.0169 93.2004 25.4307Z"
                fill="#3D465D"
            />
            <path
                d="M44.5071 26.4394C41.2155 23.3877 37.2145 21.8618 32.5041 21.8618C27.7937 21.8618 23.7927 23.3877 20.5011 26.4394C17.1527 29.4394 15.4785 33.0859 15.4785 37.379H49.5297C49.5297 33.0859 47.8555 29.4394 44.5071 26.4394Z"
                fill="white"
            />
            <path
                d="M39.7403 30.7844C37.754 28.974 35.342 28.0688 32.5044 28.0688C29.7236 28.0688 27.34 28.974 25.3537 30.7844C23.3106 32.5947 22.2891 34.793 22.2891 37.3792H42.7198C42.7198 34.793 41.7266 32.5947 39.7403 30.7844Z"
                fill="#3D465D"
            />
            <path
                d="M95.5833 26.4394C92.2917 23.3877 88.2907 21.8618 83.5803 21.8618C78.8699 21.8618 74.8688 23.3877 71.5772 26.4394C68.2289 29.4394 66.5547 33.0859 66.5547 37.379H100.606C100.606 33.0859 98.9317 29.4394 95.5833 26.4394Z"
                fill="white"
            />
            <path
                d="M90.8174 30.7844C88.8311 28.974 86.4192 28.0688 83.5816 28.0688C80.8007 28.0688 78.4171 28.974 76.4308 30.7844C74.3877 32.5947 73.3662 34.793 73.3662 37.3792H93.7969C93.7969 34.793 92.8038 32.5947 90.8174 30.7844Z"
                fill="#3D465D"
            />
            <path
                d="M17.1807 17.2069C17.1807 17.6207 17.3509 17.9828 17.6914 18.2931C18.0319 18.6034 18.4292 18.7586 18.8832 18.7586H41.0165C41.4705 18.7586 41.8678 18.6034 42.2083 18.2931C42.5488 17.9828 42.719 17.6207 42.719 17.2069V12.5517C42.719 12.1379 42.5488 11.7759 42.2083 11.4655C41.8678 11.1552 41.4705 11 41.0165 11H34.4616C34.2914 11 34.1495 11.0259 34.036 11.0776L18.4576 14.6465C18.0603 14.6983 17.7482 14.8793 17.5212 15.1897C17.2942 15.4483 17.1807 15.7586 17.1807 16.1207V17.2069Z"
                fill="#3D465D"
            />
            <path
                d="M73.877 18.2931C74.2175 18.6034 74.6148 18.7586 75.0688 18.7586H97.202C97.656 18.7586 98.0533 18.6034 98.3938 18.2931C98.7343 17.9828 98.9046 17.6207 98.9046 17.2069V16.1207C98.9046 15.7586 98.7911 15.4483 98.5641 15.1897C98.3371 14.8793 98.0249 14.6983 97.6277 14.6465L82.0493 11.0776C81.9358 11.0259 81.7939 11 81.6236 11H75.0688C74.6148 11 74.2175 11.1552 73.877 11.4655C73.5365 11.7759 73.3662 12.1379 73.3662 12.5517V17.2069C73.3662 17.6207 73.5365 17.9828 73.877 18.2931Z"
                fill="#3D465D"
            />
            <path
                d="M68.8677 49.6382C69.3217 48.914 69.4352 48.164 69.2082 47.3882C68.9244 46.5606 68.3569 45.9657 67.5056 45.6037C66.7111 45.1382 65.8314 45.0347 64.8666 45.2933C63.9586 45.5002 63.2492 45.9399 62.7384 46.6123C62.2844 47.1813 61.6318 47.7244 60.7805 48.2416C58.6807 49.4313 56.212 50.0261 53.3744 50.0261C52.0691 50.0261 50.8489 49.9226 49.7139 49.7157C48.7491 49.5606 47.8694 49.7157 47.0749 50.1813C46.2804 50.5433 45.7696 51.164 45.5426 52.0433C45.3156 52.8192 45.4858 53.5692 46.0534 54.2933C46.5074 54.914 47.2452 55.3278 48.2667 55.5347C49.799 55.845 51.5016 56.0002 53.3744 56.0002C57.7443 56.0002 61.5183 55.0692 64.6964 53.2071L64.6113 53.2847C66.5408 52.1985 67.9596 50.983 68.8677 49.6382Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-29': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M104 21.332H67.2002C67.2002 25.9423 69.0095 29.8583 72.6282 33.08C76.1855 36.3572 80.5095 37.9958 85.6002 37.9958C90.6909 37.9958 95.0149 36.3572 98.5722 33.08C102.191 29.8583 104 25.9423 104 21.332Z"
                fill="white"
            />
            <path
                d="M93.4205 28.4141C95.5672 26.47 96.6405 24.1093 96.6405 21.332H74.5605C74.5605 24.1093 75.6645 26.47 77.8725 28.4141C80.0192 30.3582 82.5952 31.3303 85.6005 31.3303C88.6672 31.3303 91.2739 30.3582 93.4205 28.4141Z"
                fill="#3D465D"
            />
            <path
                d="M48.8 21.332H12C12 25.9423 13.8093 29.8583 17.428 33.08C20.9853 36.3572 25.3093 37.9958 30.4 37.9958C35.4907 37.9958 39.8147 36.3572 43.372 33.08C46.9907 29.8583 48.8 25.9423 48.8 21.332Z"
                fill="white"
            />
            <path
                d="M38.2194 28.4141C40.366 26.47 41.4394 24.1093 41.4394 21.332H19.3594C19.3594 24.1093 20.4634 26.47 22.6714 28.4141C24.818 30.3582 27.394 31.3303 30.3994 31.3303C33.466 31.3303 36.0727 30.3582 38.2194 28.4141Z"
                fill="#3D465D"
            />
            <path
                d="M13.104 20.4154C13.7787 21.0264 14.6373 21.3319 15.68 21.3319H45.12C46.1627 21.3319 47.052 21.0264 47.788 20.4154C48.4627 19.7488 48.8 18.9434 48.8 17.9991V16.3328C48.8 15.3885 48.4627 14.6108 47.788 13.9998C47.052 13.3333 46.1627 13 45.12 13H15.68C14.6373 13 13.7787 13.3333 13.104 13.9998C12.368 14.6108 12 15.3885 12 16.3328V17.9991C12 18.9434 12.368 19.7488 13.104 20.4154Z"
                fill="#3D465D"
            />
            <path
                d="M68.3042 20.4154C68.9789 21.0264 69.8375 21.3319 70.8802 21.3319H100.32C101.363 21.3319 102.252 21.0264 102.988 20.4154C103.663 19.7488 104 18.9434 104 17.9991V16.3328C104 15.3885 103.663 14.6108 102.988 13.9998C102.252 13.3333 101.363 13 100.32 13H70.8802C69.8375 13 68.9789 13.3333 68.3042 13.9998C67.5682 14.6108 67.2002 15.3885 67.2002 16.3328V17.9991C67.2002 18.9434 67.5682 19.7488 68.3042 20.4154Z"
                fill="#3D465D"
            />
            <path
                d="M68.7644 51.0769C69.3164 51.4102 69.8378 51.7712 70.3284 52.1601C71.0644 52.7711 71.9538 53.0488 72.9964 52.9933C73.9778 52.9377 74.8058 52.5767 75.4804 51.9101C76.1551 51.188 76.4618 50.3826 76.4004 49.4939C76.3391 48.5496 75.9404 47.7719 75.2044 47.1609C74.5298 46.661 73.8244 46.1611 73.0884 45.6612C68.9178 42.7728 63.8884 41.3286 58.0004 41.3286C51.9898 41.3286 46.8684 42.7728 42.6364 45.6612C42.6364 45.7167 42.6058 45.7445 42.5444 45.7445C41.7471 46.3 41.0724 46.8832 40.5204 47.4942C39.8458 48.1608 39.5391 48.9662 39.6004 49.9105C39.6618 50.7992 40.0911 51.5491 40.8884 52.1601C41.6244 52.7711 42.5138 53.0488 43.5564 52.9933C44.5378 52.9377 45.3658 52.5767 46.0404 51.9101C46.3471 51.5768 46.6844 51.2713 47.0524 50.9936C50.0578 48.9939 53.7071 47.9941 58.0004 47.9941C62.1711 47.9941 65.7284 48.9939 68.6724 50.9936L68.7644 51.0769Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-30': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M103.999 22.0913H67.1992C67.1992 27.1216 69.0086 31.3944 72.6272 34.9096C76.1846 38.4853 80.5086 40.2732 85.5992 40.2732C90.6899 40.2732 95.0139 38.4853 98.5712 34.9096C102.19 31.3944 103.999 27.1216 103.999 22.0913Z"
                fill="white"
            />
            <path
                d="M93.4205 29.8186C95.5672 27.6974 96.6405 25.1216 96.6405 22.0913H74.5605C74.5605 25.1216 75.6645 27.6974 77.8725 29.8186C80.0192 31.9398 82.5952 33.0005 85.6005 33.0005C88.6672 33.0005 91.2739 31.9398 93.4205 29.8186Z"
                fill="#3D465D"
            />
            <path
                d="M48.8 22.0908H12C12 27.1211 13.8093 31.3939 17.428 34.9091C20.9853 38.4848 25.3093 40.2727 30.4 40.2727C35.4907 40.2727 39.8147 38.4848 43.372 34.9091C46.9907 31.3939 48.8 27.1211 48.8 22.0908Z"
                fill="white"
            />
            <path
                d="M38.2204 29.8181C40.367 27.6969 41.4404 25.1211 41.4404 22.0908H19.3604C19.3604 25.1211 20.4644 27.6969 22.6724 29.8181C24.819 31.9394 27.395 33 30.4004 33C33.467 33 36.0737 31.9394 38.2204 29.8181Z"
                fill="#3D465D"
            />
            <path
                d="M13.104 21.091C13.7787 21.7576 14.6373 22.091 15.68 22.091H45.12C46.1627 22.091 47.052 21.7576 47.788 21.091C48.4627 20.3637 48.8 19.4849 48.8 18.4546V16.6364C48.8 15.6061 48.4627 14.7576 47.788 14.0909C47.052 13.3636 46.1627 13 45.12 13H15.68C14.6373 13 13.7787 13.3636 13.104 14.0909C12.368 14.7576 12 15.6061 12 16.6364V18.4546C12 19.4849 12.368 20.3637 13.104 21.091Z"
                fill="#3D465D"
            />
            <path
                d="M68.3032 21.0914C68.9779 21.7581 69.8366 22.0914 70.8792 22.0914H100.319C101.362 22.0914 102.251 21.7581 102.987 21.0914C103.662 20.3642 103.999 19.4854 103.999 18.4551V16.6369C103.999 15.6066 103.662 14.7581 102.987 14.0914C102.251 13.3641 101.362 13.0005 100.319 13.0005H70.8792C69.8366 13.0005 68.9779 13.3641 68.3032 14.0914C67.5672 14.7581 67.1992 15.6066 67.1992 16.6369V18.4551C67.1992 19.4854 67.5672 20.3642 68.3032 21.0914Z"
                fill="#3D465D"
            />
            <path
                d="M46.96 53H70.88"
                stroke="#3D465D"
                strokeWidth="5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-31': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M104 21H67.2002C67.2002 25.4267 69.0095 29.1867 72.6282 32.28C76.1855 35.4267 80.5095 37 85.6002 37C90.6909 37 95.0149 35.4267 98.5722 32.28C102.191 29.1867 104 25.4267 104 21Z"
                fill="white"
            />
            <path
                d="M93.4205 27.8C95.5672 25.9333 96.6405 23.6667 96.6405 21H74.5605C74.5605 23.6667 75.6645 25.9333 77.8725 27.8C80.0192 29.6667 82.5952 30.6 85.6005 30.6C88.6672 30.6 91.2739 29.6667 93.4205 27.8Z"
                fill="#3D465D"
            />
            <path
                d="M48.8 21H12C12 25.4267 13.8093 29.1867 17.428 32.28C20.9853 35.4267 25.3093 37 30.4 37C35.4907 37 39.8147 35.4267 43.372 32.28C46.9907 29.1867 48.8 25.4267 48.8 21Z"
                fill="white"
            />
            <path
                d="M38.2194 27.8C40.366 25.9333 41.4394 23.6667 41.4394 21H19.3594C19.3594 23.6667 20.4634 25.9333 22.6714 27.8C24.818 29.6667 27.394 30.6 30.3994 30.6C33.466 30.6 36.0727 29.6667 38.2194 27.8Z"
                fill="#3D465D"
            />
            <path
                d="M13.104 20.12C13.7787 20.7067 14.6373 21 15.68 21H45.12C46.1627 21 47.052 20.7067 47.788 20.12C48.4627 19.48 48.8 18.7067 48.8 17.8V16.2C48.8 15.2933 48.4627 14.5467 47.788 13.96C47.052 13.32 46.1627 13 45.12 13H15.68C14.6373 13 13.7787 13.32 13.104 13.96C12.368 14.5467 12 15.2933 12 16.2V17.8C12 18.7067 12.368 19.48 13.104 20.12Z"
                fill="#3D465D"
            />
            <path
                d="M68.3042 20.12C68.9789 20.7067 69.8375 21 70.8802 21H100.32C101.363 21 102.252 20.7067 102.988 20.12C103.663 19.48 104 18.7067 104 17.8V16.2C104 15.2933 103.663 14.5467 102.988 13.96C102.252 13.32 101.363 13 100.32 13H70.8802C69.8375 13 68.9789 13.32 68.3042 13.96C67.5682 14.5467 67.2002 15.2933 67.2002 16.2V17.8C67.2002 18.7067 67.5682 19.48 68.3042 20.12Z"
                fill="#3D465D"
            />
            <path
                d="M92.5922 44.7919C91.1815 43.8639 89.5255 43.3999 87.6242 43.3999C67.3842 46.0559 47.5735 46.0559 28.1922 43.3999C26.2909 43.3999 24.6349 43.8639 23.2242 44.7919C21.8749 45.7199 21.2002 46.8239 21.2002 48.1039V48.3439C21.2002 49.5919 21.8749 50.6959 23.2242 51.6559C24.6349 52.5519 26.2909 52.9999 28.1922 52.9999C47.5735 50.3759 67.3842 50.3759 87.6242 52.9999C89.5255 52.9999 91.1815 52.5519 92.5922 51.6559C94.0642 50.6959 94.8002 49.5919 94.8002 48.3439V48.1039C94.8002 46.8239 94.0642 45.7199 92.5922 44.7919Z"
                fill="white"
            />
        </svg>
    ),
    'face-32': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M104 21.332H67.2002C67.2002 25.9423 69.0095 29.8583 72.6282 33.0799C76.1855 36.3571 80.5095 37.9957 85.6002 37.9957C90.6909 37.9957 95.0149 36.3571 98.5722 33.0799C102.191 29.8583 104 25.9423 104 21.332Z"
                fill="white"
            />
            <path
                d="M93.4205 28.4141C95.5672 26.47 96.6405 24.1093 96.6405 21.332H74.5605C74.5605 24.1093 75.6645 26.47 77.8725 28.4141C80.0192 30.3582 82.5952 31.3302 85.6005 31.3302C88.6672 31.3302 91.2739 30.3582 93.4205 28.4141Z"
                fill="#3D465D"
            />
            <path
                d="M48.8 21.332H12C12 25.9423 13.8093 29.8583 17.428 33.0799C20.9853 36.3571 25.3093 37.9957 30.4 37.9957C35.4907 37.9957 39.8147 36.3571 43.372 33.0799C46.9907 29.8583 48.8 25.9423 48.8 21.332Z"
                fill="white"
            />
            <path
                d="M38.2194 28.4141C40.366 26.47 41.4394 24.1093 41.4394 21.332H19.3594C19.3594 24.1093 20.4634 26.47 22.6714 28.4141C24.818 30.3582 27.394 31.3302 30.3994 31.3302C33.466 31.3302 36.0727 30.3582 38.2194 28.4141Z"
                fill="#3D465D"
            />
            <path
                d="M13.104 20.4153C13.7787 21.0263 14.6373 21.3318 15.68 21.3318H45.12C46.1627 21.3318 47.052 21.0263 47.788 20.4153C48.4627 19.7488 48.8 18.9434 48.8 17.9991V16.3327C48.8 15.3885 48.4627 14.6108 47.788 13.9998C47.052 13.3333 46.1627 13 45.12 13H15.68C14.6373 13 13.7787 13.3333 13.104 13.9998C12.368 14.6108 12 15.3885 12 16.3327V17.9991C12 18.9434 12.368 19.7488 13.104 20.4153Z"
                fill="#3D465D"
            />
            <path
                d="M68.3042 20.4153C68.9789 21.0263 69.8375 21.3318 70.8802 21.3318H100.32C101.363 21.3318 102.252 21.0263 102.988 20.4153C103.663 19.7488 104 18.9434 104 17.9991V16.3327C104 15.3885 103.663 14.6108 102.988 13.9998C102.252 13.3333 101.363 13 100.32 13H70.8802C69.8375 13 68.9789 13.3333 68.3042 13.9998C67.5682 14.6108 67.2002 15.3885 67.2002 16.3327V17.9991C67.2002 18.9434 67.5682 19.7488 68.3042 20.4153Z"
                fill="#3D465D"
            />
            <path
                d="M47.2356 43.2517C46.6836 42.9185 46.1622 42.5574 45.6716 42.1686C44.9356 41.5576 44.0462 41.2799 43.0036 41.3354C42.0222 41.391 41.1942 41.752 40.5196 42.4186C39.8449 43.1406 39.5382 43.9461 39.5996 44.8348C39.6609 45.7791 40.0596 46.5567 40.7956 47.1677C41.4702 47.6676 42.1756 48.1675 42.9116 48.6674C47.0822 51.5558 52.1116 53 57.9996 53C64.0102 53 69.1316 51.5558 73.3636 48.6674C73.3636 48.6119 73.3942 48.5841 73.4556 48.5841C74.2529 48.0287 74.9276 47.4454 75.4796 46.8344C76.1542 46.1679 76.4609 45.3625 76.3996 44.4182C76.3382 43.5295 75.9089 42.7796 75.1116 42.1686C74.3756 41.5576 73.4862 41.2799 72.4436 41.3354C71.4622 41.391 70.6342 41.752 69.9596 42.4186C69.6529 42.7518 69.3156 43.0573 68.9476 43.3351C65.9422 45.3347 62.2929 46.3345 57.9996 46.3345C53.8289 46.3345 50.2716 45.3347 47.3276 43.3351L47.2356 43.2517Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-33': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M104 21.3335H67.2002C67.2002 25.9446 69.0095 29.8613 72.6282 33.0835C76.1855 36.3613 80.5095 38.0001 85.6002 38.0001C90.6909 38.0001 95.0149 36.3613 98.5722 33.0835C102.191 29.8613 104 25.9446 104 21.3335Z"
                fill="white"
            />
            <path
                d="M93.4205 28.4168C95.5672 26.4724 96.6405 24.1113 96.6405 21.3335H74.5605C74.5605 24.1113 75.6645 26.4724 77.8725 28.4168C80.0192 30.3613 82.5952 31.3335 85.6005 31.3335C88.6672 31.3335 91.2739 30.3613 93.4205 28.4168Z"
                fill="#3D465D"
            />
            <path
                d="M48.8 21.3335H12C12 25.9446 13.8093 29.8613 17.428 33.0835C20.9853 36.3613 25.3093 38.0001 30.4 38.0001C35.4907 38.0001 39.8147 36.3613 43.372 33.0835C46.9907 29.8613 48.8 25.9446 48.8 21.3335Z"
                fill="white"
            />
            <path
                d="M38.2194 28.4168C40.366 26.4724 41.4394 24.1113 41.4394 21.3335H19.3594C19.3594 24.1113 20.4634 26.4724 22.6714 28.4168C24.818 30.3613 27.394 31.3335 30.3994 31.3335C33.466 31.3335 36.0727 30.3613 38.2194 28.4168Z"
                fill="#3D465D"
            />
            <path
                d="M13.104 20.4167C13.7787 21.0278 14.6373 21.3333 15.68 21.3333H45.12C46.1627 21.3333 47.052 21.0278 47.788 20.4167C48.4627 19.75 48.8 18.9444 48.8 18V16.3333C48.8 15.3889 48.4627 14.6111 47.788 14C47.052 13.3333 46.1627 13 45.12 13H15.68C14.6373 13 13.7787 13.3333 13.104 14C12.368 14.6111 12 15.3889 12 16.3333V18C12 18.9444 12.368 19.75 13.104 20.4167Z"
                fill="#3D465D"
            />
            <path
                d="M68.3042 20.4167C68.9789 21.0278 69.8375 21.3333 70.8802 21.3333H100.32C101.363 21.3333 102.252 21.0278 102.988 20.4167C103.663 19.75 104 18.9444 104 18V16.3333C104 15.3889 103.663 14.6111 102.988 14C102.252 13.3333 101.363 13 100.32 13H70.8802C69.8375 13 68.9789 13.3333 68.3042 14C67.5682 14.6111 67.2002 15.3889 67.2002 16.3333V18C67.2002 18.9444 67.5682 19.75 68.3042 20.4167Z"
                fill="#3D465D"
            />
            <path
                d="M70.4206 46.1665C70.9112 45.3888 71.0339 44.5832 70.7886 43.7499C70.4819 42.861 69.8686 42.2221 68.9486 41.8332C68.0899 41.3332 67.1392 41.2221 66.0966 41.4999C65.1152 41.7221 64.3486 42.1943 63.7966 42.9165C63.3059 43.5277 62.6006 44.111 61.6806 44.6665C59.4112 45.9443 56.7432 46.5832 53.6766 46.5832C52.2659 46.5832 50.9472 46.4721 49.7206 46.2499C48.6779 46.0832 47.7272 46.2499 46.8686 46.7499C46.0099 47.1388 45.4579 47.8054 45.2126 48.7499C44.9672 49.5832 45.1512 50.3888 45.7646 51.1665C46.2552 51.8332 47.0526 52.2776 48.1566 52.4999C49.8126 52.8332 51.6526 52.9999 53.6766 52.9999C58.3992 52.9999 62.4779 51.9999 65.9126 49.9999L65.8206 50.0832C67.9059 48.9165 69.4392 47.611 70.4206 46.1665Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-34': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.074 13.0855C16.6913 16.4758 15 20.5269 15 25.2389C15 30.0083 16.6913 34.0595 20.074 37.3923C23.3993 40.7826 27.4413 42.4778 32.2 42.4778C36.9587 42.4778 41.0007 40.7826 44.326 37.3923C47.7087 34.0595 49.4 30.0083 49.4 25.2389C49.4 20.5269 47.7087 16.4758 44.326 13.0855C41.0007 9.69516 36.9587 8 32.2 8C27.4413 8 23.3993 9.69516 20.074 13.0855Z"
                fill="white"
            />
            <path
                d="M39.5099 32.5654C41.5165 30.5542 42.5199 28.112 42.5199 25.2388C42.5199 22.4232 41.5165 20.0097 39.5099 17.9985C37.5032 15.9298 35.0665 14.8955 32.1999 14.8955C29.3905 14.8955 26.9825 15.9298 24.9759 17.9985C22.9119 20.0097 21.8799 22.4232 21.8799 25.2388C21.8799 28.112 22.9119 30.5542 24.9759 32.5654C26.9825 34.5766 29.3905 35.5822 32.1999 35.5822C35.0665 35.5822 37.5032 34.5766 39.5099 32.5654Z"
                fill="#3D465D"
            />
            <path
                d="M71.6736 13.0855C68.2909 16.4758 66.5996 20.5269 66.5996 25.2389C66.5996 30.0083 68.2909 34.0595 71.6736 37.3923C74.9989 40.7826 79.0409 42.4778 83.7996 42.4778C88.5583 42.4778 92.6003 40.7826 95.9256 37.3923C99.3083 34.0595 101 30.0083 101 25.2389C101 20.5269 99.3083 16.4758 95.9256 13.0855C92.6003 9.69516 88.5583 8 83.7996 8C79.0409 8 74.9989 9.69516 71.6736 13.0855Z"
                fill="white"
            />
            <path
                d="M91.1105 32.5654C93.1171 30.5542 94.1205 28.112 94.1205 25.2388C94.1205 22.4232 93.1171 20.0097 91.1105 17.9985C89.1038 15.9298 86.6671 14.8955 83.8005 14.8955C80.9911 14.8955 78.5831 15.9298 76.5765 17.9985C74.5125 20.0097 73.4805 22.4232 73.4805 25.2388C73.4805 28.112 74.5125 30.5542 76.5765 32.5654C78.5831 34.5766 80.9911 35.5822 83.8005 35.5822C86.6671 35.5822 89.1038 34.5766 91.1105 32.5654Z"
                fill="#3D465D"
            />
            <path
                d="M72.1041 50.3217C68.2055 47.3911 63.5041 45.9258 58.0001 45.9258C52.3815 45.9258 47.5941 47.4198 43.6381 50.4079C43.6381 50.4654 43.6095 50.4941 43.5521 50.4941C42.8068 51.0687 42.1761 51.6434 41.6601 52.218C41.0295 52.965 40.7428 53.7982 40.8001 54.7176C40.8575 55.6945 41.2588 56.499 42.0041 57.1311C42.6921 57.7632 43.5235 58.0505 44.4981 57.993C45.4155 57.9356 46.1895 57.5333 46.8201 56.7863C47.1068 56.499 47.4221 56.2117 47.7661 55.9243C50.5755 53.8557 53.9868 52.8213 58.0001 52.8213C61.8988 52.8213 65.2241 53.8557 67.9761 55.9243H68.0621C68.5781 56.3266 69.0655 56.7288 69.5241 57.1311C70.2121 57.7632 71.0435 58.0505 72.0181 57.993C72.9355 57.9356 73.7095 57.5333 74.3401 56.7863C74.9708 56.0967 75.2575 55.2635 75.2001 54.2866C75.1428 53.3672 74.7701 52.5915 74.0821 51.9594C73.4515 51.4422 72.7921 50.9251 72.1041 50.4079V50.3217Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-35': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M47 55H69.36"
                stroke="#3D465D"
                strokeWidth="6"
                strokeLinecap="round"
            />
            <path
                d="M20.074 16.0862C16.6913 19.477 15 23.5287 15 28.2414C15 33.0115 16.6913 37.0632 20.074 40.3965C23.3993 43.7874 27.4413 45.4828 32.2 45.4828C36.9587 45.4828 41.0007 43.7874 44.326 40.3965C47.7087 37.0632 49.4 33.0115 49.4 28.2414C49.4 23.5287 47.7087 19.477 44.326 16.0862C41.0007 12.6954 36.9587 11 32.2 11C27.4413 11 23.3993 12.6954 20.074 16.0862Z"
                fill="white"
            />
            <path
                d="M39.5099 35.5689C41.5165 33.5574 42.5199 31.1149 42.5199 28.2413C42.5199 25.4252 41.5165 23.0114 39.5099 20.9999C37.5032 18.931 35.0665 17.8965 32.1999 17.8965C29.3905 17.8965 26.9825 18.931 24.9759 20.9999C22.9119 23.0114 21.8799 25.4252 21.8799 28.2413C21.8799 31.1149 22.9119 33.5574 24.9759 35.5689C26.9825 37.5804 29.3905 38.5861 32.1999 38.5861C35.0665 38.5861 37.5032 37.5804 39.5099 35.5689Z"
                fill="#3D465D"
            />
            <path
                d="M71.6736 16.0862C68.2909 19.477 66.5996 23.5287 66.5996 28.2414C66.5996 33.0115 68.2909 37.0632 71.6736 40.3965C74.9989 43.7874 79.0409 45.4828 83.7996 45.4828C88.5583 45.4828 92.6003 43.7874 95.9256 40.3965C99.3083 37.0632 101 33.0115 101 28.2414C101 23.5287 99.3083 19.477 95.9256 16.0862C92.6003 12.6954 88.5583 11 83.7996 11C79.0409 11 74.9989 12.6954 71.6736 16.0862Z"
                fill="white"
            />
            <path
                d="M91.1105 35.5689C93.1171 33.5574 94.1205 31.1149 94.1205 28.2413C94.1205 25.4252 93.1171 23.0114 91.1105 20.9999C89.1038 18.931 86.6671 17.8965 83.8005 17.8965C80.9911 17.8965 78.5831 18.931 76.5765 20.9999C74.5125 23.0114 73.4805 25.4252 73.4805 28.2413C73.4805 31.1149 74.5125 33.5574 76.5765 35.5689C78.5831 37.5804 80.9911 38.5861 83.8005 38.5861C86.6671 38.5861 89.1038 37.5804 91.1105 35.5689Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-36': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.074 13.0862C16.6913 16.477 15 20.5287 15 25.2414C15 30.0115 16.6913 34.0632 20.074 37.3965C23.3993 40.7874 27.4413 42.4828 32.2 42.4828C36.9587 42.4828 41.0007 40.7874 44.326 37.3965C47.7087 34.0632 49.4 30.0115 49.4 25.2414C49.4 20.5287 47.7087 16.477 44.326 13.0862C41.0007 9.6954 36.9587 8 32.2 8C27.4413 8 23.3993 9.6954 20.074 13.0862Z"
                fill="white"
            />
            <path
                d="M39.5099 32.5689C41.5165 30.5574 42.5199 28.1149 42.5199 25.2413C42.5199 22.4252 41.5165 20.0114 39.5099 17.9999C37.5032 15.931 35.0665 14.8965 32.1999 14.8965C29.3905 14.8965 26.9825 15.931 24.9759 17.9999C22.9119 20.0114 21.8799 22.4252 21.8799 25.2413C21.8799 28.1149 22.9119 30.5574 24.9759 32.5689C26.9825 34.5804 29.3905 35.5861 32.1999 35.5861C35.0665 35.5861 37.5032 34.5804 39.5099 32.5689Z"
                fill="#3D465D"
            />
            <path
                d="M71.6736 13.0862C68.2909 16.477 66.5996 20.5287 66.5996 25.2414C66.5996 30.0115 68.2909 34.0632 71.6736 37.3965C74.9989 40.7874 79.0409 42.4828 83.7996 42.4828C88.5583 42.4828 92.6003 40.7874 95.9256 37.3965C99.3083 34.0632 101 30.0115 101 25.2414C101 20.5287 99.3083 16.477 95.9256 13.0862C92.6003 9.6954 88.5583 8 83.7996 8C79.0409 8 74.9989 9.6954 71.6736 13.0862Z"
                fill="white"
            />
            <path
                d="M91.1105 32.5689C93.1171 30.5574 94.1205 28.1149 94.1205 25.2413C94.1205 22.4252 93.1171 20.0114 91.1105 17.9999C89.1038 15.931 86.6671 14.8965 83.8005 14.8965C80.9911 14.8965 78.5831 15.931 76.5765 17.9999C74.5125 20.0114 73.4805 22.4252 73.4805 25.2413C73.4805 28.1149 74.5125 30.5574 76.5765 32.5689C78.5831 34.5804 80.9911 35.5861 83.8005 35.5861C86.6671 35.5861 89.1038 34.5804 91.1105 32.5689Z"
                fill="#3D465D"
            />
            <path
                d="M69.6105 50.9312C70.0692 50.1266 70.1839 49.2933 69.9545 48.4312C69.6679 47.5117 69.0945 46.8508 68.2345 46.4485C67.4319 45.9312 66.5432 45.8163 65.5685 46.1036C64.6512 46.3335 63.9345 46.822 63.4185 47.5692C62.9599 48.2013 62.3005 48.8048 61.4405 49.3795C59.3192 50.7013 56.8252 51.3623 53.9585 51.3623C52.6399 51.3623 51.4072 51.2473 50.2605 51.0174C49.2859 50.845 48.3972 51.0174 47.5945 51.5347C46.7919 51.937 46.2759 52.6266 46.0465 53.6036C45.8172 54.4657 45.9892 55.2991 46.5625 56.1036C47.0212 56.7933 47.7665 57.2531 48.7985 57.483C50.3465 57.8278 52.0665 58.0002 53.9585 58.0002C58.3732 58.0002 62.1859 56.9657 65.3965 54.8968L65.3105 54.983C67.2599 53.7761 68.6932 52.4255 69.6105 50.9312Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-37': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.074 13.0862C16.6913 16.477 15 20.5287 15 25.2414C15 30.0115 16.6913 34.0632 20.074 37.3965C23.3993 40.7874 27.4413 42.4828 32.2 42.4828C36.9587 42.4828 41.0007 40.7874 44.326 37.3965C47.7087 34.0632 49.4 30.0115 49.4 25.2414C49.4 20.5287 47.7087 16.477 44.326 13.0862C41.0007 9.6954 36.9587 8 32.2 8C27.4413 8 23.3993 9.6954 20.074 13.0862Z"
                fill="white"
            />
            <path
                d="M39.5099 32.5689C41.5165 30.5574 42.5199 28.1149 42.5199 25.2413C42.5199 22.4252 41.5165 20.0114 39.5099 17.9999C37.5032 15.931 35.0665 14.8965 32.1999 14.8965C29.3905 14.8965 26.9825 15.931 24.9759 17.9999C22.9119 20.0114 21.8799 22.4252 21.8799 25.2413C21.8799 28.1149 22.9119 30.5574 24.9759 32.5689C26.9825 34.5804 29.3905 35.5861 32.1999 35.5861C35.0665 35.5861 37.5032 34.5804 39.5099 32.5689Z"
                fill="#3D465D"
            />
            <path
                d="M71.6736 13.0862C68.2909 16.477 66.5996 20.5287 66.5996 25.2414C66.5996 30.0115 68.2909 34.0632 71.6736 37.3965C74.9989 40.7874 79.0409 42.4828 83.7996 42.4828C88.5583 42.4828 92.6003 40.7874 95.9256 37.3965C99.3083 34.0632 101 30.0115 101 25.2414C101 20.5287 99.3083 16.477 95.9256 13.0862C92.6003 9.6954 88.5583 8 83.7996 8C79.0409 8 74.9989 9.6954 71.6736 13.0862Z"
                fill="white"
            />
            <path
                d="M91.1105 32.5689C93.1171 30.5574 94.1205 28.1149 94.1205 25.2413C94.1205 22.4252 93.1171 20.0114 91.1105 17.9999C89.1038 15.931 86.6671 14.8965 83.8005 14.8965C80.9911 14.8965 78.5831 15.931 76.5765 17.9999C74.5125 20.0114 73.4805 22.4252 73.4805 25.2413C73.4805 28.1149 74.5125 30.5574 76.5765 32.5689C78.5831 34.5804 80.9911 35.5861 83.8005 35.5861C86.6671 35.5861 89.1038 34.5804 91.1105 32.5689Z"
                fill="#3D465D"
            />
            <path
                d="M72.1041 53.6034V53.5172C72.7921 53 73.4515 52.4827 74.0821 51.9655C74.7701 51.3333 75.1428 50.5574 75.2001 49.6379C75.2575 48.6609 74.9708 47.8276 74.3401 47.1379C73.7095 46.3908 72.9355 45.9885 72.0181 45.931C71.0435 45.8735 70.2121 46.1609 69.5241 46.7931C69.0655 47.1954 68.5781 47.5977 68.0621 48H67.9761C65.2241 50.0689 61.8988 51.1034 58.0001 51.1034C53.9868 51.1034 50.5755 50.0689 47.7661 48C47.4221 47.7126 47.1068 47.4253 46.8201 47.1379C46.1895 46.3908 45.4155 45.9885 44.4981 45.931C43.5235 45.8735 42.6921 46.1609 42.0041 46.7931C41.2588 47.4253 40.8575 48.2299 40.8001 49.2069C40.7428 50.1264 41.0295 50.9597 41.6601 51.7069C42.1761 52.2816 42.8068 52.8563 43.5521 53.431C43.6095 53.431 43.6381 53.4597 43.6381 53.5172C47.5941 56.5057 52.3815 58 58.0001 58C63.5041 58 68.2055 56.5345 72.1041 53.6034Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-38': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.9069 15.4634C31.8329 15.4634 28.3725 16.9024 25.5256 19.7805C22.6296 22.6586 21.1816 26.0976 21.1816 30.0977C21.1816 34.1465 22.6296 37.5855 25.5256 40.4148C28.3725 43.2929 31.8329 44.732 35.9069 44.732C39.9809 44.732 43.4413 43.2929 46.2882 40.4148C49.1842 37.5855 50.6322 34.1465 50.6322 30.0977C50.6322 26.0976 49.1842 22.6586 46.2882 19.7805C43.4413 16.9024 39.9809 15.4634 35.9069 15.4634Z"
                fill="white"
            />
            <path
                d="M35.9074 21.3169C33.5023 21.3169 31.4408 22.195 29.7228 23.9511C27.9558 25.6584 27.0723 27.7072 27.0723 30.0975C27.0723 32.5365 27.9558 34.6097 29.7228 36.317C31.4408 38.0244 33.5023 38.878 35.9074 38.878C38.3616 38.878 40.4477 38.0244 42.1657 36.317C43.8836 34.6097 44.7426 32.5365 44.7426 30.0975C44.7426 27.7072 43.8836 25.6584 42.1657 23.9511C40.4477 22.195 38.3616 21.3169 35.9074 21.3169Z"
                fill="#3D465D"
            />
            <path
                d="M80.0827 15.4639C76.0087 15.4639 72.5483 16.9029 69.7014 19.781C66.8054 22.6591 65.3574 26.0981 65.3574 30.0982C65.3574 34.147 66.8054 37.586 69.7014 40.4153C72.5483 43.2934 76.0087 44.7324 80.0827 44.7324C84.1567 44.7324 87.6171 43.2934 90.464 40.4153C93.36 37.586 94.8079 34.147 94.8079 30.0982C94.8079 26.0981 93.36 22.6591 90.464 19.781C87.6171 16.9029 84.1567 15.4639 80.0827 15.4639Z"
                fill="white"
            />
            <path
                d="M80.0822 21.3169C77.6771 21.3169 75.6156 22.195 73.8976 23.9511C72.1306 25.6584 71.2471 27.7072 71.2471 30.0975C71.2471 32.5365 72.1306 34.6097 73.8976 36.317C75.6156 38.0244 77.6771 38.878 80.0822 38.878C82.5364 38.878 84.6225 38.0244 86.3405 36.317C88.0584 34.6097 88.9174 32.5365 88.9174 30.0975C88.9174 27.7072 88.0584 25.6584 86.3405 23.9511C84.6225 22.195 82.5364 21.3169 80.0822 21.3169Z"
                fill="#3D465D"
            />
            <path
                d="M18.4587 21.9026C18.8023 22.5856 19.3913 23.0246 20.2258 23.2197L48.6455 30.8295C49.4309 31.0247 50.1917 30.9271 50.928 30.5369C51.6151 30.1466 52.0569 29.5369 52.2532 28.7076L53.7994 23.0734C53.9957 22.2441 53.8975 21.5124 53.5049 20.8782C53.0631 20.1465 52.4496 19.6831 51.6642 19.488L23.2445 11.8781C22.41 11.683 21.6738 11.805 21.0357 12.244C20.2994 12.5855 19.8331 13.1708 19.6368 14.0001L18.0906 19.6343C17.8943 20.4636 18.017 21.2197 18.4587 21.9026Z"
                fill="#3D465D"
            />
            <path
                d="M64.3273 10.6342C63.542 10.8781 62.953 11.3659 62.5603 12.0977C62.1186 12.7318 61.9958 13.4635 62.1922 14.2928L63.7383 19.927C63.9347 20.7563 64.401 21.366 65.1372 21.7563C65.8244 22.1465 66.5607 22.2197 67.346 21.9758L95.7658 14.4391C96.6002 14.244 97.2138 13.7806 97.6064 13.0489C97.9991 12.4147 98.0973 11.683 97.9009 10.8537L96.3548 5.21953C96.1584 4.39026 95.7167 3.78049 95.0295 3.39025C94.3423 3 93.5815 2.90244 92.7471 3.09756L64.3273 10.6342Z"
                fill="#3D465D"
            />
            <path
                d="M91.3332 50.4877C90.2043 49.073 88.879 48.3657 87.3574 48.3657C71.1596 52.4145 55.3054 52.4145 39.7948 48.3657C38.2732 48.3657 36.9479 49.073 35.819 50.4877C34.7391 51.9023 34.1992 53.5853 34.1992 55.5365V55.9024C34.1992 57.8048 34.7391 59.4878 35.819 60.9512C36.9479 62.3171 38.2732 63 39.7948 63C55.3054 59 71.1596 59 87.3574 63C88.879 63 90.2043 62.3171 91.3332 60.9512C92.5113 59.4878 93.1003 57.8048 93.1003 55.9024V55.5365C93.1003 53.5853 92.5113 51.9023 91.3332 50.4877Z"
                fill="white"
            />
        </svg>
    ),
    'face-39': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.907 16.1011C30.833 16.1011 27.3725 17.6137 24.5256 20.6391C21.6296 23.6644 20.1816 27.2794 20.1816 31.484C20.1816 35.74 21.6296 39.355 24.5256 42.329C27.3725 45.3544 30.833 46.867 34.907 46.867C38.981 46.867 42.4414 45.3544 45.2883 42.329C48.1843 39.355 49.6323 35.74 49.6323 31.484C49.6323 27.2794 48.1843 23.6644 45.2883 20.6391C42.4414 17.6137 38.981 16.1011 34.907 16.1011Z"
                fill="white"
            />
            <path
                d="M34.9075 22.2544C32.5023 22.2544 30.4408 23.1774 28.7228 25.0233C26.9558 26.818 26.0723 28.9716 26.0723 31.4842C26.0723 34.048 26.9558 36.2273 28.7228 38.0219C30.4408 39.8166 32.5023 40.714 34.9075 40.714C37.3617 40.714 39.4478 39.8166 41.1657 38.0219C42.8837 36.2273 43.7426 34.048 43.7426 31.4842C43.7426 28.9716 42.8837 26.818 41.1657 25.0233C39.4478 23.1774 37.3617 22.2544 34.9075 22.2544Z"
                fill="#3D465D"
            />
            <path
                d="M79.0837 16.1011C75.0097 16.1011 71.5493 17.6137 68.7024 20.6391C65.8064 23.6644 64.3584 27.2794 64.3584 31.484C64.3584 35.74 65.8064 39.355 68.7024 42.329C71.5493 45.3544 75.0097 46.867 79.0837 46.867C83.1577 46.867 86.6182 45.3544 89.4651 42.329C92.361 39.355 93.809 35.74 93.809 31.484C93.809 27.2794 92.361 23.6644 89.4651 20.6391C86.6182 17.6137 83.1577 16.1011 79.0837 16.1011Z"
                fill="white"
            />
            <path
                d="M79.0842 22.2544C76.6791 22.2544 74.6175 23.1774 72.8996 25.0233C71.1325 26.818 70.249 28.9716 70.249 31.4842C70.249 34.048 71.1325 36.2273 72.8996 38.0219C74.6175 39.8166 76.6791 40.714 79.0842 40.714C81.5384 40.714 83.6245 39.8166 85.3425 38.0219C87.0604 36.2273 87.9194 34.048 87.9194 31.4842C87.9194 28.9716 87.0604 26.818 85.3425 25.0233C83.6245 23.1774 81.5384 22.2544 79.0842 22.2544Z"
                fill="#3D465D"
            />
            <path
                d="M17.4588 22.8703C17.8023 23.5882 18.3914 24.0496 19.2258 24.2547L47.6456 32.2539C48.431 32.459 49.1918 32.3564 49.9281 31.9462C50.6153 31.536 51.057 30.8951 51.2533 30.0234L52.7995 24.1009C52.9958 23.2292 52.8977 22.4601 52.505 21.7935C52.0632 21.0243 51.4497 20.5372 50.6643 20.3321L22.2445 12.3329C21.41 12.1278 20.6738 12.256 20.0357 12.7175C19.2994 13.0765 18.8331 13.6918 18.6368 14.5635L17.0906 20.4859C16.8943 21.3576 17.017 22.1524 17.4588 22.8703Z"
                fill="#3D465D"
            />
            <path
                d="M63.3283 11.0248C62.543 11.2812 61.954 11.7939 61.5613 12.5631C61.1195 13.2297 60.9968 13.9988 61.1932 14.8705L62.7393 20.793C62.9357 21.6647 63.402 22.3056 64.1382 22.7158C64.8254 23.1261 65.5617 23.203 66.347 22.9466L94.7669 15.0244C95.6013 14.8193 96.2149 14.3321 96.6075 13.563C97.0002 12.8964 97.0984 12.1272 96.902 11.2555L95.3559 5.33308C95.1595 4.46138 94.7178 3.82042 94.0306 3.41021C93.3434 3 92.5826 2.89745 91.7482 3.10255L63.3283 11.0248Z"
                fill="#3D465D"
            />
            <path
                d="M74.7252 56.1482C71.3874 53.5331 67.3625 52.2256 62.6504 52.2256C57.8401 52.2256 53.7416 53.5588 50.3548 56.2252C50.3548 56.2764 50.3302 56.3021 50.2811 56.3021C49.643 56.8148 49.1031 57.3276 48.6613 57.8404C48.1214 58.507 47.876 59.2505 47.9251 60.0709C47.9742 60.9426 48.3178 61.6605 48.9559 62.2245C49.5449 62.7886 50.2566 63.0449 51.091 62.9937C51.8764 62.9424 52.539 62.5835 53.0789 61.9169C53.3244 61.6605 53.5943 61.4041 53.8888 61.1477C56.294 59.3018 59.2145 58.3788 62.6504 58.3788C65.9881 58.3788 68.835 59.3018 71.1911 61.1477H71.2647C71.7065 61.5066 72.1237 61.8656 72.5164 62.2245C73.1054 62.7886 73.8171 63.0449 74.6515 62.9937C75.4369 62.9424 76.0995 62.5835 76.6394 61.9169C77.1794 61.3015 77.4248 60.558 77.3757 59.6863C77.3266 58.8659 77.0076 58.1737 76.4186 57.6096C75.8786 57.1481 75.3142 56.6866 74.7252 56.2252V56.1482Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-40': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.907 16.1025C30.833 16.1025 27.3725 17.6153 24.5256 20.641C21.6296 23.6666 20.1816 27.282 20.1816 31.4871C20.1816 35.7434 21.6296 39.3588 24.5256 42.3331C27.3725 45.3588 30.833 46.8716 34.907 46.8716C38.981 46.8716 42.4414 45.3588 45.2883 42.3331C48.1843 39.3588 49.6323 35.7434 49.6323 31.4871C49.6323 27.282 48.1843 23.6666 45.2883 20.641C42.4414 17.6153 38.981 16.1025 34.907 16.1025Z"
                fill="white"
            />
            <path
                d="M34.9075 22.2563C32.5023 22.2563 30.4408 23.1794 28.7228 25.0256C26.9558 26.8204 26.0723 28.9743 26.0723 31.4871C26.0723 34.0511 26.9558 36.2306 28.7228 38.0255C30.4408 39.8203 32.5023 40.7178 34.9075 40.7178C37.3617 40.7178 39.4478 39.8203 41.1657 38.0255C42.8837 36.2306 43.7426 34.0511 43.7426 31.4871C43.7426 28.9743 42.8837 26.8204 41.1657 25.0256C39.4478 23.1794 37.3617 22.2563 34.9075 22.2563Z"
                fill="#3D465D"
            />
            <path
                d="M79.0837 16.1025C75.0097 16.1025 71.5493 17.6153 68.7024 20.641C65.8064 23.6666 64.3584 27.282 64.3584 31.4871C64.3584 35.7434 65.8064 39.3588 68.7024 42.3331C71.5493 45.3588 75.0097 46.8716 79.0837 46.8716C83.1577 46.8716 86.6182 45.3588 89.4651 42.3331C92.361 39.3588 93.809 35.7434 93.809 31.4871C93.809 27.282 92.361 23.6666 89.4651 20.641C86.6182 17.6153 83.1577 16.1025 79.0837 16.1025Z"
                fill="white"
            />
            <path
                d="M79.0842 22.2563C76.6791 22.2563 74.6175 23.1794 72.8996 25.0256C71.1325 26.8204 70.249 28.9743 70.249 31.4871C70.249 34.0511 71.1325 36.2306 72.8996 38.0255C74.6175 39.8203 76.6791 40.7178 79.0842 40.7178C81.5384 40.7178 83.6245 39.8203 85.3425 38.0255C87.0604 36.2306 87.9194 34.0511 87.9194 31.4871C87.9194 28.9743 87.0604 26.8204 85.3425 25.0256C83.6245 23.1794 81.5384 22.2563 79.0842 22.2563Z"
                fill="#3D465D"
            />
            <path
                d="M17.4588 22.8718C17.8023 23.5898 18.3914 24.0513 19.2258 24.2564L47.6456 32.2564C48.431 32.4615 49.1918 32.359 49.9281 31.9487C50.6153 31.5384 51.057 30.8974 51.2533 30.0256L52.7995 24.1026C52.9958 23.2308 52.8977 22.4616 52.505 21.7949C52.0632 21.0257 51.4497 20.5385 50.6643 20.3334L22.2445 12.3334C21.41 12.1283 20.6738 12.2565 20.0357 12.7181C19.2994 13.077 18.8331 13.6924 18.6368 14.5642L17.0906 20.4872C16.8943 21.359 17.017 22.1539 17.4588 22.8718Z"
                fill="#3D465D"
            />
            <path
                d="M63.3283 11.0256C62.543 11.282 61.954 11.7948 61.5613 12.564C61.1195 13.2307 60.9968 13.9999 61.1932 14.8717L62.7393 20.7948C62.9357 21.6665 63.402 22.3076 64.1382 22.7178C64.8254 23.1281 65.5617 23.205 66.347 22.9486L94.7669 15.0256C95.6013 14.8204 96.2149 14.3333 96.6075 13.564C97.0002 12.8974 97.0984 12.1281 96.902 11.2564L95.3559 5.33332C95.1595 4.46153 94.7178 3.82051 94.0306 3.41025C93.3434 3 92.5826 2.89744 91.7482 3.10256L63.3283 11.0256Z"
                fill="#3D465D"
            />
            <path
                d="M77.3757 55.5387C77.4248 54.6669 77.1794 53.9233 76.6394 53.3079C76.0995 52.6413 75.4369 52.2823 74.6515 52.231C73.8171 52.1797 73.1054 52.4361 72.5164 53.0002C72.1237 53.3592 71.7065 53.7182 71.2647 54.0772H71.1911C68.835 55.9233 65.9881 56.8464 62.6504 56.8464C59.2145 56.8464 56.294 55.9233 53.8888 54.0772C53.5943 53.8208 53.3244 53.5643 53.0789 53.3079C52.539 52.6413 51.8764 52.2823 51.091 52.231C50.2566 52.1797 49.5449 52.4361 48.9559 53.0002C48.3178 53.5643 47.9742 54.2823 47.9251 55.1541C47.876 55.9746 48.1214 56.7182 48.6613 57.3848C49.1031 57.8976 49.643 58.4105 50.2811 58.9233C50.3302 58.9233 50.3548 58.9489 50.3548 59.0002C53.7416 61.6669 57.8401 63.0002 62.6504 63.0002C67.3625 63.0002 71.3874 61.6925 74.7252 59.0771V59.0002C75.3142 58.5387 75.8786 58.0771 76.4186 57.6156C77.0076 57.0515 77.3266 56.3592 77.3757 55.5387Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-41': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.907 15.9805C30.833 15.9805 27.3725 17.4792 24.5256 20.4767C21.6296 23.4741 20.1816 27.0558 20.1816 31.2218C20.1816 35.4385 21.6296 39.0202 24.5256 41.9669C27.3725 44.9643 30.833 46.4631 34.907 46.4631C38.981 46.4631 42.4414 44.9643 45.2883 41.9669C48.1843 39.0202 49.6323 35.4385 49.6323 31.2218C49.6323 27.0558 48.1843 23.4741 45.2883 20.4767C42.4414 17.4792 38.981 15.9805 34.907 15.9805Z"
                fill="white"
            />
            <path
                d="M34.9075 22.0771C32.5023 22.0771 30.4408 22.9916 28.7228 24.8206C26.9558 26.5987 26.0723 28.7325 26.0723 31.2219C26.0723 33.7621 26.9558 35.9213 28.7228 37.6995C30.4408 39.4776 32.5023 40.3667 34.9075 40.3667C37.3617 40.3667 39.4478 39.4776 41.1657 37.6995C42.8837 35.9213 43.7426 33.7621 43.7426 31.2219C43.7426 28.7325 42.8837 26.5987 41.1657 24.8206C39.4478 22.9916 37.3617 22.0771 34.9075 22.0771Z"
                fill="#3D465D"
            />
            <path
                d="M79.0837 15.9805C75.0097 15.9805 71.5493 17.4792 68.7024 20.4767C65.8064 23.4741 64.3584 27.0558 64.3584 31.2218C64.3584 35.4385 65.8064 39.0202 68.7024 41.9669C71.5493 44.9643 75.0097 46.4631 79.0837 46.4631C83.1577 46.4631 86.6182 44.9643 89.4651 41.9669C92.361 39.0202 93.809 35.4385 93.809 31.2218C93.809 27.0558 92.361 23.4741 89.4651 20.4767C86.6182 17.4792 83.1577 15.9805 79.0837 15.9805Z"
                fill="white"
            />
            <path
                d="M79.0842 22.0771C76.6791 22.0771 74.6175 22.9916 72.8996 24.8206C71.1325 26.5987 70.249 28.7325 70.249 31.2219C70.249 33.7621 71.1325 35.9213 72.8996 37.6995C74.6175 39.4776 76.6791 40.3667 79.0842 40.3667C81.5384 40.3667 83.6245 39.4776 85.3425 37.6995C87.0604 35.9213 87.9194 33.7621 87.9194 31.2219C87.9194 28.7325 87.0604 26.5987 85.3425 24.8206C83.6245 22.9916 81.5384 22.0771 79.0842 22.0771Z"
                fill="#3D465D"
            />
            <path
                d="M17.4588 22.6869C17.8023 23.3982 18.3914 23.8554 19.2258 24.0586L47.6456 31.9841C48.431 32.1873 49.1918 32.0857 49.9281 31.6793C50.6153 31.2728 51.057 30.6378 51.2533 29.7741L52.7995 23.9062C52.9958 23.0425 52.8977 22.2805 52.505 21.62C52.0632 20.858 51.4497 20.3753 50.6643 20.1721L22.2445 12.2466C21.41 12.0434 20.6738 12.1704 20.0357 12.6277C19.2994 12.9833 18.8331 13.5929 18.6368 14.4566L17.0906 20.3245C16.8943 21.1882 17.017 21.9757 17.4588 22.6869Z"
                fill="#3D465D"
            />
            <path
                d="M63.3283 10.9509C62.543 11.2049 61.954 11.7129 61.5613 12.475C61.1195 13.1355 60.9968 13.8975 61.1932 14.7612L62.7393 20.6291C62.9357 21.4928 63.402 22.1278 64.1382 22.5343C64.8254 22.9407 65.5617 23.0169 66.347 22.7629L94.7669 14.9136C95.6013 14.7104 96.2149 14.2278 96.6075 13.4657C97.0002 12.8052 97.0984 12.0432 96.902 11.1795L95.3559 5.3116C95.1595 4.44792 94.7178 3.81287 94.0306 3.40643C93.3434 3 92.5826 2.89839 91.7482 3.10161L63.3283 10.9509Z"
                fill="#3D465D"
            />
            <path
                d="M71.6472 52.7886C70.96 52.3313 70.1992 52.2297 69.3648 52.4837C68.5794 52.687 67.9659 53.1188 67.5241 53.7792C67.1314 54.3381 66.567 54.8715 65.8307 55.3796C64.0146 56.5481 61.8794 57.1323 59.4252 57.1323C58.2962 57.1323 57.2409 57.0307 56.2592 56.8275C55.4248 56.6751 54.664 56.8275 53.9768 57.2847C53.2896 57.6404 52.8479 58.25 52.6515 59.1137C52.4552 59.8758 52.6024 60.6124 53.0933 61.3237C53.486 61.9333 54.1241 62.3398 55.0076 62.543C56.3329 62.8478 57.8054 63.0002 59.4252 63.0002C63.2047 63.0002 66.4688 62.0858 69.2175 60.2568L69.1439 60.333C70.8128 59.2661 72.0399 58.0722 72.8252 56.7513C73.2179 56.04 73.3161 55.3034 73.1197 54.5413C72.8743 53.7284 72.3835 53.1442 71.6472 52.7886Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-42': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.897 16.3735C17.6323 19.2892 16 22.7732 16 26.8255C16 30.9272 17.6323 34.4111 20.897 37.2774C24.1063 40.1931 28.0073 41.6509 32.6 41.6509C37.1927 41.6509 41.0937 40.1931 44.303 37.2774C47.5677 34.4111 49.2 30.9272 49.2 26.8255C49.2 22.7732 47.5677 19.2892 44.303 16.3735C41.0937 13.4578 37.1927 12 32.6 12C28.0073 12 24.1063 13.4578 20.897 16.3735Z"
                fill="white"
            />
            <path
                d="M39.6556 33.1263C41.5923 31.3966 42.5606 29.2964 42.5606 26.8254C42.5606 24.404 41.5923 22.3284 39.6556 20.5988C37.719 18.8197 35.3673 17.9302 32.6006 17.9302C29.8893 17.9302 27.5653 18.8197 25.6286 20.5988C23.6366 22.3284 22.6406 24.404 22.6406 26.8254C22.6406 29.2964 23.6366 31.3966 25.6286 33.1263C27.5653 34.8559 29.8893 35.7207 32.6006 35.7207C35.3673 35.7207 37.719 34.8559 39.6556 33.1263Z"
                fill="#3D465D"
            />
            <path
                d="M70.6968 16.3735C67.4321 19.2892 65.7998 22.7732 65.7998 26.8255C65.7998 30.9272 67.4321 34.4111 70.6968 37.2774C73.9061 40.1931 77.8071 41.6509 82.3998 41.6509C86.9925 41.6509 90.8935 40.1931 94.1028 37.2774C97.3675 34.4111 98.9998 30.9272 98.9998 26.8255C98.9998 22.7732 97.3675 19.2892 94.1028 16.3735C90.8935 13.4578 86.9925 12 82.3998 12C77.8071 12 73.9061 13.4578 70.6968 16.3735Z"
                fill="white"
            />
            <path
                d="M89.4545 33.1263C91.3911 31.3966 92.3595 29.2964 92.3595 26.8254C92.3595 24.404 91.3911 22.3284 89.4545 20.5988C87.5178 18.8197 85.1661 17.9302 82.3995 17.9302C79.6881 17.9302 77.3641 18.8197 75.4275 20.5988C73.4355 22.3284 72.4395 24.404 72.4395 26.8254C72.4395 29.2964 73.4355 31.3966 75.4275 33.1263C77.3641 34.8559 79.6881 35.7207 82.3995 35.7207C85.1661 35.7207 87.5178 34.8559 89.4545 33.1263Z"
                fill="#3D465D"
            />
            <path
                d="M71.1125 48.3967C67.3498 45.8764 62.8125 44.6162 57.5005 44.6162C52.0778 44.6162 47.4575 45.9011 43.6395 48.4708C43.6395 48.5202 43.6118 48.545 43.5565 48.545C42.8371 49.0391 42.2285 49.5333 41.7305 50.0275C41.1218 50.6699 40.8451 51.3865 40.9005 52.1772C40.9558 53.0173 41.3431 53.7092 42.0625 54.2528C42.7265 54.7964 43.5288 55.0434 44.4695 54.994C45.3548 54.9446 46.1018 54.5987 46.7105 53.9562C46.9871 53.7092 47.2915 53.4621 47.6235 53.215C50.3348 51.4359 53.6271 50.5464 57.5005 50.5464C61.2631 50.5464 64.4725 51.4359 67.1285 53.215H67.2115C67.7095 53.5609 68.1798 53.9068 68.6225 54.2528C69.2865 54.7964 70.0888 55.0434 71.0295 54.994C71.9148 54.9446 72.6618 54.5987 73.2705 53.9562C73.8791 53.3632 74.1558 52.6467 74.1005 51.8066C74.0451 51.0159 73.6855 50.3487 73.0215 49.8051C72.4128 49.3604 71.7765 48.9156 71.1125 48.4708V48.3967Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-43': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.897 16.3741C17.6323 19.2902 16 22.7747 16 26.8276C16 30.9299 17.6323 34.4144 20.897 37.281C24.1063 40.1971 28.0073 41.6552 32.6 41.6552C37.1927 41.6552 41.0937 40.1971 44.303 37.281C47.5677 34.4144 49.2 30.9299 49.2 26.8276C49.2 22.7747 47.5677 19.2902 44.303 16.3741C41.0937 13.458 37.1927 12 32.6 12C28.0073 12 24.1063 13.458 20.897 16.3741Z"
                fill="white"
            />
            <path
                d="M39.6556 33.1294C41.5923 31.3995 42.5606 29.299 42.5606 26.8277C42.5606 24.4059 41.5923 22.33 39.6556 20.6001C37.719 18.8208 35.3673 17.9312 32.6006 17.9312C29.8893 17.9312 27.5653 18.8208 25.6286 20.6001C23.6366 22.33 22.6406 24.4059 22.6406 26.8277C22.6406 29.299 23.6366 31.3995 25.6286 33.1294C27.5653 34.8593 29.8893 35.7243 32.6006 35.7243C35.3673 35.7243 37.719 34.8593 39.6556 33.1294Z"
                fill="#3D465D"
            />
            <path
                d="M70.6968 16.3741C67.4321 19.2902 65.7998 22.7747 65.7998 26.8276C65.7998 30.9299 67.4321 34.4144 70.6968 37.281C73.9061 40.1971 77.8071 41.6552 82.3998 41.6552C86.9925 41.6552 90.8935 40.1971 94.1028 37.281C97.3675 34.4144 98.9998 30.9299 98.9998 26.8276C98.9998 22.7747 97.3675 19.2902 94.1028 16.3741C90.8935 13.458 86.9925 12 82.3998 12C77.8071 12 73.9061 13.458 70.6968 16.3741Z"
                fill="white"
            />
            <path
                d="M89.4545 33.1294C91.3911 31.3995 92.3595 29.299 92.3595 26.8277C92.3595 24.4059 91.3911 22.33 89.4545 20.6001C87.5178 18.8208 85.1661 17.9312 82.3995 17.9312C79.6881 17.9312 77.3641 18.8208 75.4275 20.6001C73.4355 22.33 72.4395 24.4059 72.4395 26.8277C72.4395 29.299 73.4355 31.3995 75.4275 33.1294C77.3641 34.8593 79.6881 35.7243 82.3995 35.7243C85.1661 35.7243 87.5178 34.8593 89.4545 33.1294Z"
                fill="#3D465D"
            />
            <path
                d="M68.704 48.9208C69.1467 48.2289 69.2574 47.5122 69.036 46.7708C68.7594 45.98 68.206 45.4116 67.376 45.0657C66.6014 44.6208 65.7437 44.522 64.803 44.7691C63.9177 44.9668 63.226 45.3869 62.728 46.0295C62.2854 46.5731 61.649 47.0921 60.819 47.5863C58.7717 48.7231 56.3647 49.2915 53.598 49.2915C52.3254 49.2915 51.1357 49.1927 50.029 48.995C49.0884 48.8467 48.2307 48.995 47.456 49.4398C46.6814 49.7858 46.1834 50.3789 45.962 51.2191C45.7407 51.9605 45.9067 52.6772 46.46 53.3691C46.9027 53.9622 47.622 54.3576 48.618 54.5553C50.112 54.8519 51.772 55.0001 53.598 55.0001C57.8587 55.0001 61.5384 54.1105 64.637 52.3312L64.554 52.4053C66.4354 51.3674 67.8187 50.2059 68.704 48.9208Z"
                fill="#3D465D"
            />
        </svg>
    ),
    'face-44': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.8971 16.8788C17.6324 20.1314 16 24.0179 16 28.5385C16 33.1141 17.6324 37.0006 20.8971 40.1981C24.1065 43.4506 28.0075 45.0769 32.6002 45.0769C37.193 45.0769 41.094 43.4506 44.3034 40.1981C47.5681 37.0006 49.2005 33.1141 49.2005 28.5385C49.2005 24.0179 47.5681 20.1314 44.3034 16.8788C41.094 13.6263 37.193 12 32.6002 12C28.0075 12 24.1065 13.6263 20.8971 16.8788Z"
                fill="white"
            />
            <path
                d="M39.6549 35.5672C41.5916 33.6377 42.5599 31.2947 42.5599 28.5383C42.5599 25.837 41.5916 23.5216 39.6549 21.5922C37.7182 19.6075 35.3665 18.6152 32.5998 18.6152C29.8884 18.6152 27.5644 19.6075 25.6277 21.5922C23.6357 23.5216 22.6396 25.837 22.6396 28.5383C22.6396 31.2947 23.6357 33.6377 25.6277 35.5672C27.5644 37.4966 29.8884 38.4614 32.5998 38.4614C35.3665 38.4614 37.7182 37.4966 39.6549 35.5672Z"
                fill="#3D465D"
            />
            <path
                d="M70.6969 16.8788C67.4322 20.1314 65.7998 24.0179 65.7998 28.5385C65.7998 33.1141 67.4322 37.0006 70.6969 40.1981C73.9063 43.4506 77.8073 45.0769 82.4001 45.0769C86.9928 45.0769 90.8938 43.4506 94.1032 40.1981C97.3679 37.0006 99.0003 33.1141 99.0003 28.5385C99.0003 24.0179 97.3679 20.1314 94.1032 16.8788C90.8938 13.6263 86.9928 12 82.4001 12C77.8073 12 73.9063 13.6263 70.6969 16.8788Z"
                fill="white"
            />
            <path
                d="M89.4567 35.5676C91.3934 33.6382 92.3617 31.2952 92.3617 28.5388C92.3617 25.8375 91.3934 23.5221 89.4567 21.5926C87.52 19.608 85.1683 18.6157 82.4016 18.6157C79.6902 18.6157 77.3661 19.608 75.4295 21.5926C73.4374 23.5221 72.4414 25.8375 72.4414 28.5388C72.4414 31.2952 73.4374 33.6382 75.4295 35.5676C77.3661 37.4971 79.6902 38.4619 82.4016 38.4619C85.1683 38.4619 87.52 37.4971 89.4567 35.5676Z"
                fill="#3D465D"
            />
            <path
                d="M47.541 55H69.1213"
                stroke="#3D465D"
                strokeWidth="5"
                strokeLinecap="round"
            />
        </svg>
    ),
    'face-45': (
        <svg
            width="115"
            height="67"
            viewBox="0 0 115 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.897 16.3741C17.6323 19.2902 16 22.7747 16 26.8276C16 30.9299 17.6323 34.4144 20.897 37.281C24.1063 40.1971 28.0073 41.6552 32.6 41.6552C37.1927 41.6552 41.0937 40.1971 44.303 37.281C47.5677 34.4144 49.2 30.9299 49.2 26.8276C49.2 22.7747 47.5677 19.2902 44.303 16.3741C41.0937 13.458 37.1927 12 32.6 12C28.0073 12 24.1063 13.458 20.897 16.3741Z"
                fill="white"
            />
            <path
                d="M39.6556 33.1294C41.5923 31.3995 42.5606 29.299 42.5606 26.8277C42.5606 24.4059 41.5923 22.33 39.6556 20.6001C37.719 18.8208 35.3673 17.9312 32.6006 17.9312C29.8893 17.9312 27.5653 18.8208 25.6286 20.6001C23.6366 22.33 22.6406 24.4059 22.6406 26.8277C22.6406 29.299 23.6366 31.3995 25.6286 33.1294C27.5653 34.8593 29.8893 35.7243 32.6006 35.7243C35.3673 35.7243 37.719 34.8593 39.6556 33.1294Z"
                fill="#3D465D"
            />
            <path
                d="M70.6968 16.3741C67.4321 19.2902 65.7998 22.7747 65.7998 26.8276C65.7998 30.9299 67.4321 34.4144 70.6968 37.281C73.9061 40.1971 77.8071 41.6552 82.3998 41.6552C86.9925 41.6552 90.8935 40.1971 94.1028 37.281C97.3675 34.4144 98.9998 30.9299 98.9998 26.8276C98.9998 22.7747 97.3675 19.2902 94.1028 16.3741C90.8935 13.458 86.9925 12 82.3998 12C77.8071 12 73.9061 13.458 70.6968 16.3741Z"
                fill="white"
            />
            <path
                d="M89.4545 33.1294C91.3911 31.3995 92.3595 29.299 92.3595 26.8277C92.3595 24.4059 91.3911 22.33 89.4545 20.6001C87.5178 18.8208 85.1661 17.9312 82.3995 17.9312C79.6881 17.9312 77.3641 18.8208 75.4275 20.6001C73.4355 22.33 72.4395 24.4059 72.4395 26.8277C72.4395 29.299 73.4355 31.3995 75.4275 33.1294C77.3641 34.8593 79.6881 35.7243 82.3995 35.7243C85.1661 35.7243 87.5178 34.8593 89.4545 33.1294Z"
                fill="#3D465D"
            />
            <path
                d="M71.1125 51.2187V51.1446C71.7765 50.6997 72.4128 50.2549 73.0215 49.8101C73.6855 49.2664 74.0451 48.5992 74.1005 47.8084C74.1558 46.9681 73.8791 46.2515 73.2705 45.6584C72.6618 45.0158 71.9148 44.6699 71.0295 44.6204C70.0888 44.571 69.2865 44.8181 68.6225 45.3618C68.1798 45.7078 67.7095 46.0538 67.2115 46.3997H67.1285C64.4725 48.1791 61.2631 49.0687 57.5005 49.0687C53.6271 49.0687 50.3348 48.1791 47.6235 46.3997C47.2915 46.1526 46.9871 45.9055 46.7105 45.6584C46.1018 45.0158 45.3548 44.6699 44.4695 44.6204C43.5288 44.571 42.7265 44.8181 42.0625 45.3618C41.3431 45.9055 40.9558 46.5974 40.9005 47.4377C40.8451 48.2285 41.1218 48.9451 41.7305 49.5877C42.2285 50.0819 42.8371 50.5762 43.5565 51.0704C43.6118 51.0704 43.6395 51.0951 43.6395 51.1446C47.4575 53.7147 52.0778 54.9997 57.5005 54.9997C62.8125 54.9997 67.3498 53.7394 71.1125 51.2187Z"
                fill="#3D465D"
            />
        </svg>
    ),
};
