import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ avatarId, gradients }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`g_paint0_${avatarId}`}
                x1="150.056"
                y1="103.882"
                x2="150.056"
                y2="160.082"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface RockerProps {
    color?: string;
    className?: string;
    avatarId?: string;
}

const Rocker = (props: RockerProps) => {
    const { color = '', avatarId = '', className } = props;
    const gradients = getAvatarColors({ color, type: 'gesture' });

    return (
        <g id={`gesture-3_${avatarId}`} className={className}>
            <path
                d="M148.456 116.632C148.322 115.565 147.856 114.682 147.056 113.982C146.989 113.949 146.939 113.915 146.906 113.882C146.006 113.182 144.989 112.899 143.856 113.032C143.022 113.132 142.306 113.449 141.706 113.982C141.506 114.149 141.322 114.349 141.156 114.582C140.456 115.482 140.172 116.499 140.306 117.632C140.872 122.099 141.372 126.515 141.806 130.882C141.906 131.582 141.989 132.282 142.056 132.982C140.822 132.082 139.522 131.249 138.156 130.482C137.156 129.915 136.106 129.782 135.006 130.082C133.939 130.382 133.122 131.032 132.556 132.032C131.989 132.999 131.856 134.032 132.156 135.132C132.456 136.232 133.106 137.065 134.106 137.632C136.072 138.765 137.822 139.999 139.356 141.332C140.556 142.399 141.606 143.532 142.506 144.732C144.239 147.865 146.672 149.849 149.806 150.682C150.572 150.849 151.372 150.932 152.206 150.932C155.306 150.932 158.056 149.765 160.456 147.432C160.856 146.999 161.239 146.565 161.606 146.132C163.272 144.099 164.589 140.615 165.556 135.682C165.856 134.115 166.172 132.565 166.506 131.032C167.106 128.165 167.589 125.315 167.956 122.482C168.122 121.349 167.856 120.332 167.156 119.432C166.489 118.532 165.589 117.999 164.456 117.832C163.356 117.665 162.356 117.932 161.456 118.632C160.522 119.332 159.989 120.249 159.856 121.382C159.556 123.449 159.206 125.532 158.806 127.632C157.406 127.099 155.722 126.699 153.756 126.432C152.122 126.232 150.739 126.265 149.606 126.532C149.239 123.265 148.856 119.965 148.456 116.632Z"
                fill={`url(#g_paint0_${avatarId})`}
            />
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Rocker;
