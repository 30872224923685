import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ avatarId, gradients }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`g_paint0_${avatarId}`}
                x1="143.474"
                y1="132.202"
                x2="143.474"
                y2="151.602"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`g_paint1_${avatarId}`}
                x1="195.216"
                y1="104.902"
                x2="195.216"
                y2="161.102"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface PeaceProps {
    color?: string;
    className?: string;
    avatarId?: string;
}

const Peace = (props: PeaceProps) => {
    const { color = '', avatarId = '', className } = props;
    const gradients = getAvatarColors({ color, type: 'gesture' });

    return (
        <g id={`gesture-0_${avatarId}`} className={className}>
            <path
                d="M139.843 136.452C139.246 138.652 139.362 141.002 140.191 143.502C140.257 143.669 140.307 143.852 140.34 144.052C141.534 147.652 143.922 150.185 147.504 151.652L143.823 132.202C141.833 132.902 140.506 134.319 139.843 136.452Z"
                fill={`url(#g_paint0_${avatarId})`}
            />
            <path
                d="M166.012 131.152C165.15 130.285 164.122 129.852 162.928 129.852C162.729 129.552 162.496 129.269 162.231 129.002C161.435 128.169 160.473 127.735 159.345 127.702C159.578 124.702 159.793 121.269 159.992 117.402C160.025 116.502 159.826 115.702 159.395 115.002C159.229 114.802 159.064 114.602 158.898 114.402C158.102 113.535 157.123 113.069 155.962 113.002C154.802 112.969 153.79 113.335 152.928 114.102C152.629 114.369 152.38 114.669 152.181 115.002C151.783 115.602 151.568 116.285 151.535 117.052C151.435 119.485 151.319 121.735 151.186 123.802L150.838 123.852C150.573 121.652 150.258 119.269 149.893 116.702C149.793 116.069 149.594 115.502 149.296 115.002C148.997 114.602 148.649 114.235 148.251 113.902C147.322 113.235 146.261 112.985 145.067 113.152C143.939 113.319 143.027 113.869 142.331 114.802C142.264 114.869 142.214 114.935 142.181 115.002C141.584 115.835 141.369 116.785 141.535 117.852C142.331 123.585 143.093 128.369 143.823 132.202L147.505 151.652C148.732 152.152 150.075 152.552 151.535 152.852C154.818 153.352 157.936 152.619 160.888 150.652C164.006 148.519 165.879 145.519 166.51 141.652C166.51 141.585 166.51 141.535 166.51 141.502C166.51 141.469 166.526 141.419 166.559 141.352C166.758 139.785 166.924 138.485 167.057 137.452C167.223 135.619 167.306 134.552 167.306 134.252C167.306 134.052 167.306 133.869 167.306 133.702C167.173 132.735 166.742 131.885 166.012 131.152Z"
                fill={`url(#g_paint1_${avatarId})`}
            />
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Peace;
