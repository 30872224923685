import React from 'react';
import { AvatarSpecs } from '../../../../types/Participant.js';
import Square from './shapes/Square.js';
import Circle from './shapes/Circle.js';
import Diamond from './shapes/Diamond.js';
import Rounded from './shapes/Rounded.js';

interface ShapeProps {
    shape: AvatarSpecs['shape'];
    color?: string;
    hasGesture?: boolean;
    avatarId?: string;
}

export const Shape = (props: ShapeProps) => {
    const { shape, avatarId = '', color = '', hasGesture = false } = props;

    switch (shape) {
        case 'circle':
            return <Circle hasGesture={hasGesture} avatarId={avatarId} color={color} />;

        case 'square':
            return <Square hasGesture={hasGesture} avatarId={avatarId} color={color} />;

        case 'diamond':
            return <Diamond hasGesture={hasGesture} avatarId={avatarId} color={color} />;

        case 'rounded':
            return <Rounded hasGesture={hasGesture} avatarId={avatarId} color={color} />;

        default:
            return null;
    }
};
