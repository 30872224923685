import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ avatarId, gradients }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`g_paint0_${avatarId}`}
                x1="141.9"
                y1="127.4"
                x2="141.9"
                y2="149.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`g_paint1_${avatarId}`}
                x1="64.0506"
                y1="104.9"
                x2="64.0506"
                y2="161.1"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface FistProps {
    color?: string;
    className?: string;
    avatarId?: string;
}

const Fist = (props: FistProps) => {
    const { color = '', avatarId = '', className } = props;
    const gradients = getAvatarColors({ color, type: 'gesture' });

    return (
        <g id="gesture-5" className={className}>
            <path
                d="M140.55 127.35C140.184 127.517 139.834 127.7 139.5 127.9C136.367 129.667 135.317 132.817 136.35 137.35C137.35 141.883 139.234 145.117 142 147.05C143.634 148.217 145.55 149.033 147.75 149.5L140.55 127.35Z"
                fill={`url(#g_paint0_${avatarId})`}
            />
            <path
                d="M169.351 125.5C168.717 122.567 166.851 120.833 163.751 120.3C162.584 120.067 161.517 120 160.551 120.1C159.884 117.5 157.734 116.133 154.101 116C150.934 115.8 148.884 116.883 147.951 119.25C146.717 117.517 144.684 117.45 141.851 119.05C139.151 120.55 138.717 123.317 140.551 127.35L147.751 149.5C149.251 149.833 150.884 150 152.651 150C156.984 150 160.667 148.617 163.701 145.85C164.067 145.483 164.417 145.117 164.751 144.75C169.484 138.85 171.017 132.433 169.351 125.5Z"
                fill={`url(#g_paint1_${avatarId})`}
            />
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Fist;
