import React, { useEffect, useRef, useState } from 'react';
import Fade from '@mui/material/Fade';
import getFace from './faces/getFace.js';

interface FaceProps {
    face: string;
    avatarId: string;
    hasGesture?: boolean;
}

/* This component assumes that the faces being copied from Figma were originally faces without
   a gesture that may need to shrink. Basically we need all of the faces to come from a shape
   of the same size so that it's possible to know whether to scale them up or down. Shrinking
   seems to work on all of them for now.

    -- face scale diff
    23.94 x 15.08 // no gesture
    19.39 x 11.49 // has gesture

    -- to enlarge face multiply (x, y) by
    1.235 and 1.312, respectively

    -- to shrink face multiply (x, y) by
    0.81 and 0.762, respectively

    -- shape scale diff 1.368 or 0.731
    31.13 x 31.13 // no gesture
    22.75 x 22.75 // has gesture
*/
export const Face = ({ face, avatarId = '', hasGesture = false }: FaceProps) => {
    const [faceTranslate, setFaceTranslate] = useState({ x: 0, y: 0 });
    const [faceScale, setFaceScale] = useState('');
    const [contentReady, setContentReady] = useState(false);

    const faceRef = useRef(null);

    const calcFaceTranslate = () => {
        const $shape = document.querySelector(
            `g#shape_${avatarId}`,
        ) as SVGGraphicsElement;
        const $face = document.querySelector(`g#face_${avatarId}`) as SVGGraphicsElement;

        const shapeBox = $shape?.getBBox();
        const faceBox = $face?.getBBox();

        let faceTranslateX = 0;
        let faceTranslateY = 0;

        if (shapeBox && faceBox) {
            if (hasGesture) {
                // shrink faceBox width and height to match shapeBox scaling from gesture removal
                // calculate the new face position based on shrink values so that we don't need
                // separate SVGs for gesture and non-gesture scenarios
                faceBox.width *= 0.721;
                faceBox.height *= 0.721;
            }

            // calc translate for face x
            const wDiff = Math.floor((shapeBox.width - faceBox.width) / 2); // space needed for both ends
            const newFaceX = shapeBox.x + wDiff;
            faceTranslateX = newFaceX - faceBox.x;

            // calc translate for face y
            const yDiff = Math.floor((shapeBox.height - faceBox.height) / 2); // space needed for both ends
            const newFaceY = shapeBox.y + yDiff;
            faceTranslateY = newFaceY - faceBox.y;
        }

        if (hasGesture) {
            // if a position has been calced based on the shrunken shape
            // scale the actual facial dimensions accordingly
            setFaceScale('scale(0.81, 0.762)');
        }

        setFaceTranslate({ x: faceTranslateX, y: faceTranslateY });
    };

    useEffect(() => {
        const checkContentReady = () => {
            const $shape = document.querySelector(`g#shape_${avatarId}`);
            const $face = document.querySelector(`g#face_${avatarId}`);

            if ($shape && $face) {
                setContentReady(true);
            } else {
                requestAnimationFrame(checkContentReady);
            }
        };

        checkContentReady();
    }, [avatarId]);

    useEffect(() => {
        if (contentReady) {
            calcFaceTranslate();
        }
    }, [contentReady]);

    return (
        <Fade in>
            <g
                style={{
                    transform: `translate(${faceTranslate.x}px, ${faceTranslate.y}px) ${faceScale}`,
                    visibility: faceTranslate.y > 0 ? 'visible' : 'hidden',
                }}
                ref={faceRef}
                id={`${face}_${avatarId}`}
            >
                {getFace(face)}
            </g>
        </Fade>
    );
};
