import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ avatarId, gradients }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`g_paint0_${avatarId}`}
                x1="144.2"
                y1="117.6"
                x2="144.2"
                y2="134.4"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.secondaryGradientHex1} />
                <stop offset="1" stopColor={gradients.secondaryGradientHex2} />
            </linearGradient>
            <linearGradient
                id={`g_paint1_${avatarId}`}
                x1="278.05"
                y1="104.9"
                x2="278.05"
                y2="161.1"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface PointerProps {
    color?: string;
    className?: string;
    avatarId?: string;
}

const Pointer = (props: PointerProps) => {
    const { color = '', avatarId = '', className } = props;
    const gradients = getAvatarColors({ color, type: 'gesture' });

    return (
        <g id={`gesture-4_${avatarId}`} className={className}>
            <path
                d="M150.6 123.8C150.6 123.233 150.567 122.683 150.5 122.15C150.367 120.75 149.717 119.6 148.55 118.7C147.484 117.867 146.217 117.5 144.75 117.6C143.317 117.733 142.134 118.333 141.2 119.4C140.267 120.533 139.867 121.75 140 123.05C140.167 124.917 140.134 126.65 139.9 128.25C139.567 130.55 138.75 132.583 137.45 134.35C137.35 134.483 137.267 134.583 137.2 134.65L150.6 123.8Z"
                fill={`url(#g_paint0_${avatarId})`}
            />
            <path
                d="M168.85 116.95C167.983 115.85 166.833 115.217 165.4 115.05C165.233 115.017 165.067 115 164.9 115C164.7 115 164.517 115 164.35 115C163.35 115.067 162.417 115.4 161.55 116C157.883 118.633 154.233 121.233 150.6 123.8L137.2 134.65C135.867 137.15 135.667 139.917 136.6 142.95C136.867 143.617 137.183 144.267 137.55 144.9C139.25 147.667 141.817 149.5 145.25 150.4C147.85 151.1 150.367 151.133 152.8 150.5C153.2 150.4 153.6 150.283 154 150.15L160.1 148.2C160.433 148.1 160.75 148 161.05 147.9C161.15 147.867 161.25 147.833 161.35 147.8C162.75 147.133 163.75 146.133 164.35 144.8C165.05 143.3 165.067 141.833 164.4 140.4C164.3 140.167 164.183 139.933 164.05 139.7C164.283 139.233 164.467 138.733 164.6 138.2C165 136.7 164.8 135.267 164 133.9C163.4 132.967 162.617 132.25 161.65 131.75L161.6 131.7L159.05 130.4C161.95 128.3 164.883 126.2 167.85 124.1C169.017 123.233 169.717 122.1 169.95 120.7C170.117 119.333 169.75 118.083 168.85 116.95Z"
                fill={`url(#g_paint1_${avatarId})`}
            />
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Pointer;
