import React from 'react';
import getAvatarColors from '../getAvatarColors.js';

interface LinearGradientsProps {
    gradients: {
        primaryGradientHex1: string;
        primaryGradientHex2: string;
        primaryGradientHex3?: string;
        secondaryGradientHex1: string;
        secondaryGradientHex2: string;
    };
    avatarId?: string;
}

const LinearGradients = ({ avatarId, gradients }: LinearGradientsProps) => {
    return (
        <defs>
            <linearGradient
                id={`g_paint0_${avatarId}`}
                x1="105.052"
                y1="99.9161"
                x2="105.052"
                y2="156.116"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={gradients.primaryGradientHex1} />
                <stop offset="1" stopColor={gradients.primaryGradientHex2} />
            </linearGradient>
        </defs>
    );
};

interface WaveProps {
    color?: string;
    className?: string;
    avatarId?: string;
}

const Wave = (props: WaveProps) => {
    const { color = '', avatarId = '', className } = props;
    const gradients = getAvatarColors({ color, type: 'gesture' });

    return (
        <g id={`gesture-1_${avatarId}`} className={className}>
            <path
                d="M156.152 112.016C155.919 111.183 155.502 110.516 154.902 110.016C154.669 109.816 154.419 109.649 154.152 109.516C153.152 108.949 152.119 108.849 151.052 109.216C150.485 109.383 150.019 109.649 149.652 110.016C149.252 110.349 148.935 110.766 148.702 111.266C148.169 112.299 148.069 113.366 148.402 114.466C149.302 117.533 149.852 120.666 150.052 123.866C148.785 120.599 147.019 117.416 144.752 114.316C144.052 113.416 143.152 112.883 142.052 112.716C140.919 112.549 139.919 112.833 139.052 113.566C138.152 114.266 137.619 115.183 137.452 116.316C137.319 117.483 137.585 118.533 138.252 119.466C140.352 122.266 141.902 125.166 142.902 128.166C143.435 129.699 143.702 131.549 143.702 133.716C142.569 132.683 141.352 131.449 140.052 130.016C139.252 129.149 138.302 128.699 137.202 128.666C136.069 128.633 135.102 129.016 134.302 129.816C133.469 130.616 133.035 131.599 133.002 132.766C132.969 133.899 133.335 134.899 134.102 135.766C134.735 136.466 135.352 137.116 135.952 137.716C137.552 139.383 139.069 140.749 140.502 141.816C140.535 141.849 140.569 141.866 140.602 141.866L141.852 142.666C144.385 144.533 147.219 145.883 150.352 146.716C155.085 147.616 158.752 146.399 161.352 143.066C161.452 142.933 161.552 142.799 161.652 142.666C161.885 142.299 162.119 141.949 162.352 141.616C163.985 138.583 165.152 134.216 165.852 128.516L166.552 124.116C166.752 122.983 166.885 121.833 166.952 120.666C166.985 120.366 167.002 120.066 167.002 119.766C167.035 118.599 166.669 117.599 165.902 116.766C165.102 115.933 164.152 115.499 163.052 115.466C161.919 115.433 160.952 115.816 160.152 116.616C159.319 117.416 158.885 118.399 158.852 119.566C158.819 121.133 158.602 122.683 158.202 124.216C158.002 120.049 157.319 115.983 156.152 112.016Z"
                fill={`url(#g_paint0_${avatarId})`}
            />
            <LinearGradients avatarId={avatarId} gradients={gradients} />
        </g>
    );
};

export default Wave;
